import React from 'react';
import {withRouter} from 'react-router';
import './styles.scss';
import __ from '../../../../utils/translate';
import ActivitieIndicator from '../../ActivityIndicator';
import {isTheyStream} from '../../../../apps-config';
const MessageBubble = ({
  item,
  index,
  messages,
  contactUser,
  credentials,
  isBlocked,
  userLang,
  translateMessage,
  firstBlurMessageIndex,
}) => {
  const showSeparatorBottom = !index;
  const showSeparatorTop = typeof messages[index + 1] === 'undefined';
  const separator = <div className="messages_separator"></div>;

  const {
    id,
    is_my,
    message,
    is_free,
    is_paid,
    translate,
    translate_load,
  } = item;

  const isPrevChangeOwner =
    typeof messages[index - 1] === 'undefined' ||
    messages[index - 1].is_my !== is_my;

  const isNextChangeOwner =
    typeof messages[index + 1] === 'undefined' ||
    messages[index + 1].is_my !== is_my;

  const renderTail = () => {
    const fromTail = isTheyStream() ? '#f7b500' : '#4886f1';
    const toTail = isTheyStream() ? '#A09881' : '#e9f1f6';
    return (
      <>
        {is_my ? (
          <svg width="14" height="18">
            <g transform="translate(-10.000000, -559.000000)">
              <path
                fill={toTail}
                d="M17.5,559 L17.5,566.900463 C17.5,569.863137 18.5,574.800926 24,576.282263 C17.165,578.257378 12,575.788484 10,573.319589 L10,559 L17.5,559 Z"
              />
            </g>
          </svg>
        ) : (
          <svg width="14" height="18">
            <g transform="translate(-10.000000, -559.000000)">
              <path
                fill={fromTail}
                d="M17.5,559 L17.5,566.900463 C17.5,569.863137 18.5,574.800926 24,576.282263 C17.165,578.257378 12,575.788484 10,573.319589 L10,559 L17.5,559 Z"
                transform="translate(17.000000, 568.000000) scale(-1, 1) translate(-17.000000, -568.000000) "
              />
            </g>
          </svg>
        )}
      </>
    );
  };

  const renderTranslate = () => {
    if (!is_free && !is_my && !is_paid) {
      return null;
    }

    if (translate_load) {
      return (
        <div>
          <ActivitieIndicator />
        </div>
      );
    }

    if (!is_my && translate) {
      return <div className="message_translate">{translate}</div>;
    }

    if (!is_my && !translate && userLang !== contactUser.language) {
      if (userLang === 'en' && contactUser.op) {
        return null;
      }
      return (
        <div
          onClick={() => translateMessage(id)}
          className="message_translate_action">
          {__('Translate to English')}
        </div>
      );
    }
  };

  const clsMsgText = ['message_text'];
  const clsMsgList = ['messages_list'];
  if (is_my) {
    const msgCls = isTheyStream() ? 'ts-to' : 'to';
    clsMsgText.push(msgCls);
    clsMsgList.push(msgCls);
  } else {
    const msgCls = isTheyStream() ? 'ts-from' : 'from';
    clsMsgText.push(msgCls);
    clsMsgList.push(msgCls);
  }

  return !is_my &&
    !is_free &&
    !is_paid &&
    firstBlurMessageIndex !== index ? null : (
    <>
      {showSeparatorBottom ? separator : null}
      {renderTranslate()}
      <div className={clsMsgList.join(' ')}>
        <div className={clsMsgText.join(' ')}>
          {!is_my && !is_free && !is_paid && firstBlurMessageIndex === index ? (
            <img alt="" src={'/img/blurpixel.png'} style={{}} />
          ) : (
            <span>{message}</span>
          )}
        </div>
        {isPrevChangeOwner ? renderTail() : null}
      </div>
      {showSeparatorTop || isNextChangeOwner ? separator : null}
    </>
  );
};

export default withRouter(MessageBubble);
