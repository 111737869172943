import React, {Component} from 'react';
import Footer from '../../Components/Footer';
import {connect} from 'react-redux';
import __ from '../../utils/translate';
import {isTheyStream} from '../../apps-config';

class Contacts extends Component {
  render() {
    const clsMain = [];
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsMain.push('blur');
    }
    if (isTheyStream()) {
      clsMain.push('ts-main');
    }
    return (
      <>
        <main id="main" className={clsMain.join(' ')}>
          <div className="container">
            <h1 className="main__title">{__('Contact Us')}</h1>
            <div>
              {__(
                'Mailing address: Harju maakond, Tallinn, Mustamäe linnaosa, Ehitajate tee 110, 13517, Estonia',
              )}
              <br />
              {__('Attention of: Customer Support, Galocor OÜ')}
              <br />
              <br />
              E-mail:{' '}
              <a href="mailto:support@corp.loka.world">
                support@corp.loka.world
              </a>
            </div>
          </div>
        </main>
        {(!this.props.session.isLogged &&
          !this.props.credentials.user_id &&
          !this.props.registration.inProgress) ||
        (!this.props.session.isLogged &&
          !this.props.credentials.user_id &&
          this.props.registration.inProgress) ? (
          <Footer />
        ) : null}
      </>
    );
  }
}

export default connect((state) => ({
  people: state.people,
  peopleSearch: state.peopleSearch,
  events: state.events,
  session: state.session,
  credentials: state.credentials,
  registration: state.registration,
}))(Contacts);
