import React from 'react';
import './styles.scss';
import {Link} from 'react-router-dom';

const Avatar = (props) => {
  const {main_photo, extraClass, uid, isMyProfile} = props;
  const {has_main_photo} = main_photo;

  const clsAvatar = [`avatar ${extraClass}`];
  if (!has_main_photo) {
    clsAvatar.push('no_photo');
  }

  const avatarUri = () => {
    const {photo_path, photo_id, photo_extension} = main_photo;
    return `${photo_path}/${photo_id}_big.${photo_extension}`;
  };
  return uid ? (
    <Link
      to={{
        pathname: `/${uid}`,
        state: {
          modal: true,
          initialRoute: props.url,
          source: props.source
        },
      }}
      className={clsAvatar.join(' ')}>
      {has_main_photo ? (
        <img alt="" src={avatarUri()} className="img_avatar" />
      ) : null}
    </Link>
  ) : isMyProfile ? (
    <Link
      to={{
        pathname: `/my-profile`,
        state: {modal: true},
      }}
      className={clsAvatar.join(' ')}>
      {has_main_photo ? (
        <img alt="" src={avatarUri()} className="img_avatar" />
      ) : null}
    </Link>
  ) : (
    <div className={clsAvatar.join(' ')}>
      {has_main_photo ? (
        <img alt="" src={avatarUri()} className="img_avatar" />
      ) : null}
    </div>
  );
};

export {Avatar};
