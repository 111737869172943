import {
    SCREEN_MODE_CHANGE
} from '../constants';

export function screenSizeChangeListener() {
    
    return (dispatch) => {
        window.onresize = (event) => {
            if (event.currentTarget) {
                const isMobileMode = event.currentTarget.innerWidth < 1020;

                dispatch({
                    type: SCREEN_MODE_CHANGE,
                    payload: isMobileMode
                });
            }
        };
    };
}

