import axios from 'axios';
import {
    CHAT_REQUEST,
    CHAT_SUCCESS,
    CHAT_FAIL,
    CHAT_NEW_MESSAGE_RECEIVED,
    CHAT_HISTORY_REQUEST,
    CHAT_HISTORY_SUCCESS,
    CHAT_HISTORY_FAIL,
    CHAT_SEND_SUCCESS,
    CHAT_SEND_FAIL,
    CHAT_MARK_AS_READED_SUCCESS,
    CHAT_MARK_MY_MESSAGE_AS_READED,
    NOTIFICATION_SHOW,
    CHAT_TRANSLATE_MESSAGE_FAIL,
    CHAT_TRANSLATE_MESSAGE_REQUEST,
    CHAT_TRANSLATE_MESSAGE_SUCCESS,
    CHAT_CLOSE,
    CONTACTS_FOLDER_TYPE_FAVORITE,
    CONTACTS_FAVORITES_REQUEST,
    CONTACTS_REQUEST, CONTACTS_FOLDER_TYPE_ALL
} from '../constants';
import {getFormatCurrentDateTime} from '../../utils/date';
import * as contactActions from './contactActions';
import store from '../../store';

export function load(uid) {
    return (dispatch) => {
        dispatch({
            type: CHAT_REQUEST,
        });

        return axios
            .get(`/chat?user_id=${uid}`)
            .then((result) => {
                dispatch({
                    type: CHAT_SUCCESS,
                    payload: result.data,
                });
            })
            .catch((err) => {
                const error = err.response.data;

                dispatch({
                    type: CHAT_FAIL,
                    payload: error.error,
                });

                if (error.error === 'contacts_verify_photo') {
                    return;
                }

                setTimeout(() => {
                    dispatch({
                        type: NOTIFICATION_SHOW,
                        payload: {
                            type: 'error',
                            text: error.error_text,
                        },
                    });
                }, 500);
            });
    };
}

export function loadHistory(uid, msgCount) {
    return (dispatch) => {
        dispatch({
            type: CHAT_HISTORY_REQUEST,
        });

        axios
            .get(`/chat?user_id=${uid}&messages_count=${msgCount}`)
            .then((result) => {
                dispatch({
                    type: CHAT_HISTORY_SUCCESS,
                    payload: result.data,
                });
            })
            .catch(() => {
                dispatch({
                    type: CHAT_HISTORY_FAIL,
                });
            });
    };
}

export function send(message, userId, prevMsgType) {
    return (dispatch) => {
        const data = {
            message,
            user_id: userId,
        };

        if (prevMsgType) {
            data.prev_type = prevMsgType;
        }

        dispatch({
            type: CHAT_SEND_SUCCESS,
            payload: {
                message: {
                    //id: 9278
                    message: message,
                    type: 'Message',
                    date: getFormatCurrentDateTime(),
                    is_my: true,
                    //user_id_from: 113432,
                    image: null,
                    is_first: false,
                    replied: false,
                    auto_reply: false,
                },
            },
        });

        return axios
            .post('/chat/post', data)
            .then((result) => {
                dispatch(contactActions.load({page: 1, folder_type: CONTACTS_FOLDER_TYPE_ALL}, true));
            })
            .catch((err) => {
                const error = err.response.data;
                dispatch({
                    type: CHAT_SEND_FAIL,
                    payload: {
                        error: error.error_text,
                    },
                });

                dispatch({
                    type: NOTIFICATION_SHOW,
                    payload: {
                        type: 'error',
                        text: error.error_text,
                    },
                });
            });
    };
}


export function sendMessageReadStatus(userId) {
    return (dispatch) => {
        axios
            .post('/chat/read', {user_id: userId})
            .then(() => {
                dispatch({
                    type: CHAT_MARK_AS_READED_SUCCESS,
                });
            })
            .catch(() => {
            });
    };
}

export function chatClose() {
    return dispatch => {
        dispatch({
            type: CHAT_CLOSE,
        });
    }
}

export function messageReceived(msg) {
    return dispatch => {
        const {chat} = store.getState();
        if (msg.from_user_id === chat.contactUser.id) {
            axios
                .post('/chat/buy-message', {
                    message_id: msg.id,
                })
                .then(result => {
                    if (result.data.result === 'success') {
                        dispatch({
                            type: CHAT_NEW_MESSAGE_RECEIVED,
                            payload: {...msg, ...{is_free: false, is_paid: true}},
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.error === 'not_enough_coins') {
                        dispatch(load(chat.contactUser.id, true));
                    }
                    if (error.response.data.error === 'message_is_free') {
                        dispatch({
                            type: CHAT_NEW_MESSAGE_RECEIVED,
                            payload: {...msg, ...{is_free: true, is_paid: false}},
                        });
                    }
                    if (error.response.data.error === 'message_is_paid') {
                        dispatch({
                            type: CHAT_NEW_MESSAGE_RECEIVED,
                            payload: {...msg, ...{is_free: false, is_paid: true}},
                        });
                    }
                });
        }
        dispatch({
            type: CHAT_NEW_MESSAGE_RECEIVED,
            payload: msg,
        });
    };
}

export function markMessageAsReaded(uid) {
    return (dispatch) => {
        dispatch({
            type: CHAT_MARK_MY_MESSAGE_AS_READED,
            payload: {uid},
        });
    };
}

export function translateMessage(id) {
    return dispatch => {
        dispatch({
            type: CHAT_TRANSLATE_MESSAGE_REQUEST,
            payload: {id},
        });
        axios
            .post('/chat/translate-message', {
                message_id: id,
            })
            .then(result => {
                dispatch({
                    type: CHAT_TRANSLATE_MESSAGE_SUCCESS,
                    payload: {
                        id,
                        data: result.data,
                    },
                });
            })
            .catch(err => {
                dispatch({
                    type: CHAT_TRANSLATE_MESSAGE_FAIL,
                    payload: {
                        id,
                    },
                });
            });
    };
}
