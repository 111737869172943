import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {bindActionCreators} from 'redux';
import * as bindPurchaseActions from '../../store/actions/purchaseActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import __ from '../../utils/translate';
import './styles.scss';
import {isLoka, isTheyStream} from '../../apps-config';
import Iframe from '../Iframe';
import {PAYMENT_TYPE} from '../../store/constants';
import modalRouteService from '../../services/ModalRouteService';

// const PayPalButton = window.paypal.Buttons.driver('react', {React, ReactDOM});

class AddCoinsPayment extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.state = {
            amount: '',
            currency: '',
        };
    }

    handleOnClick = () => {
        this.props.eventsActions.closeModal(false);
    };

    componentDidMount() {
        modalRouteService.addPath(this.props.match.url);
        this.setState({
            amount: this.props.location.state.productAmount,
            currency: this.props.location.state.productCurrency,
        });
    }

    goBack() {
        this.props.eventsActions.closeModal(false);
        modalRouteService.removePath();
        if (modalRouteService.currentPath()) {
            this.props.history.replace({pathname: modalRouteService.currentPath()});
        } else {
            if (this.props.location.state && this.props.location.state.initialRoute) {
                this.props.history.replace({
                    pathname: this.props.location.state.initialRoute,
                });
            } else {
                this.props.history.replace({pathname: '/'});
            }
            modalRouteService.removeAllStack();
        }
        document.removeEventListener('keydown', this.escFunction, false);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.goBack();
        }
    }

    createOrder(data, actions) {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: this.state.amount,
                        currency: this.state.currency,
                    },
                },
            ],
        });
    }

    onApprove(data) {
        this.props.purchaseActions.paypalCoinsProcess(this.props.location.state.productId, data.orderID)
            .then(() => {
                if (this.props.location.state.go === 3) {
                    this.props.history.go(-3);
                } else {
                    this.props.history.go(-2);
                }
                this.props.eventsActions.closeModal(false);
                document.removeEventListener('keydown', this.escFunction, false);
            });
    }

    render() {
        const {paymentUrl} = this.props.purchase;
        const clsBody = isTheyStream()
            ? 'popup_body popup-purchase ts-popup-purchase'
            : 'popup_body popup-purchase';
        return (
            <div className={clsBody}>
                <div className="popup-purchase__head">
                    <div className="btn_close" onClick={this.goBack}>
                        <IconClose/>
                    </div>
                    <h1 className="popup-purchase__title">
                        {isLoka() ? __('Add Coins') : __('Add Hearts')}
                    </h1>
                    <p className="popup-purchase__description">
                        {__('Choose your payment type')}
                    </p>
                </div>
                {PAYMENT_TYPE === 'pw' ? <Iframe source={paymentUrl}/> : null}
                {PAYMENT_TYPE === 'paypal' &&
                this.props.credentials &&
                this.props.credentials.user_id === 136810 ? (
                    <Iframe source={paymentUrl}/>
                ) : null}
                <div className="popup-purchase__agree">
                    <Link to="/terms" onClick={() => this.handleOnClick()}>
                        {__('Terms of Service {{and}} Privacy Policy', {
                            and: <span>{__('and')}</span>,
                        })}
                    </Link>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => ({
            purchase: state.purchase,
            credentials: state.credentials,
        }),
        (dispatch) => ({
            purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
            myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
            eventsActions: bindActionCreators(bindEventsActions, dispatch),
        }),
    )(AddCoinsPayment),
);
