import React from 'react'
import {ReactComponent as IconClose} from '../../Icons/Close.svg';
import './styles.scss'
import { Button } from '../Button';

const Notification = ({title, close, textLine1, textButton}) => {
  return <>
    <div className="confirm">
      <div className="confirm__container">
        <div className="confirm__cancel" onClick={() => close()}><IconClose/></div>
        <div className="confirm__title">
          <p>{title}</p>
        </div>
        <div className="confirm__text" style={{marginBottom: 20}}>
          <p>{textLine1}</p>
        </div>
        <Button class="btn btn_primary" onClick={() => close()}>{textButton}</Button>
      </div>
    </div>
  </>
}

export {Notification}