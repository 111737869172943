import {LOAD} from 'redux-storage';
import {
    CHAT_REQUEST,
    CHAT_SUCCESS,
    CHAT_FAIL,
    CHAT_NEW_MESSAGE_RECEIVED,
    CHAT_HISTORY_REQUEST,
    CHAT_HISTORY_SUCCESS,
    CHAT_HISTORY_FAIL,
    CHAT_SEND_SUCCESS,
    CHAT_SEND_FAIL,
    CONTACT_FAVORITE_SUCCESS,
    // CONTACT_REQUEST_ACCEPT_SUCCESS,
    CHAT_UPLOAD_REQUEST,
    CHAT_UPLOAD_FILE_SUCCESS,
    CHAT_MARK_MY_MESSAGE_AS_READED,
    CONTACT_FEEDBACK_SUCCESS,
    CONTACT_NEXT_UNREAD_SUCCESS,
    CONTACT_REQUEST_ACCEPT_SUCCESS,
    GIFT_ASSIGN_SUCCESS,
    CHAT_TRANSLATE_MESSAGE_FAIL,
    CHAT_TRANSLATE_MESSAGE_REQUEST,
    CHAT_TRANSLATE_MESSAGE_SUCCESS,
    CHAT_CLOSE,
} from '../constants';

const initialState = {
    is_free_contacts_limit: false,
    messages: [],
    contactUser: {},
    contactId: null,
    showFeedbackBlock: false,
    showRatingBlock: false,
    systemUserId: null,
    needVip: false,
    need_verify: false,
    hasMore: false,
    myMessageReadStatus: null,
    totalUsersCount: 0,
    contactFolder: null,
    unread_messages_count: 0,
    nextUnreadContact: null,

    showAllowRequestBlock: false,
    hasNotAllowedRequest: false,

    hasError: false,
    errorName: null,

    isFetchingHistory: false,
    isFetching: false,
    need_coins: 0,
};

export default function chatReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD:
            return initialState;

        case CHAT_REQUEST:
            return {
                ...initialState,
                isFetching: true
            };

        case CHAT_SUCCESS:
            return {
                ...state,
                is_free_contacts_limit: action.payload.is_free_contacts_limit,
                messages: [...action.payload.messages],
                contactUser: action.payload.contact_user,
                contactId: action.payload.contact_id,
                showFeedbackBlock: action.payload.show_feedback_block,
                showRatingBlock: action.payload.show_rating_block,
                systemUserId: action.payload.system_user_id,
                needVip: action.payload.need_vip,
                need_verify: action.payload.need_verify,
                hasMore: action.payload.show_more_link,
                myMessageReadStatus: action.payload.my_message_read_status,
                totalUsersCount: action.payload.total_users_count,
                contactFolder: action.payload.contact_folder_type,
                unread_messages_count: action.payload.unread_messages_count,
                showAllowRequestBlock: action.payload.show_allow_request_block,
                hasNotAllowedRequest: action.payload.has_not_allowed_request,

                isFetching: false,
                need_coins: action.payload.need_coins,
            };

        case CHAT_CLOSE:
            return initialState;

        case CHAT_FAIL:
            return {
                ...initialState,
                hasError: true,
                errorName: action.payload,
                isFetching: false,
            };

        case CONTACT_FAVORITE_SUCCESS:
            return {
                ...state,
                contactFolder: action.payload.moveTo,
            };

        case CHAT_HISTORY_REQUEST:
            return {
                ...state,
                isFetchingHistory: true,
            };

        case CHAT_HISTORY_SUCCESS:
            return {
                ...state,
                messages: [...state.messages, ...action.payload.messages],
                systemUserId: action.payload.system_user_id,
                needVip: action.payload.need_vip,
                hasMore: action.payload.show_more_link,
                myMessageReadStatus: action.payload.my_message_read_status,
                totalUsersCount: action.payload.total_users_count,

                isFetchingHistory: false,
            };

        case CHAT_HISTORY_FAIL:
            return {
                ...state,
                isFetchingHistory: false,
            };

        case CHAT_SEND_SUCCESS:
            return {
                ...state,
                messages: [action.payload.message, ...state.messages],
                myMessageReadStatus: 'Unread',
            };

        case CHAT_SEND_FAIL:
            return {
                ...state,
                is_free_contacts_limit: true
            };

        case CHAT_NEW_MESSAGE_RECEIVED:
            if (action.payload.from_user_id === state.contactUser.id) {
                return {
                    ...state,
                    messages: [action.payload, ...state.messages],
                };
            }
            return {
                ...state,
            };

        case CHAT_MARK_MY_MESSAGE_AS_READED:
            return {
                ...state,
                myMessageReadStatus: 'None',
            };

        case CONTACT_FEEDBACK_SUCCESS:
            return {
                ...state,
                showFeedbackBlock: false,
            };

        case CHAT_UPLOAD_REQUEST:
            return {
                ...state,
                messages: [...state.messages, ...action.payload.images],
            };

        case CHAT_UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                messages: state.messages.map((message) => {
                    if (
                        message.image &&
                        message.image.file_id === action.payload.message.image.file_id
                    ) {
                        return action.payload.message;
                    }
                    return message;
                }),
            };

        case CONTACT_NEXT_UNREAD_SUCCESS:
            return {
                ...state,
                nextUnreadContact: action.payload,
            };

        case GIFT_ASSIGN_SUCCESS:
            return {
                ...state,
                isAssignedGift: action.payload === 'chat',
            };

        case CONTACT_REQUEST_ACCEPT_SUCCESS:
            return {
                ...state,
                showAllowRequestBlock: false,
            };

        case CHAT_TRANSLATE_MESSAGE_REQUEST:
            return {
                ...state,
                messages: state.messages.map(item => {
                    if (item.id === action.payload.id) {
                        item.translate_load = true;
                    }
                    return item;
                }),
            };
        case CHAT_TRANSLATE_MESSAGE_SUCCESS:
            return {
                ...state,
                messages: state.messages.map(item => {
                    if (item.id === action.payload.id) {
                        item.translate_load = false;
                        item.translate = action.payload.data.translate === null ? 'Translation is not available' : action.payload.data.translate;
                    }
                    return item;
                }),
            };
        case CHAT_TRANSLATE_MESSAGE_FAIL:
            return {
                ...state,
                messages: state.messages.map(item => {
                    if (item.id === action.payload.id) {
                        item.translate_load = false;
                    }
                    return item;
                }),
            };

        default:
            return state;
    }
}
