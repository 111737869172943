import axios from 'axios';

import {
  PEOPLE_REQUEST,
  PEOPLE_SUCCESS,
  PEOPLE_FAIL,
  PEOPLE_LOAD_MORE_REQUEST,
  PEOPLE_LOAD_MORE_SUCCESS,
  PEOPLE_LOAD_MORE_FAIL,
  PEOPLE_SET_SEARCH_PARAMS,
  LOGOUT_SUCCESS,
} from '../constants';

export function load(paramsValue) {
  return (dispatch, getState) => {
    const peopleSearch = getState().peopleSearch
    const filterParams = {
      country_id: !peopleSearch.country_id ? null : peopleSearch.country_id,
      // region_id: !peopleSearch.region_id ? null : peopleSearch.region_id,
      // city_id: !peopleSearch.city_id ? null : peopleSearch.city_id,
      formatted_address: !peopleSearch.formatted_address.length ? null : peopleSearch.formatted_address,
      languages: null || peopleSearch.languages,
      gender: null || peopleSearch.gender,
      age: peopleSearch.age[0] === 18 && peopleSearch.age[1] === 99 ? null : peopleSearch.age,
    }
    const params = {...paramsValue, ...filterParams}
    dispatch({
      type: PEOPLE_REQUEST,
    });

    return axios
      .get('/users', {params})
      .then((result) => {
        dispatch({
          type: PEOPLE_SUCCESS,
          payload: result.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;

          if (error.error === 'account_blocked') {
            dispatch({
              type: LOGOUT_SUCCESS,
            });
          }
          dispatch({
            type: PEOPLE_FAIL,
          });
        }
      });
  };
}

export function setSearchParams(params) {
  return dispatch => {
    dispatch({
      type: PEOPLE_SET_SEARCH_PARAMS,
      payload: params,
    });
  };
}


export function loadMore(params) {
  return (dispatch) => {
    dispatch({
      type: PEOPLE_LOAD_MORE_REQUEST,
    });

    return axios
      .get('/users', {params})
      .then((result) => {
        dispatch({
          type: PEOPLE_LOAD_MORE_SUCCESS,
          payload: result.data,
        });
      })
      .catch(() => {
        dispatch({
          type: PEOPLE_LOAD_MORE_FAIL,
        });
      });
  };
}

export function searchPeopleByParams(params) {
  return dispatch => {
    dispatch({
      type: PEOPLE_SET_SEARCH_PARAMS,
      payload: params,
    });
  };
}

