import {
    SCREEN_MODE_CHANGE
} from '../constants';

const initialState = {
    isMobileMode: window.innerWidth < 1020,
};

const screenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCREEN_MODE_CHANGE:
      if (state.isMobileMode !== action.payload) {
        return {
            ...state,
            isMobileMode: action.payload,
        };
      }
      return state;

    default:
      return state;
  }
};

export default screenReducer;
