import {
  LIVE_CHAT_LIST_REQUEST,
  LIVE_CHAT_LIST_SUCCESS,
  LIVE_CHAT_LIST_FAIL,
  LIVE_CHAT_FAVORITE_LIST_FAIL,
  LIVE_CHAT_FAVORITE_LIST_REQUEST,
  LIVE_CHAT_FAVORITE_LIST_SUCCESS,
  FAVORITES_ADD_SUCCESS,
} from '../constants';

const initialState = {
  items: [],
  favoriteItems: [],
  isFetching: false,
  hasMore: false,
  isFetchingMore: false,
  activeChat: null,
};

const liveChatListReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIVE_CHAT_LIST_REQUEST:
    case LIVE_CHAT_FAVORITE_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LIVE_CHAT_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };

    case LIVE_CHAT_FAVORITE_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        favoriteItems: action.payload,
      };
    case LIVE_CHAT_LIST_FAIL:
    case LIVE_CHAT_FAVORITE_LIST_FAIL:
      return {
        isFetching: false,
      };
    case FAVORITES_ADD_SUCCESS:
      const {user} = action.payload;
      let newStreams = [];
      const items = state.items;
      const item = items.filter((item) => item.user.user_id === user.user_id);
      if (user.is_favorite) {
        newStreams = state.favoriteItems.filter((item) => {
          return item.user.user_id !== user.user_id;
        });
        if (item && item.length)
          item.forEach((i) => {
            i.user.is_favorite = false;
          });
      } else {
        newStreams = state.favoriteItems;
        newStreams.unshift(...item);
        if (item && item.length)
          item.forEach((i) => {
            i.user.is_favorite = true;
          });
      }
      return {
        ...state,
        favoriteItems: newStreams,
        items,
      };

    default:
      return state;
  }
};

export default liveChatListReducer;
