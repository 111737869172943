import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import * as bindContactActions from '../../../store/actions/contactActions';
import * as bindLogoutActions from '../../../store/actions/auth/logoutActions';
import queryString from 'query-string';
import __ from '../../../utils/translate';
import {Notification} from '../../../Components/UI/Notification';
import {CONTACTS_FOLDER_TYPE_ALL} from "../../../store/constants";

class AutoLogin extends Component {
    state = {
        unsubscribeModal: false
    }

    componentDidMount() {
        const {logoutActions, credentials} = this.props;

        if (credentials.user_id) {
            logoutActions.logout().then(() => setTimeout(() => this.autoReadQuery(), 1000));
        } else {
            this.autoReadQuery();
        }
    }

    autoReadQuery() {
        const {location, authActions} = this.props;
        const params = queryString.parse(location.search);
        switch (location.pathname) {
            case '/lalh':
                if (params.token_l && params.url) {
                    authActions
                        .autologin(params.token_l)
                        .then(() => this.redirectToURL(params.url));
                }
                break;
            case '/calh':
                if (params.token_l && params.token_c && params.url) {
                    authActions
                        .autologin(params.token_l)
                        .then(() => authActions.trackEmail(params.token_c))
                        .then(() => this.redirectToURL(params.url));
                }
                break;
            case '/ualh':
                if (params.token_l) {
                    authActions
                        .autologin(params.token_l)
                        .then(() => authActions.unsubscribe(params.token_l))
                        .then(() => {
                            this.setState({unsubscribeModal: true})
                        });
                }
                break;
            default:
        }
    }

    redirectToURL(url) {
        if (url.indexOf('/chat') > -1) {
            this.props.contactActions.load({
                page: 1,
                folder_type: CONTACTS_FOLDER_TYPE_ALL,
            }).then(() => {
                const splitUrl = url.split('/');
                const id = splitUrl[splitUrl.length - 1];
                const chatId = parseInt(id, 10);
                if (typeof chatId === 'number') {
                    const contact = this.props.contacts.all.items.filter(item => {
                        return item.user_id === chatId;
                    })[0];
                    this.props.history.push({pathname: '/chat', state: {user: contact}});
                }
            });
            return;
        }
        this.props.history.push({pathname: url, state: {isAutoLogin: true}});
    }

    render() {
        return this.state.unsubscribeModal ? (
            <Notification
                textLine1={__('You have been successfully unsubscibed.')}
                textButton={__('Done')}
                title={__('Success!')}
                close={() => this.props.history.push('/')}
            />
        ) : null;
    }
}

export default withRouter(
    connect(
        (state) => ({
            registration: state.registration,
            credentials: state.credentials,
            contacts: state.contacts,
        }),
        (dispatch) => ({
            contactActions: bindActionCreators(bindContactActions, dispatch),
            authActions: bindActionCreators(bindAuthActions, dispatch),
            logoutActions: bindActionCreators(bindLogoutActions, dispatch),
        }),
    )(AutoLogin),
);
