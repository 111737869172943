import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../store/actions/auth/registrationActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import {Input} from '../../Components/UI/Input';
import {Button} from '../../Components/UI/Button';
import __ from '../../utils/translate';
import { isLoka, isTheyStream } from '../../apps-config';

const ACTIVITY_STEP = 'ACTIVITY_STEP';
const OCCUPATION_STEP = 'OCCUPATION_STEP';

class SetActivitiesOccupation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedActivities: [],
      activities: [
        {activitie: 'Local food', isSelected: false},
        {activitie: 'Local music', isSelected: false},
        {activitie: 'Local history', isSelected: false},
        {activitie: 'Local culture', isSelected: false},
        {activitie: 'Theatres', isSelected: false},
        {activitie: 'Museums', isSelected: false},
        {activitie: 'Nightlife & Bars', isSelected: false},
        {activitie: 'Restaurants', isSelected: false},
        {activitie: 'Shopping', isSelected: false},
        {activitie: 'Recreation', isSelected: false},
        {activitie: 'Sightseeing', isSelected: false},
      ],
      occupation: '',
      mobileStep: ACTIVITY_STEP,
    };
  }

  selectedItem = (item, idx) => {
    let activities = this.state.activities;
    activities[idx].isSelected = !activities[idx].isSelected;
    let selectedActivities = this.state.selectedActivities;
    const exist = selectedActivities.filter((it) => it === item.activitie)[0];
    if (exist) {
      selectedActivities.splice(
        selectedActivities.indexOf(
          selectedActivities.filter((it) => it === item.activitie)[0],
        ),
        1,
      );
    } else {
      selectedActivities.push(item.activitie);
    }
    this.setState({
      selectedActivities,
      activities,
    });
    this.props.myProfileActions.clearErrors();
  };

  componentDidMount() {
    this.props.myProfileActions.clearErrors();
    if (this.props.location.pathname === '/activities-occupation/occupation') {
      this.setState({mobileStep: OCCUPATION_STEP});
    }
    this.props.history.listen((location, action) => {
      if (location.pathname === '/activities-occupation/occupation') {
        this.setState({mobileStep: OCCUPATION_STEP});
      }
    });
  }

  componentWillUnmount() {
    this.props.myProfileActions.clearErrors();
  }

  hasErrors() {
    return 'occupation' in this.props.myProfile.errors;
  }

  handleOnChangeOccupation = (event) => {
    this.setState({occupation: event.target.value});
    this.props.myProfileActions.clearErrors();
  };

  skip = () => {
    this.props.history.replace(
      '/',
      '/activities-occupation/activity',
    );
    this.props.registrationActions.done();
  };

  done = () => {
    this.props.registrationActions.done();
  };

  handlePressMobileContinue = () => {
    if (this.state.mobileStep === ACTIVITY_STEP) {
      this.setState({mobileStep: OCCUPATION_STEP});
    }
    if (this.state.mobileStep === OCCUPATION_STEP) {
      this.handlePressContinue();
    }
  };

  handlePressContinue = () => {
    const activities = this.state.selectedActivities;
    this.props.myProfileActions
      .save({activities: {...activities}, occupation: this.state.occupation})
      .then(this.done())
      .catch(() => null);
  };

  render() {
    const isMobileMode = this.props.screen.isMobileMode;

    let title = __('A bit more about you…');
    let subTitle = isTheyStream() ? __("Let's enrich your Theystream experience") : __("Let's enrich your Loka experience");
    if (isMobileMode) {
      switch (this.state.mobileStep) {
        case ACTIVITY_STEP:
          title = __('Your activities');
          subTitle = __('What local activities are you an expert in?');
          break;
        case OCCUPATION_STEP:
          title = __('Your occupation');
          subTitle = __('How do you make a living?');
          break;
        default:
      }
    }
    const clsCard = isLoka()
      ? 'card_form step_form'
      : 'card_form step_form ts-step_form';
    return (
      <main
        style={
          this.props.screen.isMobileMode
            ? {
                paddingTop: 0,
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }
            : {}
        }>
        <div className="container">
          <div className="wrapper">
            <div
              className={clsCard}
              style={
                this.props.screen.isMobileMode
                  ? {boxShadow: 'none', padding: 0}
                  : {}
              }>
              <div className="left_wrap">
                <div className="top_wrap">
                  <h3 className="step_title mobile_step_title">{title}</h3>
                  <p className="step_text mobile_step_text">{subTitle}</p>
                </div>
                {!isMobileMode ? (
                  <div onClick={this.skip} className="step_skip btn_link">
                    {__('Skip this step')}
                  </div>
                ) : null}
              </div>
              <div className="right_wrap">
                <form>
                  <div
                    className="form_control"
                    style={
                      !isMobileMode
                        ? {}
                        : this.state.mobileStep === ACTIVITY_STEP
                        ? {}
                        : {display: 'none'}
                    }>
                    {!isMobileMode ? (
                      <>
                        <label className="form_label">
                          {__('Your activities')}
                        </label>
                        <p className="form_text">
                          {__(
                            'In what aspects of local life are you an expert?',
                          )}
                        </p>
                      </>
                    ) : null}
                    <div className="activities_wrap">
                      {this.state.activities.map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => this.selectedItem(item, index)}
                            className={
                              item.isSelected
                                ? 'activities_item active'
                                : 'activities_item'
                            }>
                            {__(`${item.activitie}`)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className="form_control"
                    style={
                      !isMobileMode
                        ? {}
                        : this.state.mobileStep === OCCUPATION_STEP
                        ? {}
                        : {display: 'none'}
                    }>
                    {!isMobileMode ? (
                      <label className="form_label">
                        {__('Your occupation')}
                      </label>
                    ) : null}

                    <Input
                      invalid={this.hasErrors()}
                      error={
                        !this.hasErrors()
                          ? null
                          : this.props.registration.errors.occupation
                      }
                      type="text"
                      value={this.state.occupation}
                      onChange={(event) => this.handleOnChangeOccupation(event)}
                      placeholder={__('Enter your occupation')}
                    />
                  </div>
                  {!isMobileMode ? (
                    <Button
                      onClick={this.handlePressContinue}
                      disabled={
                        !(this.state.occupation.length > 2) ||
                        this.props.myProfile.isFetching
                      }>
                      {this.props.myProfile.isFetching
                        ? __('Loading...')
                        : __('Create my profile')}
                    </Button>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
        {isMobileMode ? (
          <div style={{paddingLeft: 15, paddingRight: 15}}>
            <Button
              onClick={this.handlePressMobileContinue}
              disabled={
                (this.state.mobileStep === OCCUPATION_STEP &&
                  !(this.state.occupation.length > 2)) ||
                this.props.myProfile.isFetching
              }>
              {this.props.myProfile.isFetching
                ? __('Loading...')
                : isMobileMode
                ? this.state.mobileStep === ACTIVITY_STEP
                  ? __('Continue')
                  : __('Create my profile')
                : __('Create my profile')}
            </Button>
          </div>
        ) : null}
      </main>
    );
  }
}

export default connect(
  (state) => ({
    registration: state.registration,
    myProfile: state.myProfile,
    screen: state.screen,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
  }),
)(SetActivitiesOccupation);
