import React, {Component} from 'react';
import AuthMenu from '../../Components/Navigation/AuthMenu';
import {connect} from 'react-redux';
import {isLoka} from '../../apps-config';

class AuthLayout extends Component {
  render() {
    const isMobileMode = this.props.screen.isMobileMode;
    const clsMain = isLoka() ? 'layout-auth' : 'layout-auth ts-layout-auth';
    return (
      <div
        className={clsMain}
        style={
          this.props.isRegistrationPages && isMobileMode
            ? {
                backgroundColor: isLoka() ? 'white' : 'black',
                minHeight: isLoka() ? '100%' : '100vh',
              }
            : {}
        }>
        <AuthMenu isRegistrationPages={this.props.isRegistrationPages} />
        {this.props.children}
      </div>
    );
  }
}

export default connect((state) => ({
  screen: state.screen,
}))(AuthLayout);
