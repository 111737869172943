import {
  REGISTRATION_STARTS,
  REGISTRATION_DONE,
  REGISTRATION_SET_STEP,
  REGISTRATION_ERRORS_CLEAR,
  REGISTRATION_SET_EMAIL_SUCCESS,
  REGISTRATION_SET_EMAIL_FAIL,
  REGISTRATION_REQUEST_CHECK_EMAIL,
  REGISTRATION_REQUEST,
  REGISTRATION_FAIL,
  REGISTRATION_SUCCESS,
} from '../../constants';

const initialState = {
  name: '',
  day: '',
  month: '',
  year: '',
  email: '' || localStorage.getItem('lk-email'),
  gender: '', // пол, нужно добавить
  secret: '',
  token: '',
  user_id: null,
  errors: {},
  isFetching: false,
  inProgress: false,
  currentStep: '1',
  emptyFields: null
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION_STARTS:
      return {
        ...state,
        inProgress: true,
      };
    case REGISTRATION_DONE:
      return {
        ...state,
        inProgress: false,
      };
    case REGISTRATION_SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case REGISTRATION_ERRORS_CLEAR:
      return {
        ...state,
        errors: {},
      };
    case REGISTRATION_REQUEST:
      return {
        ...state,
        errors: {},
        isFetching: true,
      };
    case REGISTRATION_SET_EMAIL_FAIL:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    case REGISTRATION_REQUEST_CHECK_EMAIL:
      return {
        ...state,
        errors: {},
        isFetching: true,
      };
    case REGISTRATION_SET_EMAIL_SUCCESS:
      return {
        ...state,
        email: action.payload,
        errors: {},
        isFetching: false,
      };
    case REGISTRATION_FAIL:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    case REGISTRATION_SUCCESS:
      let currentStep = '3';
      if (action.payload.user_data.gender === 'None' || action.payload.user_data.birthday === '0000-00-00' || action.payload.user_data.name.length) {
        currentStep = '0';
      }
      if (!action.payload.user_data.email) {
        currentStep = '4';
      }
      // вот тут надо записать данные в credentials
      return {
        ...state,
        errors: {},
        day: action.payload.day,
        month: action.payload.month,
        year: action.payload.year,
        name: action.payload.user_data.name && action.payload.user_data.name.length ? action.payload.user_data.name : action.payload.name,
        gender: action.payload.gender,
        isFetching: false,
        email: action.payload.user_data.email,
        user_id: action.payload.user_data.user_id,
        secret: action.payload.secret,
        token: action.payload.token,
        currentStep,
        emptyFields: action.payload.empty_fields
      };
    default:
      return state;
  }
};

export default registrationReducer;
