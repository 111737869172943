import axios from 'axios';
import {
    LOGOUT_SUCCESS,
    PURCHASE_PRODUCTS_REQUEST,
    PURCHASE_PRODUCTS_SUCCESS,
    PURCHASE_PRODUCTS_FAIL,
    // PURCHASE_COINS_SUCCESS,
    PURCHASE_PAYPAL_CREATE_REQUEST,
    PURCHASE_PAYPAL_CREATE_SUCCESS,
    PURCHASE_PAYPAL_CREATE_FAIL,
    PURCHASE_PAYPAL_CAPTURE_REQUEST,
    PURCHASE_PAYPAL_CAPTURE_SUCCESS,
    PURCHASE_PAYPAL_CAPTURE_FAIL,
    PURCHASE_PAYPAL_SUBSCRIPTIONS_REQUEST,
    PURCHASE_PAYPAL_SUBSCRIPTIONS_SUCCESS,
    PURCHASE_PAYPAL_SUBSCRIPTIONS_FAIL,
    PURCHASE_PAYPAL_COINS_REQUEST,
    PURCHASE_PAYPAL_COINS_SUCCESS,
    PURCHASE_PAYPAL_COINS_FAIL,
    PW_PAYMENT_URL_REQUEST,
    PW_PAYMENT_URL_SUCCESS,
    PW_PAYMENT_URL_FAIL,
    STRIPE_GET_SESSION_REQUEST,
    STRIPE_GET_SESSION_SUCCESS,
    STRIPE_GET_SESSION_FAIL,
} from '../constants';

export function loadProducts() {
    return (dispatch) => {
        dispatch({
            type: PURCHASE_PRODUCTS_REQUEST,
        });

        return axios
            .post('/billing/getTariffs', {platform: 'web', type: 'coins'})
            .then((result) => {
                dispatch({
                    type: PURCHASE_PRODUCTS_SUCCESS,
                    payload: result.data,
                });
            })
            .catch((err) => {
                if (err.response) {
                    const error = err.response.data;

                    if (error.error === 'account_blocked') {
                        dispatch({
                            type: LOGOUT_SUCCESS,
                        });
                    }
                    dispatch({
                        type: PURCHASE_PRODUCTS_FAIL,
                    });
                }
            });
    };
}

export function payPalCreate(productId) {
    return (dispatch, getState) => {
        dispatch({
            type: PURCHASE_PAYPAL_CREATE_REQUEST,
        });
        const {
            credentials: {token},
        } = getState();

        return axios
            .post('/billing/paypalCreate', {productId, token})
            .then((result) => {
                dispatch({
                    type: PURCHASE_PAYPAL_CREATE_SUCCESS,
                    payload: result.data,
                });
                window.location.href = `${result.data.link}`
            })
            .catch((err) => {
                if (err.response) {
                    const error = err.response.data;

                    if (error.error === 'account_blocked') {
                        dispatch({
                            type: LOGOUT_SUCCESS,
                        });
                    }
                    dispatch({
                        type: PURCHASE_PAYPAL_CREATE_FAIL,
                    });
                }
            });
    };
}

export function payPallCapture(orderId) {
    return (dispatch, getState) => {
        dispatch({
            type: PURCHASE_PAYPAL_CAPTURE_REQUEST,
        });
        const {
            credentials: {token},
        } = getState();

        return axios
            .post('/billing/paypalCapture', {orderId, token})
            .then((result) => {
                dispatch({
                    type: PURCHASE_PAYPAL_CAPTURE_SUCCESS,
                    payload: result.data,
                });
            })
            .catch((err) => {
                if (err.response) {
                    const error = err.response.data;

                    if (error.error === 'account_blocked') {
                        dispatch({
                            type: LOGOUT_SUCCESS,
                        });
                    }
                    dispatch({
                        type: PURCHASE_PAYPAL_CAPTURE_FAIL,
                    });
                }
            });
    };
}

export function paypalSubscriptionProcess(productId, subscriptionId) {
    return (dispatch, getState) => {
        dispatch({
            type: PURCHASE_PAYPAL_SUBSCRIPTIONS_REQUEST,
        });
        const {
            credentials: {token},
        } = getState();

        return axios
            .post('/billing/paypalSubscriptionProcess', {
                productId,
                subscriptionId,
                token,
            })
            .then((result) => {
                dispatch({
                    type: PURCHASE_PAYPAL_SUBSCRIPTIONS_SUCCESS,
                    payload: result.data,
                });
            })
            .catch((err) => {
                if (err.response) {
                    const error = err.response.data;

                    if (error.error === 'account_blocked') {
                        dispatch({
                            type: LOGOUT_SUCCESS,
                        });
                    }
                    dispatch({
                        type: PURCHASE_PAYPAL_SUBSCRIPTIONS_FAIL,
                    });
                }
            });
    };
}

export function paypalCoinsProcess(productId, orderId) {
    return (dispatch, getState) => {
        dispatch({
            type: PURCHASE_PAYPAL_COINS_REQUEST,
        });
        const {
            credentials: {token},
        } = getState();

        return axios
            .post('/billing/paypalCoinsProcess', {productId, orderId, token})
            .then((result) => {
                dispatch({
                    type: PURCHASE_PAYPAL_COINS_SUCCESS,
                    payload: result.data,
                });
            })
            .catch((err) => {
                if (err.response) {
                    const error = err.response.data;

                    if (error.error === 'account_blocked') {
                        dispatch({
                            type: LOGOUT_SUCCESS,
                        });
                    }
                    dispatch({
                        type: PURCHASE_PAYPAL_COINS_FAIL,
                    });
                }
            });
    };
}

export function getPaymentUrl(productId) {
    return (dispatch, getState) => {
        dispatch({type: PW_PAYMENT_URL_REQUEST});
        const {
            credentials: {token},
        } = getState();
        const params = {productId, token};
        axios
            .get(`/billing/pwGetPaymentUrl`, {params})
            .then((result) => {
                dispatch({
                    type: PW_PAYMENT_URL_SUCCESS,
                    payload: result.data.url,
                });
            })
            .catch((err) => {
                dispatch({type: PW_PAYMENT_URL_FAIL});
            });
    };
}

export function stripeGetSession(productId) {
    return (dispatch, getState) => {
        dispatch({type: STRIPE_GET_SESSION_REQUEST});
        const {
            credentials: {token},
        } = getState();
        if (window.Stripe) {
            let keyStripe =
                'pk_live_51Ibib2CmlwltT9O4Zf3gEh2ntewo7tfG6uig0HFpWYddu6kpMaRlvphrXyau0gOQrtDSwaTZjzPO0cEvdALStdX000MJbcFhUh';
            if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
                keyStripe =
                    'pk_test_51Ibib2CmlwltT9O4eeyBBUR65z7SognaQMCwWzESDidTVhekI2hRtd3AakxbofIR8xFYsEnnveUa9gb8GGzM4yXa00pLXBzeF8';
            }
            var stripe = window.Stripe(keyStripe);
        }
        const params = {productId, token};
        axios
            .get(`/billing/stripeGetSession`, {params})
            .then((result) => {
                dispatch({
                    type: STRIPE_GET_SESSION_SUCCESS,
                    payload: result.data.session,
                });
                stripe.redirectToCheckout({sessionId: result.data.session});
            })
            .catch((err) => {
                dispatch({type: STRIPE_GET_SESSION_FAIL});
            });
    };
}
