import {
  PURCHASE_PRODUCTS_FAIL,
  PURCHASE_PRODUCTS_REQUEST,
  PURCHASE_PRODUCTS_SUCCESS, PW_PAYMENT_URL_SUCCESS, STRIPE_GET_SESSION_SUCCESS,
} from '../constants';

const initialState = {
  tariffs: {
    coins: [],
    subscriptions: [],
  },
  isFetching: false,
  error: null,
  paymentUrl: '',
  stripeSession: ''
};

const purchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case PURCHASE_PRODUCTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case PURCHASE_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tariffs: {
          coins: action.payload.coins,
          subscriptions: action.payload.subscriptions,
        },
      };
    case PURCHASE_PRODUCTS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case PW_PAYMENT_URL_SUCCESS:
      return {
        ...state,
        paymentUrl: action.payload
      }
    case STRIPE_GET_SESSION_SUCCESS:
      return {
        ...state,
        stripeSession: action.payload
      }

    default:
      return state;
  }
}

export default purchaseReducer