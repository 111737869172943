import React, {useState} from 'react';
import {ReactComponent as IconArrowDown} from '../../Icons/ArrowDown.svg';
import styled from 'styled-components';

const DropDownWrap = styled('div')`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const DropDownList = styled('ul')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -1px;
  width: calc(100% + 2px);
  height: auto;
  max-height: 440px;
  overflow-y: auto;
  display: none;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 #c3d2db;
  border: solid 1px #c3d2db;
  background-color: #ffffff;
  z-index: 100;
  display: block;
`;

const ListItem = styled('li')`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 30px;
  line-height: 30px;
  transition: all 0.3s ease;
  &:hover {
    background: #f8f8f8;
    cursor: pointer;
  }
`;

const Dropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const clsDropDownWrap = ['form_input', 'dropdown_value'];
  if (selectedOption) {
    clsDropDownWrap.push('selected');
  }
  if (props.defaultValue === 'Day') {
    clsDropDownWrap.push('dropdown_day');
  }
  if (props.defaultValue === 'Year') {
    clsDropDownWrap.push('dropdown_year');
  }
  if (props.defaultValue === 'Month') {
    clsDropDownWrap.push('dropdown_month');
  }

  return (
    <DropDownWrap className={clsDropDownWrap.join(' ')} onClick={toggling}>
      <input type="hidden" value={selectedOption || props.defaultValue} />
      {selectedOption || props.defaultValue}
      <span className="dropdown_arrow">
        <IconArrowDown />
      </span>

      {isOpen && (
        <DropDownList>
          {props.options.map((option) => (
            <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
              {option}
            </ListItem>
          ))}
        </DropDownList>
      )}
    </DropDownWrap>
  );
};

export {Dropdown};
