import __ from '../utils/translate';

export const genders = [
  {id: 'M', name: 'Male'},
  {id: 'F', name: 'Female'},
];

export const languages = [
  {id: 1, name: __('English')},
  {id: 2, name: __('German')},
  {id: 3, name: __('French')},
  {id: 4, name: __('Spanish')},
  {id: 5, name: __('Italian')},
  {id: 6, name: __('Arabic')},
  {id: 7, name: __('Armenian')},
  {id: 8, name: __('Belorussian')},
  {id: 9, name: __('Bulgarian')},
  {id: 10, name: __('Catalan')},
  {id: 11, name: __('Chinese')},
  {id: 12, name: __('Croatian')},
  {id: 13, name: __('Czech')},
  {id: 14, name: __('Danish')},
  {id: 15, name: __('Dutch')},
  {id: 16, name: __('Estonian')},
  {id: 17, name: __('Filipino')},
  {id: 18, name: __('Finnish')},
  {id: 19, name: __('Greek')},
  {id: 20, name: __('Hebrew')},
  {id: 21, name: __('Hindi')},
  {id: 22, name: __('Hungarian')},
  {id: 23, name: __('Icelandic')},
  {id: 24, name: __('Indonesian')},
  {id: 25, name: __('Japanese')},
  {id: 26, name: __('Korean')},
  {id: 27, name: __('Kurdish')},
  {id: 28, name: __('Latvian')},
  {id: 29, name: __('Lithuanian')},
  {id: 30, name: __('Nepali')},
  {id: 31, name: __('Norwegian')},
  {id: 32, name: __('Persian')},
  {id: 33, name: __('Polish')},
  {id: 34, name: __('Portuguese')},
  {id: 35, name: __('Romanian')},
  {id: 36, name: __('Russian')},
  {id: 37, name: __('Serbian')},
  {id: 38, name: __('Slovak')},
  {id: 39, name: __('Slovenian')},
  {id: 40, name: __('Swedish')},
  {id: 41, name: __('Thai')},
  {id: 42, name: __('Turkish')},
  {id: 43, name: __('Ukrainian')},
  {id: 44, name: __('Urdu')},
  {id: 45, name: __('Vietnamese')},
];