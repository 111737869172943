import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {showDailyCoins} from '../../store/actions/eventsActions';
import {ReactComponent as IconClose} from '../Icons/Close.svg';
import {Button} from '../UI';
import './styles.scss';
import __ from '../../utils/translate';
import {isTheyStream} from '../../apps-config';

const DailyCoins = () => {
  const {dailyCoins} = useSelector((state) => state.events);
  const dispatch = useDispatch();

  const hideDailyCoins = () => {
    dispatch(showDailyCoins(false));
  };

  const clsBody = isTheyStream() ? 'ts-server-error' : 'server-error';
  const clsContainer = isTheyStream()
    ? 'ts-server-error__container'
    : 'server-error__container';
  const clsTitle = isTheyStream()
    ? 'ts-server-error__title'
    : 'server-error__title';
  const clsCancel = isTheyStream()
    ? 'ts-server-error__cancel'
    : 'server-error__cancel';
  const clsText = isTheyStream()
    ? 'ts-server-error__text'
    : 'server-error__text';

  return dailyCoins ? (
    <div className={clsBody}>
      <div className={clsContainer}>
        <div className={clsCancel} onClick={hideDailyCoins}>
          <IconClose />
        </div>
        <img style={{width: 42, height: 37}} alt="heart" src="/img/heart.png" />
        <h4 className={clsTitle}>
          {isTheyStream()
            ? __('Your free \n5 daily Hearts')
            : __('Your free \n5 daily Coins')}
          …
        </h4>
        <p className={clsText}>
          {isTheyStream()
            ? __(
                'As a Premium user, you’ve earned your 5 daily Hearts. Come again tomorrow to get more!',
              )
            : __(
                'As a Premium user, you’ve earned your 5 daily Coins. Come again tomorrow to get more!',
              )}
        </p>
        <Button class="action" onClick={hideDailyCoins}>
          {isTheyStream()
            ? __('Grab your free Hearts')
            : __('Grab your free Coins')}
        </Button>
      </div>
    </div>
  ) : null;
};

export default DailyCoins;
