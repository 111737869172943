import gettext from 'gettext.js';
import detectBrowserLanguage from 'detect-browser-language';

const i18n = gettext();

const langs = [
  'tr', // турецкий
  'ar', // арабский
  'iw', // иврит
  'de', // немецкий
  'ru', // русский
  'es', // испанский
  'it', // итальянский
  'fr', // французский
  'el', // греческий
  'pl', // польский
  'nl', // голландский
  'cs', // чешский
];

let jsonData;
const brawserLang = detectBrowserLanguage();
let locale = brawserLang.split('-')[0];

export const deviceLocale = locale;

if (langs.includes(locale)) {
  switch (locale) {
    case 'en':
      jsonData = require('./translations/ar');
      break;
    // турецкий
    case 'tr':
      jsonData = require('./translations/tr');
      break;
    // арабский
    case 'ar':
      jsonData = require('./translations/ar');
      break;
    // иврит
    case 'iw':
      jsonData = require('./translations/iw');
      break;
    // немецкий
    case 'de':
      jsonData = require('./translations/de');
      break;
    // русский
    case 'ru':
      jsonData = require('./translations/ru');
      break;
    // испанский
    case 'es':
      jsonData = require('./translations/es');
      break;
    // итальянский
    case 'it':
      jsonData = require('./translations/it');
      break;
    // французский
    case 'fr':
      jsonData = require('./translations/fr');
      break;
    // греческий
    case 'el':
      jsonData = require('./translations/el');
      break;
    // польский
    case 'pl':
      jsonData = require('./translations/pl');
      break;
    // голландский
    case 'nl':
      jsonData = require('./translations/nl');
      break;
    // чешский
    case 'cs':
      jsonData = require('./translations/cs');
      break;
    default:
  }
  // console.warn('jsonData: ', jsonData);
  i18n.setLocale(locale);
  i18n.loadJSON(jsonData);
}

export const json = jsonData;

export default i18n;
