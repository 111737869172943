import axios from 'axios';
//import {LoginManager, AccessToken} from 'react-native-fbsdk';

import store from '../../../store';

import { LOGOUT_SUCCESS } from '../../constants';

export function logout() {
  const state = store.getState();
  const {pushNotificationId} = state.session;

  return (dispatch) => {
    // Logout from facebook.
    // AccessToken.getCurrentAccessToken().then((data) => {
    //   if (data !== null) {
    //     LoginManager.logOut();
    //   }
    // });

    return axios.post('/logout', {notification_id: pushNotificationId}).then((result) => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
      localStorage.removeItem('lk-token');
      localStorage.removeItem('lk-secret');
      localStorage.removeItem('lk-uid');
    }).catch((err) => {
    });
    // return Promise.resolve();
  };
}
