import {PEOPLE_SEARCH_SET_PARAMS, PEOPLE_SEARCH_RESET} from '../constants';

export const setSearchParams = (params) => (dispatch) => {
  return dispatch({
    type: PEOPLE_SEARCH_SET_PARAMS,
    payload: params,
  });
};

export const reset = () => (dispatch) => {
  return dispatch({
    type: PEOPLE_SEARCH_RESET,
  });
};
