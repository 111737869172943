import axios from 'axios';
import isEmail from 'is-email';
import isEmpty from 'lodash/isEmpty';
import __ from '../../../utils/translate'

import {
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERRORS_CLEAR,
    SOCIAL_LOGIN_SUCCESS,
    NOTIFICATION_SHOW,
    EVENTS_CLOSE_MODAL,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_REQUEST,
    AUTO_LOGIN_REQUEST,
    // AUTO_LOGIN_SUCCESS,
    AUTO_LOGIN_FAIL,
    REGISTRATION_STARTS,
    REGISTRATION_SUCCESS,
    RESTORE_PASSWORD_REQUEST,
    RESTORE_PASSWORD_SUCCESS,
    RESTORE_PASSWORD_FAIL
} from '../../constants';

import store from '../../../store';

import {loadMyProfile} from '../myProfileActions';
import {config} from '../sessionActions';
import {logout} from './logoutActions';

const postLoginHandler = (newConfigVersion, dispatch) => {
    const currentState = store.getState();
    if (currentState) {
        const currentConfigVersion = currentState.session.config_version; // проверить какой конфиг в session.config_version
        if (newConfigVersion !== currentConfigVersion) {
            config(dispatch);
        }
    }
    loadMyProfile(dispatch);
};

export const loginSocial = (provider, body) => {
    return dispatch => {
        dispatch({
            type: LOGIN_REQUEST,
        });


        return axios
            .post(`/login/${provider}`, body)
            .then(result => {
                // Alert.alert(' GoogleSignin error', JSON.stringify(result));
                const {data} = result;
                let loginData = data;
                if (provider === 'fb') {
                    loginData.fbAccessToken = body.fb_token;
                } else {
                    loginData.fbAccessToken = '';
                }
                if (!data.is_registered) {
                    // логин
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: data,
                    });

                    postLoginHandler(data, dispatch);
                    postLoginHandler(result.data.config_version, dispatch);
                } else {
                    // рега
                    dispatch({
                        type: REGISTRATION_SUCCESS,
                        payload: data,
                    });
                    dispatch({
                        type: REGISTRATION_STARTS,
                    });
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: loginData,
                    });
                    // отправляем на дозаполнение данных на основе emptyFileds
                    // const {empty_fields} = data;
                    // const steps = Object.keys(empty_fields).filter(
                    //   key => !!empty_fields[key],
                    // );
                }
            })
            .catch(error => {
            });
    };
};

export const login = (body) => {
    const errors = {};
    const email = body.email.trim();

    return (dispatch) => {
        dispatch({
            type: LOGIN_REQUEST,
        });

        if (!isEmail(email)) {
            errors.email = __('Email and password do not match.');
        }

        if (body.password === '') {
            errors.password = __('Email and password do not match.');
        }

        if (!isEmpty(errors)) {
            dispatch({
                type: LOGIN_FAIL,
                payload: errors,
            });
            return;
        }

        return axios
            .post('/login', body)
            .then((result) => {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: result.data,
                });
                dispatch({
                    type: EVENTS_CLOSE_MODAL,
                });
                const {user_data} = result.data;
                localStorage.setItem('lk-token', result.data.token);
                localStorage.setItem('lk-secret', result.data.secret);
                localStorage.setItem('lk-uid', result.data.user_id);

                postLoginHandler(user_data, dispatch);
                postLoginHandler(result.data.config_version, dispatch);
            })
            .catch((err) => {
                if (err.response) {
                    const error = err.response.data;
                    errors.email = __('Internal error. Please try later.');
                    if (error.error === 'wrong_password') {
                        errors.email = __('Email and password do not match.');
                    } else if (error.error === 'account_blocked') {
                        errors.email = __('Your account has been blocked');
                    }
                }

                dispatch({
                    type: LOGIN_FAIL,
                    payload: errors,
                });
            });
    };
};

export const autologin = (token) => {
    return (dispatch) => {
        dispatch({
            type: AUTO_LOGIN_REQUEST,
        });

        return axios
            .get(`/login/magic-link?token=${token}`)
            .then((result) => {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: result.data,
                });
                const {user_data} = result.data;
                localStorage.setItem('lk-token', result.data.token);
                localStorage.setItem('lk-secret', result.data.secret);
                localStorage.setItem('lk-uid', result.data.user_id);
                postLoginHandler(user_data, dispatch);
                postLoginHandler(result.data.config_version, dispatch);
            })
            .catch((err) => {
                const error = err.response.data;
                dispatch({
                    type: AUTO_LOGIN_FAIL,
                    payload: error,
                });
                return Promise.reject(new Error('fail'));
            });
    };
};

export const trackEmail = (token_c) => {
    return (dispatch) => {
        return axios.get(`/track-email/follow?token_c=${token_c}`).catch((err) => {
            return Promise.reject(new Error('fail'));
        });
    };
};

export const unsubscribe = (token) => {
    return (dispatch) => {
        return axios.post(`/user-settings/unsubscribe`, {token}).catch((err) => {
            return Promise.reject(new Error('fail'));
        });
    };
};

export const resetPassword = (body) => {
    const errors = {};
    const email = body.email.trim();
    return (dispatch) => {
        dispatch({
            type: RESET_PASSWORD_REQUEST,
        });

        return axios
            .post('/restorePassword/simpleRestore', body)
            .then((result) => {
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    payload: result.data,
                });
                dispatch({
                    type: EVENTS_CLOSE_MODAL,
                });
            })
            .catch((err) => {
                if (err.response) {
                    const error = err.response.data;
                    errors.email = 'Internal error. Please try later.';
                    if (error.error === 'wrong_email') {
                        errors.email = 'Wrong email';
                    } else if (error.error === 'internal_error') {
                        errors.email = 'Internal error';
                    }

                    dispatch({
                        type: RESET_PASSWORD_FAIL,
                        payload: errors,
                    });
                }
            });
    };
};

export const restorePassword = (body) => {
    const errors = {};
    return (dispatch) => {
        dispatch({
            type: RESTORE_PASSWORD_REQUEST,
        });

        return axios
            .post('/restorePassword/simpleChange', body)
            .then((result) => {
                dispatch({
                    type: RESTORE_PASSWORD_SUCCESS,
                    payload: result.data,
                });
                dispatch({
                    type: EVENTS_CLOSE_MODAL,
                });
            })
            .catch((err) => {
                if (err.response) {
                    const error = err.response.data;
                    errors.password = 'Internal error. Please try later.';
                    if (error.error === 'wrong_hash') {
                        errors.password = 'Wrong hash';
                    } else if (error.error === 'internal_error') {
                        errors.email = 'Internal error';
                    }

                    dispatch({
                        type: RESTORE_PASSWORD_FAIL,
                        payload: errors,
                    });
                }
            });
    };
};

export const loginBySecret = (body, cbOk = () => {
}, cbFail = () => {
}) => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_REQUEST,
        });
        return axios
            .post('/login', body)
            .then((result) => {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: result.data,
                });
                const {user_data} = result.data.config_version;
                postLoginHandler(user_data, dispatch);
                postLoginHandler(result.data.config_version, dispatch);
                cbOk(result);
            })
            .catch((error) => {
                store.dispatch(logout());
                cbFail(error);
            });
    };
};

export const loginBySocial = (provider, body) => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_REQUEST,
        });

        axios
            .post(`/login/${provider}`, body)
            .then((result) => {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: result.data,
                });

                dispatch({
                    type: SOCIAL_LOGIN_SUCCESS,
                });

                const {user_data} = result.data;

                postLoginHandler(user_data, dispatch);
                postLoginHandler(result.data.config_version, dispatch);
            })
            .catch((err) => {
                if (err.response) {
                    const error = err.response.data;
                    let msgError = 'Internal error. Please try later.';
                    if (error.error === 'social_empty_email') {
                        msgError =
                            __('Connect an email to your social account or use a different way of registration.');
                    } else if (error.error === 'account_blocked') {
                        msgError = __('Your account has been blocked');
                    }

                    dispatch({
                        type: NOTIFICATION_SHOW,
                        payload: {
                            type: 'error',
                            text: msgError,
                        },
                    });
                }
            });
    };
};

export const clearErrors = () => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_ERRORS_CLEAR,
        });
    };
};
