import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {ReactComponent as IconWhiteClose} from '../../Components/Icons/WhiteClose.svg';
import {bindActionCreators} from 'redux';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import __ from '../../utils/translate';
import __reverse from '../../utils/reverse';
import './style.scss';
import {LoadPhotoBtn} from '../../Components/UI/LoadPhotoBtn';
import {Input} from '../../Components/UI/Input';
import TextareaAutosize from 'react-autosize-textarea';
import {Button} from '../../Components/UI/Button';
import {DayPicker, MonthPicker, YearPicker} from '../../Components/BirthDate';
import Location from '../../Components/Location';
import * as bindUploadActions from '../../store/actions/uploadActions';
import LanguagesField from '../../Components/LanguagesField';
import {languages} from '../../config/searchConfig';
import {monthByNumber, numberByMonth} from '../../Components/BirthDate/helper';
import {isLoka} from '../../apps-config';
import modalRouteService from '../../services/ModalRouteService';
import Carousel from '../../Components/Сarousel';

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      about: '',
      name: '',
      year: '',
      month: '',
      day: '',
      selectedActivities: [],
      activities: [
        {activitie: 'Local food', isSelected: false},
        {activitie: 'Local music', isSelected: false},
        {activitie: 'Local history', isSelected: false},
        {activitie: 'Local culture', isSelected: false},
        {activitie: 'Theatres', isSelected: false},
        {activitie: 'Museums', isSelected: false},
        {activitie: 'Nightlife & Bars', isSelected: false},
        {activitie: 'Restaurants', isSelected: false},
        {activitie: 'Shopping', isSelected: false},
        {activitie: 'Recreation', isSelected: false},
        {activitie: 'Sightseeing', isSelected: false},
      ],
      occupation: '',
      imageURI: '',
      image: null,
      selectedLanguages: [],
      selectLocation: {},
    };
  }

  componentDidMount() {
    this.props.myProfileActions.loadMyProfile().then(() => {
      let initActivity;
      try {
        initActivity =
          this.props.myProfile.activities === null
            ? []
            : JSON.parse(this.props.myProfile.activities);
      } catch (e) {
        initActivity = [];
      }

      modalRouteService.addPath(this.props.match.url);

      this.setState(
        {
          name: this.props.myProfile.name,
          about: this.props.myProfile.about,
          selectedLanguages: languages.filter((language) =>
            this.props.myProfile.speak_languages.includes(language.id),
          ),
          year: +new Date(
            this.props.myProfile.birthdate_ts * 1000,
          ).getFullYear(),
          month:
            monthByNumber[
              new Date(this.props.myProfile.birthdate_ts * 1000).getMonth()
            ],
          day: new Date(this.props.myProfile.birthdate_ts * 1000).getDate(),
          selectedActivities: initActivity,
          occupation:
            this.props.myProfile.occupation === null
              ? ''
              : this.props.myProfile.occupation,
        },
        () => {
          if (
            this.state.selectedActivities &&
            this.state.selectedActivities.length
          ) {
            let allActivities = this.state.activities;
            allActivities.forEach((item) => {
              if (this.state.selectedActivities.includes(item.activitie)) {
                item.isSelected = !item.isSelected;
              }
            });
            this.setState({
              activities: allActivities,
            });
          }
        },
      );
    });
  }

  selectedItem = (item, idx) => {
    let activities = this.state.activities;
    activities[idx].isSelected = !activities[idx].isSelected;
    let selectedActivities = this.state.selectedActivities;
    const exist = selectedActivities.filter((it) => it === item.activitie)[0];
    if (exist) {
      selectedActivities.splice(
        selectedActivities.indexOf(
          selectedActivities.filter((it) => it === item.activitie)[0],
        ),
        1,
      );
    } else {
      selectedActivities.push(item.activitie);
    }
    this.setState({
      selectedActivities: selectedActivities,
      activities,
    });
    this.props.myProfileActions.clearErrors();
  };

  goBack() {
    this.props.eventsActions.closeModal(false);
    modalRouteService.removePath();
    if (modalRouteService.currentPath()) {
      this.props.history.replace({pathname: modalRouteService.currentPath()});
    } else {
      if (this.props.location.state && this.props.location.state.initialRoute) {
        this.props.history.replace({
          pathname: this.props.location.state.initialRoute,
        });
      } else {
        this.props.history.replace({pathname: '/'});
      }
      modalRouteService.removeAllStack();
    }
  }

  fileSelectedHandler = (e) => {
    if (e.target.files.length) {
      const imageURI = URL.createObjectURL(e.target.files[0]);
      const image = e.target.files[0];
      this.setState(
        {
          imageURI,
          image,
        },
        () => {
          const photo = {
            uri: this.state.imageURI,
            image: this.state.image,
            albumType: 'Default',
            isDocument: true,
          };
          this.props.uploadActions
            .upload([photo])
            .then(() => {
              this.props.myProfileActions.loadMyProfile();
            })
            .catch((e) => console.log(e));
        },
      );
    }
  };

  slider = () => {
    if (this.props.myProfile.albums && !this.props.myProfile.albums.length) {
      return (
        <>
          <div className="load-photo__wrap">
            <LoadPhotoBtn onClick={() => this.fileInput.click()} />
            <LoadPhotoBtn onClick={() => this.fileInput.click()} />
            <LoadPhotoBtn onClick={() => this.fileInput.click()} />
          </div>

          <input
            type="file"
            id="upload-button"
            style={{display: 'none'}}
            onChange={this.fileSelectedHandler}
            ref={(fileInput) => (this.fileInput = fileInput)}
          />
        </>
      );
    }
    if (this.props.myProfile.albums && this.props.myProfile.albums.length) {
      const {photos} = this.props.myProfile.albums[0];

      try {
        const parsedPhotos = Object.values(photos).reverse();
        return (
          <>
            {photos ? (
              <>
                <Carousel
                  loadPhoto={true}
                  isMyProfile={true}
                  onClick={() => this.fileInput.click()}
                  photos={parsedPhotos}
                />
                <input
                  type="file"
                  id="upload-button"
                  style={{display: 'none'}}
                  onChange={this.fileSelectedHandler}
                  ref={(fileInput) => (this.fileInput = fileInput)}
                />
              </>
            ) : null}
          </>
        );
      } catch (e) {
        return null;
      }
    }
  };

  componentWillUnmount() {
    this.props.myProfileActions.clearErrors();
  }

  hasErrors() {
    return (
      'name' in this.props.myProfile.errors ||
      'birthdate' in this.props.myProfile
    );
  }

  handleOnChangeName = (event) => {
    this.setState({name: event.target.value});
    this.props.myProfileActions.clearErrors();
  };

  handleOnChangeAbout = (event) => {
    this.setState({about: event.target.value});
    this.props.myProfileActions.clearErrors();
  };

  handleOnChangeOccupation = (event) => {
    this.setState({occupation: event.target.value});
    this.props.myProfileActions.clearErrors();
  };

  renderActivities = () => {
    return (
      <div className="form_control mb20">
        <div className="form_label">{__('Your activities')}</div>
        <div className="activities_wrap">
          {this.state.activities.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => this.selectedItem(item, index)}
                className={
                  item.isSelected ? 'activities_item active' : 'activities_item'
                }
                style={{
                  backgroundColor: item.isSelected
                    ? this.props.screen.isMobileMode
                      ? '#A09881'
                      : '#403D34'
                    : this.props.screen.isMobileMode
                    ? '#403D34'
                    : '#000',
                  // color: '#f7b500',
                }}>
                {__(`${item.activitie}`)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderName = () => {
    return (
      <Input
        extraСlass="mb20"
        placeholder={__('Enter your real name')}
        label={__('Your first name')}
        type="text"
        invalid={this.hasErrors() && this.state.name.length < 2}
        error={!this.hasErrors() ? null : this.props.myProfile.errors.name}
        value={this.state.name}
        onChange={(event) => this.handleOnChangeName(event)}
      />
    );
  };

  renderAbout = () => {
    const clsInput = isLoka() ? 'form_input' : 'form_input ts-form_input_area';
    return (
      <div className="form_control mb20">
        <div className="form_label">{__('About you')}</div>
        <TextareaAutosize
          className={clsInput}
          rows={3}
          value={this.state.about}
          onChange={(event) => this.handleOnChangeAbout(event)}
          placeholder={__(
            'To make communication more interesting, tell us about yourself…',
          )}
        />
      </div>
    );
  };

  locationText = () => {
    const setLocation = (location) => {
      this.setState({
        selectLocation: location,
      });
    };
    return (
      <div
        className={isLoka() ? 'form_control' : 'form_control ts-form_control'}
        style={{marginBottom: 0}}>
        <div className="form_label">{__('Location')}</div>
        <Location selectLocation={(location) => setLocation(location)} />
      </div>
    );
  };

  birthDate = () => {
    return (
      <div className="form_control mb20">
        <label className="form_label">{__('Your date of birth')}</label>
        <div className="wrap_controls">
          <DayPicker
            defaultValue={__('Day')}
            year={this.state.year}
            month={numberByMonth[this.state.month]}
            endYearGiven
            required={true}
            value={this.state.day}
            onClick={(day) => {
              this.setState({day});
              this.props.myProfileActions.clearErrors();
            }}
            id={'day'}
            name={'day'}
            classes={
              this.props.myProfile.errors.birthdate &&
              this.props.myProfile.errors.birthdate.length
                ? 'form_input dropdown_value invalid'
                : 'form_input dropdown_value'
            }
            optionClasses={'option dropdown_list_item'}
          />
          <MonthPicker
            defaultValue={__('Month')}
            endYearGiven
            year={this.state.year}
            required={true}
            value={this.state.month}
            onClick={(month) => {
              let mm = __reverse(month);
              this.setState({month: mm});
              this.props.myProfileActions.clearErrors();
            }}
            id={'month'}
            name={'month'}
            classes={
              this.props.myProfile.errors.birthdate &&
              this.props.myProfile.errors.birthdate.length
                ? 'form_input dropdown_value invalid'
                : 'form_input dropdown_value'
            }
            optionClasses={'option dropdown_list_item'}
          />
          <YearPicker
            defaultValue={__('Year')}
            start={new Date(new Date().getFullYear()) - 99}
            end={new Date(new Date().getFullYear()) - 18}
            reverse
            required={true}
            value={this.state.year}
            onClick={(year) => {
              this.setState({year});
              this.props.myProfileActions.clearErrors();
            }}
            id={'year'}
            name={'year'}
            classes={
              this.props.myProfile.errors.birthdate &&
              this.props.myProfile.errors.birthdate.length
                ? 'form_input dropdown_value invalid'
                : 'form_input dropdown_value'
            }
            optionClasses={'option dropdown_list_item'}
          />
        </div>
      </div>
    );
  };

  renderOccupation = () => {
    return (
      <Input
        extraСlass="mb20"
        type="text"
        label={__('Work as')}
        value={this.state.occupation}
        onChange={(event) => this.handleOnChangeOccupation(event)}
        placeholder={__('Enter your occupation')}
      />
    );
  };

  selectLanguage = (lang) => {
    const cLanguages = [...this.state.selectedLanguages];
    const exist = cLanguages.filter((it) => it.name === lang.name)[0];
    if (exist) {
      cLanguages.splice(
        cLanguages.indexOf(cLanguages.filter((it) => it.name === lang.name)[0]),
        1,
      );
    } else {
      if (this.state.selectedLanguages.length > 3) {
        return;
      }
      cLanguages.push(lang);
    }
    this.setState({
      selectedLanguages: cLanguages,
    });
  };

  renderLanguages = () => {
    return (
      <LanguagesField
        languages={languages}
        selectLanguage={(v) => this.selectLanguage(v)}
        selectedLanguages={this.state.selectedLanguages}
      />
    );
  };

  saveProfileChanges = () => {
    const activities = this.state.selectedActivities;
    const {name, day, month, year, occupation, about} = this.state;
    const {country_id, region_id, city_id} = this.state.selectLocation;
    this.props.myProfileActions
      .save({
        activities,
        about,
        name,
        day,
        month: numberByMonth[month] + 1,
        year,
        occupation,
        country_id,
        region_id,
        city_id,
        languages: this.state.selectedLanguages.map((l) => l.id),
      })
      .then(() => {
        console.log('this.state.name', this.state.name);
        console.log('this.props.myProfile', this.props.myProfile.isFetching);
        console.log('this.props.myProfile', this.props.myProfile.errors);
        if (
          !(this.state.name && this.state.name.length > 2) ||
          this.props.myProfile.isFetching ||
          (this.props.myProfile.errors.birthdate &&
            this.props.myProfile.errors.birthdate.length)
        ) {
        } else {
          this.goBack();
        }
      })
      .catch(() => null);
  };

  render() {
    const clsBody = isLoka() ? 'popup_body' : 'popup_body ts-edit-profile';
    return (
      <div className={clsBody}>
        <div className="popup_head">
          <div className="profile_info">
            <div className="profile_title">{__('Edit profile')}</div>
          </div>
          <div className="btn_close mobile-mt-n3" onClick={this.goBack}>
            {isLoka() ? <IconClose /> : <IconWhiteClose />}
          </div>
        </div>
        <div className="popup_content scroll mobile-scroll">
          {!this.props.screen.isMobileMode ? (
            <div className="profile_gallery_wrap">{this.slider()}</div>
          ) : null}
          <div className="edit-container">
            {this.renderActivities()}
            {this.renderName()}
            {this.renderAbout()}
            {this.renderOccupation()}
            {this.locationText()}
            {this.birthDate()}
            {this.renderLanguages()}
          </div>
        </div>
        <div className="popup_footer edit-profile-footer ">
          <Button
            onClick={this.saveProfileChanges}
            disabled={
              !(this.state.name && this.state.name.length > 2) ||
              this.props.myProfile.isFetching ||
              (this.props.myProfile.errors.birthdate &&
                this.props.myProfile.errors.birthdate.length)
            }>
            {this.props.myProfile.isFetching ? __('Loading...') : __('Save')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      myProfile: state.myProfile,
      upload: state.upload,
      screen: state.screen,
    }),
    (dispatch) => ({
      myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
      uploadActions: bindActionCreators(bindUploadActions, dispatch),
    }),
  )(EditProfile),
);
