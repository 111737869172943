import { isMobile, isIOS } from 'react-device-detect';
const INNER_HEIGHT = window.innerHeight;

export function chatKeyboardOpenListener() {
    if (window.innerWidth < 1020 && isMobile && isIOS) {
        document.body.addEventListener("focus", event => {
          const target = event.target;
          if (target.className === 'form_textarea_message') {
            const header = document.getElementById('textarea_message');
            if (header) {
            header.classList.add("keyboard");
            setTimeout(() => {
                let top = INNER_HEIGHT - window.innerHeight;
                header.style.top = `${top}px`;
            }, 100);
            }
          }
        }, true);
        document.body.addEventListener("blur", () => {
            const header = document.getElementById('textarea_message');
            if (header) {
              header.classList.remove("keyboard");
              header.style.top = '0px';
            }
        }, true);
    }
}