import React from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as IconStart} from '../Icons/Star.svg';
import {ReactComponent as TsIconStart} from '../Icons/theystream/Star.svg';
import {ReactComponent as TsIconMessage} from '../Icons/theystream/Message.svg';
import {ReactComponent as IconClose} from '../Icons/Close.svg';
import {ReactComponent as MessagesActive} from '../Icons/MessagesActive.svg';
import {isLoka, isTheyStream} from '../../apps-config';

export default ({
  user,
  handleAddToFavorite,
  handleRemoveFromFavorite,
  fromFavorites,
  isLive,
  stream_id,
  item,
}) => {
  const avatarUri = () => {
    const {photo_path, photo_id, photo_extension} = user.main_photo;
    return `${photo_path}/${photo_id}_huge.${photo_extension}`;
  };

  const photoSource =
    user && user.main_photo && user.main_photo.has_main_photo
      ? {uri: avatarUri()}
      : null;

  const renderName = () => {
    const {name, age} = user;

    return (
      <div className="card_title">
        {name}, {age}
        {/*{props.isOnline ? (*/}
        {/*    <span className="card_status _online"></span>*/}
        {/*) : null}*/}
      </div>
    );
  };

  const renderAbout = () => {
    const {about} = user;
    if (!about) {
      return null;
    }
    return (
      <p className="card_message user_description">
        <span>{about}</span>
      </p>
    );
  };

  const locationText = () => {
    const {location_city_text, location_country_text} = user;
    if (location_city_text && location_country_text) {
      return (
        <span>
          {location_city_text}, <br /> {location_country_text}
        </span>
      );
    }
    if (location_city_text) {
      return location_city_text;
    }
    if (location_country_text) {
      return location_country_text;
    }
  };

  const renderLocation = () => {
    return <div className="people_card_location">{locationText()}</div>;
  };

  const clsFavorites = ['btn_round', 'btn_favorites'];

  if (user.is_favorite || fromFavorites) {
    clsFavorites.push('active');
  }

  return (
    <>
      <div className="card_img">
        <Link
          className="card_link"
          to={{
            pathname: isLive ? `modal/live` : `/${user.id}`, // todo: modal/live - что это?
            state: {modal: true, stream_id: stream_id, user: user, item: item},
          }}></Link>
        {isTheyStream() && isLive ? (
          <div className="live_text">Live</div>
        ) : null}
        {photoSource ? (
          <img
            alt=""
            src={photoSource ? photoSource.uri : '/img/no-photo.svg'}
            style={
              user.main_photo.width > user.main_photo.height
                ? {width: 'auto', height: '100%'}
                : {width: '100%', height: 'auto'}
            }
          />
        ) : (
          <img
            alt=""
            src={'/img/no-photo.svg'}
            style={{
              width: 'auto',
              height: '90%',
              top: 'auto',
              transform: 'translateX(-50%)',
            }}
          />
        )}
        <div className="card_wrap">
          {isLoka() ? (
            <div className="card_location">{locationText()}</div>
          ) : null}
        </div>
        <div className="card_actions">
          {fromFavorites ? (
            <div
              className="btn_round btn_favorites active"
              onClick={handleRemoveFromFavorite}>
              {isTheyStream() ? <TsIconStart /> : <IconStart />}
              <div className="remove_favorites">
                <IconClose />
              </div>
            </div>
          ) : null}
          {user.is_favorite || fromFavorites ? null : (
            <div
              className="btn_round btn_favorites"
              onClick={handleAddToFavorite}>
              {isTheyStream() ? <TsIconStart /> : <IconStart />}
            </div>
          )}
          <Link
            to={{
              pathname: '/chat',
              state: {modal: true, user: user}
            }}
            className="btn_round btn_chat">
            {isTheyStream() ? <TsIconMessage /> : <MessagesActive />}
          </Link>
        </div>
      </div>
      <div className="card_content">
        <Link
          className="card_link"
          to={{
            pathname: `/${user.id}`,
            state: {modal: true},
          }}></Link>
        {renderName()}
        {isLoka() && renderAbout()}
        {isTheyStream() && renderLocation()}
      </div>
    </>
  );
};
