import React, {Component} from 'react';
import {ReactComponent as AuthLogo} from '../../Icons/AuthLogo.svg';
import {ReactComponent as TsLogo} from '../../Icons/theystream/Logo.svg';
import {ReactComponent as IconBack} from '../../Icons/Back.svg';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import __ from '../../../utils/translate';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import {isLoka} from '../../../apps-config';

class AuthMenu extends Component {
  skip = () => {
    if (this.props.location.pathname === '/photo') {
      this.props.history.push('/activities-occupation/activity');
      this.props.registrationActions.setCurrentStep('4');
    }
    if (this.props.location.pathname === '/activities-occupation/activity') {
      this.props.history.push('/activities-occupation/occupation');
    }
    if (this.props.location.pathname === '/activities-occupation/occupation') {
      this.props.registrationActions.done();
      this.props.history.push('/');
    }
  };

  render() {
    const clsHeader = ['header', 'header__sign'];
    const isMobileMode = this.props.screen.isMobileMode;
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsHeader.push('blur');
    }
    const showSkipBtn =
      isMobileMode &&
      (this.props.location.pathname === '/photo' ||
        this.props.location.pathname.indexOf('/activities-occupation') > -1);
    const clsBtnIn = isLoka() ? 'btn_in' : 'btn_in ts_btn_in';
    return (
      <>
        <header className={clsHeader.join(' ')}>
          <div className="container">
            {isMobileMode && this.props.isRegistrationPages ? (
              <div className="back_btn" onClick={this.props.history.goBack}>
                <IconBack />
              </div>
            ) : (
              <Link to="/">
                {isLoka() ? (
                  <AuthLogo />
                ) : (
                  <TsLogo style={{height: 48, width: 106}} />
                )}
              </Link>
            )}
            {showSkipBtn ? (
              <div
                onClick={this.skip}
                style={{marginBottom: 0}}
                className="step_skip btn_link">
                {__('Skip')}
              </div>
            ) : null}
            {(!this.props.session.isLogged &&
              this.props.history.location.pathname === '/') ||
            (!this.props.session.isLogged &&
              this.props.registration.currentStep !== '2') ? (
              <Link
                to={{
                  pathname: '/login',
                  state: {modal: true},
                }}
                className={clsBtnIn}>
                {__('Sign in')}
              </Link>
            ) : null}
          </div>
        </header>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      events: state.events,
      session: state.session,
      registration: state.registration,
      screen: state.screen,
    }),
    (dispatch) => ({
      registrationActions: bindActionCreators(
        bindRegistrationActions,
        dispatch,
      ),
    }),
  )(AuthMenu),
);
