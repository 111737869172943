import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// import {ReactComponent as IconSearchFilter} from '../../Components/Icons/SearchFilter.svg';
import PeopleCard from '../../Components/PeopleCard/peopleCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import __ from '../../utils/translate';

import * as bindPeopleActions from '../../store/actions/peopleActions';
import * as bindProfileActions from '../../store/actions/profileActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindLiveChatListActions from '../../store/actions/liveChatListActions';
import * as bindPeopleSearchActions from '../../store/actions/peopleSearchActions';
import * as bindFavoritesActions from '../../store/actions/favoritesActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import * as bindPurchaseActions from '../../store/actions/purchaseActions';
import {isLoka, isTheyStream} from '../../apps-config';
import {CONTACTS_FOLDER_TYPE_ALL} from '../../store/constants';
import SwitchFolderButtons from '../../Components/Messages/Contacts/SwitchFolderButtons';

class Broadcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isFavoriteProfile: null,
      redirect: false,
      activeFolder: CONTACTS_FOLDER_TYPE_ALL,
    };
  }

  componentDidMount() {
    this.initialLoad();
    if (!this.props.myProfile.name) {
      this.props.myProfileActions.loadMyProfile();
    }
  }

  initialLoad = () => {
    this.props.liveChatListActions.listStream();
    this.props.liveChatListActions.listFavoritesStream();
  };

  handleAddToFavorite(item) {
    this.props.liveChatListActions.addToFavorites(item);
  }

  handleRemoveFromFavorite(item) {
    this.props.liveChatListActions.removeFromFavorites(item);
  }

  switchFolder(activeFolder) {
    this.setState({activeFolder});
    this.initialLoad();
  }

  renderUsers(items) {
    const {activeFolder} = this.state;
    const clsCard = isTheyStream() ? 'ts-card' : 'card';
    const fromFavorites = activeFolder !== CONTACTS_FOLDER_TYPE_ALL;
    return items.map((item) => {
      return (
        <div className={clsCard} key={item.id}>
          <PeopleCard
            isLive={true}
            stream_id={item.stream_id}
            user={item.user}
            item={item}
            fromFavorites={fromFavorites}
            handleAddToFavorite={() => {
              this.handleAddToFavorite(item);
            }}
            handleRemoveFromFavorite={() => {
              this.handleRemoveFromFavorite(item);
            }}
          />
        </div>
      );
    });
  }

  render() {
    const clsMain = ['page_people'];
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsMain.push('blur');
    }
    const {activeFolder} = this.state;
    const items =
      activeFolder === CONTACTS_FOLDER_TYPE_ALL
        ? this.props.liveChatList.items
        : this.props.liveChatList.favoriteItems;

    return (
      <>
        <main className={clsMain.join(' ')} id="main">
          <div className="container">
            {this.props.screen.isMobileMode && isLoka() ? (
              <p className="title-text">{__('Broadcast')}</p>
            ) : null}
            <SwitchFolderButtons
              activeFolder={activeFolder}
              switchFolder={this.switchFolder.bind(this)}
              showAvatar={isTheyStream() && this.props.screen.isMobileMode}
              main_photo={this.props.myProfile.main_photo}
            />
            {!items.length ? (
              <div className="wrapper">
                <p className="add-text_dflt">
                  {__(
                    'Add interesting users to Favorites to stay tuned for their updates',
                  )}
                </p>
              </div>
            ) : (
              <InfiniteScroll
                dataLength={items.length}
                hasMore={false}
                loader={__('Loading...')}>
                <div className="wrapper_cards">{this.renderUsers(items)}</div>
              </InfiniteScroll>
            )}
          </div>
        </main>
      </>
    );
  }
}

export default connect(
  (state) => ({
    people: state.people,
    peopleSearch: state.peopleSearch,
    events: state.events,
    profile: state.profile,
    screen: state.screen,
    liveChatList: state.liveChatList,
    myProfile: state.myProfile,
  }),
  (dispatch) => ({
    peopleActions: bindActionCreators(bindPeopleActions, dispatch),
    profileActions: bindActionCreators(bindProfileActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
    liveChatListActions: bindActionCreators(bindLiveChatListActions, dispatch),
    peopleSearchActions: bindActionCreators(bindPeopleSearchActions, dispatch),
    favoritesActions: bindActionCreators(bindFavoritesActions, dispatch),
    eventsActions: bindActionCreators(bindEventsActions, dispatch),
    purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
  }),
)(Broadcast);
