import axios from 'axios';

// import {Navigation} from 'react-native-navigation';
// import Toasts from '../components/Toasts';

import {
  FAVORITES_REQUEST,
  FAVORITES_SUCCESS,
  FAVORITES_FAIL,
  FAVORITES_LOAD_MORE_REQUEST,
  FAVORITES_LOAD_MORE_SUCCESS,
  FAVORITES_LOAD_MORE_FAIL,
  FAVORITES_ADD_SUCCESS,
} from '../constants';

export function load(params) {
  return (dispatch) => {
    dispatch({
      type: FAVORITES_REQUEST,
    });

    return axios
      .get('/users/favorites', {params})
      .then((result) => {
        dispatch({
          type: FAVORITES_SUCCESS,
          payload: result.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FAVORITES_FAIL,
        });
      });
  };
}

export function loadMore(params) {
  return (dispatch) => {
    dispatch({
      type: FAVORITES_LOAD_MORE_REQUEST,
    });

    return axios
      .get('/users/favorites', {params})
      .then((result) => {
        dispatch({
          type: FAVORITES_LOAD_MORE_SUCCESS,
          payload: result.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FAVORITES_LOAD_MORE_FAIL,
        });
      });
  };
}

export function toggle(user) {
  return (dispatch) => {
    let moveTo = 'Favorite';
    let oldFolderType = 'Income';

    if (user.is_favorite) {
      moveTo = 'Income';
      oldFolderType = 'Favorite';
      user.is_favorite = false;
      user.folder_type = 'Income';
    } else {
      user.is_favorite = true;
      user.folder_type = 'Favorite';
    }

    const body = {
      user_id: user.user_id,
      folder_type: moveTo,
      old_folder_type: oldFolderType,
    };

    return axios
      .post('/contacts/move', body)
      .then((response) => {
        showNotification();
        dispatch({
          type: FAVORITES_ADD_SUCCESS,
          payload: {user},
        });
      })
      .catch((err) => {
      });
  };
}

export const showNotification = () => {
  /*const toasts = new Toasts();
  toasts.callToast('test');*/
  // Navigation.showOverlay({
  //   component: {
  //     name: 'Toast',
  //   },
  // });
};
