import React, {Component} from 'react';
import MainMenu from '../../Components/Navigation/MainMenu';
import Footer from '../../Components/Footer';

import './style.css';
import {ModalContainer} from 'react-router-modal';
import {connect} from 'react-redux';
import BottomMainMenu from '../../Components/Navigation/BottomMainMenu';

class MainLayout extends Component {
  render() {
    return (
      <div className="layout">
        <div>
          {!this.props.screen.isMobileMode && <MainMenu />}
          {this.props.children}
          <ModalContainer />
          {this.props.screen.isMobileMode && <BottomMainMenu />}
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    screen: state.screen
  })
)(MainLayout);
