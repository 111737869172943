import React from 'react';
import './styles.scss'

const ActivitieIndicator = () => (
  <div className="loading-dots">
    <div className="loading-dots--dot"></div>
    <div className="loading-dots--dot"></div>
    <div className="loading-dots--dot"></div>
  </div>
)

export default ActivitieIndicator
