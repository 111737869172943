import __ from '../../../utils/translate';
import React from 'react';
import { genders } from '../../../config/searchConfig';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchParams } from '../../../store/actions/peopleSearchActions';


const SearchSettingsGender = ({showAllSettings}) => {
  const {gender} = useSelector(state => state.peopleSearch)
  const dispatch = useDispatch()

  const handleChange = (item) => {
    dispatch(setSearchParams({gender: item.id}));
    showAllSettings()
  };

  const resetGender = () => {
    dispatch(setSearchParams({gender: null}));
    showAllSettings()
  }

  return (
    <div className="form_control mb20">
      <div className="form_label form_label_wrapper">
        <span>{__('Gender')}</span>
        {gender && gender.length
          ? <button className="btn_link btn_filter" onClick={() => resetGender()}>{__('Reset gender')}</button>
          : null
        }
      </div>
      <div className="gender_controls">
        {genders.map(item => (
          <p className="input_radio" key={item.id}>
            <label>
              <input
                className="with-gap"
                name="gender"
                type="radio"
                value={item.id}
                checked={gender === item.id}
                onChange={() => handleChange(item)}
              />
              <span>{__(`${item.name}`)}</span>
            </label>
          </p>
        ))}
      </div>
    </div>
  );
};

export default SearchSettingsGender;