import {
  CONTACTS_REQUEST,
  CONTACTS_SUCCESS,
  CONTACTS_FAIL,
  CONTACTS_LOAD_MORE_REQUEST,
  CONTACTS_LOAD_MORE_SUCCESS,
  CONTACTS_LOAD_MORE_FAIL,
  CONTACTS_FAVORITES_REQUEST,
  CONTACTS_FAVORITES_SUCCESS,
  CONTACTS_FAVORITES_FAIL,
  CONTACTS_FAVORITES_LOAD_MORE_REQUEST,
  CONTACTS_FAVORITES_LOAD_MORE_SUCCESS,
  CONTACTS_FAVORITES_LOAD_MORE_FAIL,
  // CONTACT_BLOCK_SUCCESS,
  // CONTACT_DELETE_SUCCESS,
  // CONTACT_FAVORITE_SUCCESS,
  CONTACTS_FOLDER_TYPE_ALL,
  CONTACTS_FOLDER_SWITCH,
  CONTACTS_MARK_CHAT_AS_READ,
} from '../constants';

const initialState = {
  all: {
    items: [],
    isFetching: false,
    hasMore: false,
    page: 1,
    isFetchingMore: false,
  },
  favorites: {
    items: [],
    isFetching: false,
    hasMore: false,
    page: 1,
    isFetchingMore: false,
  },
  activeFolder: CONTACTS_FOLDER_TYPE_ALL,
  activeChat: null,
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACTS_MARK_CHAT_AS_READ:
      const newAllItems = state.all.items.map((item) => {
        return item.contact_id !== action.payload.contact_id || action.payload.is_last_blur
          ? item
          : {
            ...item,
            new_messages_count: 0,
          };
      });
      const newFavoritesItems = state.favorites.items.map((item) => {
        return item.contact_id !== action.payload.contact_id
          ? item
          : {
            ...item,
            new_messages_count: 0,
          };
      });
      return {
        ...state,
        all: {
          ...state.all,
          items: newAllItems,
        },
        favorites: {
          ...state.favorites,
          items: newFavoritesItems,
        },
      };
    case CONTACTS_FOLDER_SWITCH:
      return {
        ...state,
        activeFolder: action.payload,
      };
    case CONTACTS_REQUEST:
      return {
        ...state,
        all: {
          ...state.all,
          isFetching: true,
        },
      };
    case CONTACTS_FAVORITES_REQUEST:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isFetching: true,
        },
      };
    case CONTACTS_SUCCESS:
      return {
        ...state,
        all: {
          ...state.all,
          isFetching: false,
          items: action.payload.contacts,
          hasMore: action.payload.is_more_contacts,
          page: 2,
        },
      };
    case CONTACTS_FAVORITES_SUCCESS:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isFetching: false,
          items: action.payload.contacts,
          hasMore: action.payload.is_more_contacts,
          page: 2,
        },
      };
    case CONTACTS_FAIL:
      return {
        ...state,
        all: {
          ...state.all,
          isFetching: false,
        },
      };
    case CONTACTS_FAVORITES_FAIL:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isFetching: false,
        },
      };
    case CONTACTS_LOAD_MORE_REQUEST:
      return {
        ...state,
        all: {
          ...state.all,
          isFetchingMore: true
        },
      };
    case CONTACTS_FAVORITES_LOAD_MORE_REQUEST:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isFetchingMore: true,
        },
      };
    case CONTACTS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        all: {
          ...state.all,
          items: [...state.all.items, ...action.payload.contacts],
          hasMore: action.payload.is_more_contacts,
          isFetchingMore: false,
          page: state.all.page + 1,
        },
      };
    case CONTACTS_FAVORITES_LOAD_MORE_SUCCESS:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          items: [...state.favorites.items, ...action.payload.contacts],
          hasMore: action.payload.is_more_contacts,
          isFetchingMore: false,
          page: state.favorites.page + 1,
        },
      };
    case CONTACTS_LOAD_MORE_FAIL:
      return {
        ...state,
        all: {
          ...state.all,
          isFetchingMore: false,
          page: state.page - 1,
        },
      };
    case CONTACTS_FAVORITES_LOAD_MORE_FAIL:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isFetchingMore: false,
          page: state.page - 1,
        },
      };
    default:
      return state;
  }
};

export default contactsReducer;
