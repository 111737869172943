import React, {Component} from 'react';
import MainLayout from './hoc/MainLayout';
import AuthLayout from './hoc/AuthLayout';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import Auth from './Containers/Auth';
import Modal from './Containers/Modal';
import Messages from './Containers/Messages';
import Favorites from './Containers/Favorites';
import People from './Containers/People';
import SetPersonalInfo from './Containers/Registration/SetPersonalInfo';
import SetPhoto from './Containers/Registration/SetPhoto';
import SetActivitiesOccupation from './Containers/Registration/SetActivitiesOccupation';
import Policy from './Containers/Policy';
import Terms from './Containers/Terms';
import Contacts from './Containers/Contacts';
import {connect} from 'react-redux';
import * as bindAuthActions from './store/actions/auth/authActions';
import * as bindSessionActions from './store/actions/sessionActions';
import * as bindLocationByIpActions from './store/actions/auth/locationByIpActions';
import * as bindRegistrationActions from './store/actions/auth/registrationActions';
import {bindActionCreators} from 'redux';
import * as bindScreenActions from './store/actions/screenActions';
import * as bindPurchaseActions from './store/actions/purchaseActions';
import * as bindEventsActions from './store/actions/eventsActions';
import * as bindMyProfileActions from './store/actions/myProfileActions';
import ServerError from './Components/ServerError';
import {chatKeyboardOpenListener} from './utils/chatKeyboardOpenListener';
import AutoLogin from './Containers/Auth/AutoLogin';
import Broadcast from './Containers/Broadcast';
import SetPersonalInfoSocial from './Containers/Registration/SetPersonalInfoSocial';
import {getToken, onMessageListener} from './firebase';
import {messaging} from './firebase';
import {GetFreeCoins} from './Components/GetFreeCoins';
import {SmartBanner} from './Components/SmartBanner';
import {isMobile} from 'react-device-detect';
import {isTheyStream} from './apps-config';
import {deviceLocale} from './i18n';
import DailyCoins from './Components/DailyCoins';

class App extends Component {
    params = new URLSearchParams(this.props.location.search);
    statusAddCoins = this.params.get('status');
    orderId = this.params.get('token');
    hash = this.params.get('hash');
    typeModal = this.params.get('type');

    constructor(props) {
        super(props);
        this.previousLocation = this.props.location;
        this.state = {
            notification: null,
            showNotification: false,
        };
    }

    componentWillUpdate() {
        const {location} = this.props;
        if (!(location.state && location.state.modal)) {
            this.previousLocation = this.props.location;
        }
    }

    componentDidMount() {
        if (this.typeModal === 'restore-password') {
            this.props.history.push({
                pathname: '/restore-password',
                state: {modal: true, hash: this.hash},
            });
        }
        if (messaging) {
            getToken();
            onMessageListener().then(payload => {
            }).catch(() => {
            });
        }
        // change language
        const locale = deviceLocale;
        if (
            this.props.credentials.user_id &&
            this.props.myProfile &&
            this.props.myProfile.language &&
            locale !== this.props.myProfile.language
        ) {
            this.props.myProfileActions.save({lang: locale});
        }
        const {sessionActions, session, credentials, registration} = this.props;
        sessionActions.getCurrentLanguage();
        if (session.isLogged && credentials.user_id && !registration.inProgress) {
            this.props.purchaseActions.loadProducts();
            this.props.myProfileActions.loadMyProfile();
            this.props.eventsActions.load();
            // обновляем счетчики
            this.props.sessionActions.counters();
            // показываем смарт баннер
            if (!isTheyStream()) {
                this.props.sessionActions.setShowSmartBanner(
                    localStorage.getItem('hideSmartBanner')
                        ? (+Date.now() - +localStorage.getItem('hideSmartBanner')) /
                        3600000 >
                        0
                            ? true
                            : false
                        : true,
                );
            }
        }
        this.props.screenActions.screenSizeChangeListener();
        chatKeyboardOpenListener();
    }

    render() {
        const {location} = this.props;
        const isModal =
            location.state &&
            location.state.modal &&
            this.previousLocation !== location;
        const {session, credentials, registration} = this.props;
        // test

        if (
            location.pathname === '/lalh' ||
            location.pathname === '/calh' ||
            location.pathname === '/ualh'
        ) {
            return <AutoLogin/>;
        }

        let routes = (
            <>
                <Switch location={isModal ? this.previousLocation : location}>
                    <Route path="/policy" exact component={Policy}/>
                    <Route path="/terms" exact component={Terms}/>
                    <Route path="/contacts" exact component={Contacts}/>
                    <Route path="/personal-info" exact component={SetPersonalInfo}/>
                    <Route path="/" exact component={Auth}/>
                    <Route exact path="/:id" component={Modal}/>
                    <Redirect to="/"/>
                </Switch>
                {isModal ? (
                    <Route exact path="/:id">
                        <Modal isModal/>
                    </Route>
                ) : null}
            </>
        );

        if (session.isLogged && credentials.user_id && registration.inProgress) {
            routes = (
                <Switch>
                    <Route
                        path="/personal-info-social"
                        exact
                        component={SetPersonalInfoSocial}
                    />
                    <Route path="/photo" exact component={SetPhoto}/>
                    <Route
                        path='/activities-occupation/:step'
                        exact
                        component={SetActivitiesOccupation}
                    />
                    {registration.currentStep === '4' ? (
                        <Redirect to='/set-email-social'/>
                    ) : (
                        registration.currentStep === '3' ? (
                            <Redirect to='/photo'/>
                        ) : (
                            registration.currentStep === '0'
                                ? <Redirect to='/personal-info-social'/>
                                : <Redirect to='/activities-occupation/:step'/>
                        )
                    )}
                </Switch>
            );
        }

        if (session.isLogged && credentials.user_id && !registration.inProgress) {
            routes = (
                <>
                    <Switch location={isModal ? this.previousLocation : location}>
                        <Route exact path="/messages" component={Messages}/>
                        <Route exact path="/favorites" component={Favorites}/>
                        <Route path="/policy" exact component={Policy}/>
                        <Route path="/terms" exact component={Terms}/>
                        <Route path="/contacts" exact component={Contacts}/>
                        <Route exact path="/" component={People}/>
                        <Route exact path="/broadcast" component={Broadcast}/>
                        <Route exact path="/:id" component={Modal}/>
                        <Redirect to="/"/>
                    </Switch>
                    {isModal ? (
                        <Switch>
                            <Route exact path="/:id">
                                <Modal isModal/>
                            </Route>
                        </Switch>
                    ) : null}
                </>
            );
        }

        const isRegistrationPages =
            location.pathname.indexOf('/activities-occupation') > -1 ||
            location.pathname === '/personal-info' ||
            location.pathname === '/personal-info-social' ||
            location.pathname === '/photo';

        return (
            <>
                {(!session.isLogged &&
                    !credentials.user_id &&
                    !registration.inProgress) ||
                (!session.isLogged &&
                    !credentials.user_id &&
                    registration.inProgress) ? (
                    <AuthLayout isRegistrationPages={isRegistrationPages}>
                        {routes}
                    </AuthLayout>
                ) : null}
                {session.isLogged && credentials.user_id && registration.inProgress ? (
                    <AuthLayout isRegistrationPages={isRegistrationPages}>
                        {routes}
                    </AuthLayout>
                ) : null}
                {session.isLogged && credentials.user_id && !registration.inProgress ? (
                    <>
                        {session.need_mobile_link || credentials.need_mobile_link ? <GetFreeCoins/> : null}
                        {this.props.screen.isMobileMode && isMobile && (
                            <>
                                {(!session.need_mobile_link && session.showSmartBanner) || (!credentials.need_mobile_link && session.showSmartBanner) ?
                                    <SmartBanner/> : null}
                            </>
                        )}
                        <MainLayout>{routes}</MainLayout>
                    </>
                ) : null}
                <ServerError/>
                <DailyCoins/>
            </>
        );
    }
}

export default withRouter(
    connect(
        (state) => ({
            session: state.session,
            purchase: state.purchase,
            credentials: state.credentials,
            registration: state.registration,
            //marketing: state.marketing,
            myProfile: state.myProfile,
            events: state.events,
            screen: state.screen,
            //notifications: state.notifications,
        }),
        (dispatch) => ({
            authActions: bindActionCreators(bindAuthActions, dispatch),
            sessionActions: bindActionCreators(bindSessionActions, dispatch),
            screenActions: bindActionCreators(bindScreenActions, dispatch),
            locationByIpActions: bindActionCreators(
                bindLocationByIpActions,
                dispatch,
            ),
            eventsActions: bindActionCreators(bindEventsActions, dispatch),
            myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
            purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
            registrationActions: bindActionCreators(
                bindRegistrationActions,
                dispatch,
            ),
        }),
    )(App),
);
