import React, {Fragment} from 'react';
import i18n, {json} from '../i18n';

function __reverse(template) {
  let string = '';
  let keys = Object.keys(json);
  console.warn('keys: ', template[0]);
  for (let i = 0; i < keys.length; i++) {
    if (json[keys[i]] === template[0]) {
      string = keys[i];
      break;
    }
  }
  return string;
}

export default __reverse;
