import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import * as bindUploadActions from '../../store/actions/uploadActions';
import * as bindPeopleSearchActions from '../../store/actions/peopleSearchActions';
import * as bindLogoutActions from '../../store/actions/auth/logoutActions';
import * as bindPeopleActions from '../../store/actions/peopleActions';
import __ from '../../utils/translate';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {ReactComponent as IconArrowBack} from '../../Components/Icons/ArrowBack.svg';
import {ReactComponent as IconArrowBackWhite} from '../../Components/Icons/theystream/ArrowBack.svg';
import {ReactComponent as IconArrowLeft} from '../../Components/Icons/ArrowLeft.svg';
import {ReactComponent as ThreeCoins} from '../../Components/Icons/ThreeCoins.svg';
import {ReactComponent as UnlimContact} from '../../Components/Icons/UnlimContact.svg';
import {ReactComponent as TwoCoins} from '../../Components/Icons/TwoCoins.svg';
import {ConfirmDelete} from '../../Components/UI/ConfirmDelete';
import {Button} from '../../Components/UI';
import dateFilter from '../../utils/dateFormat';
import {isLoka, isTheyStream} from '../../apps-config';
import {ReactComponent as IconWhiteClose} from '../../Components/Icons/WhiteClose.svg';
import modalRouteService from '../../services/ModalRouteService';

class MyProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      typeSettings: '',
      showConfirm: false,
      showConfirmLogout: false,
    };
  }

  componentDidMount() {
    modalRouteService.addPath(this.props.match.url);
  }

  goBack() {
    this.props.eventsActions.closeModal(false);
    modalRouteService.removePath();
    if (modalRouteService.currentPath()) {
      this.props.history.replace({pathname: modalRouteService.currentPath()});
    } else {
      if (this.props.location.state && this.props.location.state.initialRoute) {
        this.props.history.replace({
          pathname: this.props.location.state.initialRoute,
        });
      } else {
        this.props.history.replace({pathname: '/'});
      }
      modalRouteService.removeAllStack();
    }
  }

  confirmDeleteProfile = (value) => {
    this.setState({
      showConfirm: false,
    });
    if (value) {
      this.props.eventsActions.closeModal(false);
      this.props.history.go(-2);
      this.props.myProfileActions.deleteAccount();
    }
  };

  confirmLogout = (value) => {
    this.setState({
      showConfirmLogout: false,
    });
    if (value) {
      // Без этого блюр иногда не уходит, хз
      setTimeout(() => this.props.eventsActions.closeModal(false), 500);
      this.props.history.go(-2);
      this.props.logoutActions.logout();
    }
  };

  getTitleSettings = (type) => {
    switch (type) {
      case 'Your subscription':
        return 'Your subscription';
      default:
        return 'Profile settings';
    }
  };

  goProfileSettings = () => {
    this.setState({
      typeSettings: '',
    });
  };

  getPremium = () => {
    this.props.history.push({
      pathname: '/get-premium',
      state: { modal: true },
    });
  };

  handleCancelSubscription = () => {
    this.props.myProfileActions.cancelSubscription();
  };

  render() {
    const clsBody = isTheyStream()
      ? 'popup_body ts-my-profile-popup_body'
      : 'popup_body';
    const {is_vip} = this.props.myProfile;
    return (
      <div className={clsBody}>
        <div className="popup_head my-profile-settings__popup_head">
          <div
            className="btn_back"
            onClick={
              !this.state.typeSettings.length
                ? this.goBack
                : this.goProfileSettings
            }>
            {isLoka() ? <IconArrowBack /> : <IconArrowBackWhite />}
          </div>
          <div
            className="btn_close mobile-mt-n3"
            onClick={
              !this.state.typeSettings.length
                ? this.goBack
                : this.goProfileSettings
            }>
            {isLoka() ? <IconClose /> : <IconWhiteClose />}
          </div>
        </div>
        <div className="popup_content my-profile-settings__content mobile-scroll scroll">
          <div className="profile_title">
            {__(`${this.getTitleSettings(this.state.typeSettings)}`)}
          </div>
          {this.state.typeSettings === 'Your subscription' && (
            <div className="settings-subscription">
              <div className="settings-subscription__title">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none">
                    <g id="Crown">
                      <path
                        id="Path"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.8097 3.21943C17.6693 3.11693 17.4907 3.13093 17.362 3.25359L13.1632 7.26443L9.32891 0.213956C9.25639 0.0806537 9.13274 0 8.99978 0C8.86729 0 8.74363 0.0806537 8.67111 0.213956L4.83681 7.26443L0.638053 3.25359C0.509282 3.13093 0.330769 3.11693 0.190377 3.21943C0.0495193 3.32192 -0.0234662 3.51852 0.00675072 3.71287L1.68216 14.4628C1.7161 15.2643 2.49709 15.9039 4.00608 16.3649C5.34492 16.7743 7.11843 17 8.99978 17C10.8816 17 12.6551 16.7743 13.9939 16.3649C15.5029 15.9039 16.2839 15.2643 16.3179 14.4628L17.9933 3.71287C18.0235 3.51852 17.95 3.32192 17.8097 3.21943Z"
                        fill="#F7B500"
                      />
                      <path
                        id="Path_2"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.4094 15.4473C12.236 15.8037 10.6702 16 8.99979 16C7.32979 16 5.76398 15.8037 4.59058 15.4473C3.29207 15.0527 3 14.6207 3 14.5C3 14.3799 3.29207 13.9473 4.59058 13.5527C5.76398 13.1963 7.32979 13 8.99979 13C10.6702 13 12.236 13.1963 13.4094 13.5527C14.7079 13.9473 15 14.3799 15 14.5C15 14.6207 14.7079 15.0527 13.4094 15.4473Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </span>
                <span>{__('You are a Premium user')}</span>
              </div>
              <p className="settings-subscription__text">
                {__('As a Premium user, you have the following benefits:')}
              </p>
              <div className="settings-subscription__list">
                <div className="settings-subscription__item">
                  <div className="settings-subscription__icon">
                    <UnlimContact />
                  </div>
                  <div className="settings-subscription__content">
                    {__('Unlimited new contacts per day')}
                  </div>
                </div>
                <div className="settings-subscription__item">
                  <div className="settings-subscription__icon">
                    <ThreeCoins />
                  </div>
                  <div className="settings-subscription__content">
                    {__('50 free Coins every week')}
                  </div>
                </div>
                <div className="settings-subscription__item">
                  <div className="settings-subscription__icon">
                    <TwoCoins />
                  </div>
                  <div className="settings-subscription__content">
                    {__('5 free Сoins every day you come')}
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.typeSettings === '' && (
            <div className="collection">
              {/*<div className="collection_title">{__('Notifications')}</div>*/}
              {/*<div className="collection_row">*/}
              {/*  <div className="collection_col collection_col_left">*/}
              {/*    <span className="collection_text">{__('E-mail')}</span>*/}
              {/*  </div>*/}
              {/*  <span className="collection_col collection_col_arrow">*/}
              {/*    <IconArrowLeft/>*/}
              {/*  </span>*/}
              {/*</div>*/}
              {/*<div className="collection_row">*/}
              {/*  <div className="collection_col collection_col_left">*/}
              {/*    <span className="collection_text">{__('Mobile notifications')}</span>*/}
              {/*  </div>*/}
              {/*  <span className="collection_col collection_col_arrow">*/}
              {/*    <IconArrowLeft/>*/}
              {/*  </span>*/}
              {/*</div>*/}
              {/*<div className="collection_title">{__('Personal Information')}</div>*/}
              {/*<div className="collection_row">*/}
              {/*  <div className="collection_col collection_col_left">*/}
              {/*    <span className="collection_text">{__('Change e-mail')}</span>*/}
              {/*  </div>*/}
              {/*  <span className="collection_col collection_col_arrow">*/}
              {/*    <IconArrowLeft/>*/}
              {/*  </span>*/}
              {/*</div>*/}
              {/*<div className="collection_row">*/}
              {/*  <div className="collection_col collection_col_left">*/}
              {/*    <span className="collection_text">{__('Change password')}</span>*/}
              {/*  </div>*/}
              {/*  <span className="collection_col collection_col_arrow">*/}
              {/*    <IconArrowLeft/>*/}
              {/*  </span>*/}
              {/*</div>*/}
              <div className="collection_title">{__('Your subscription')}</div>
              <div
                className="collection_row"
                onClick={() =>
                  is_vip
                    ? this.setState({
                        typeSettings: 'Your subscription',
                      })
                    : this.getPremium()
                }>
                <div className="collection_col collection_col_left">
                  <span className="collection_text">{__('Manage')}</span>
                </div>
                <span className="collection_col collection_col_arrow">
                  <IconArrowLeft />
                </span>
              </div>
              <div className="collection_title">{__('About Us')}</div>
              <a href="/terms" target="_blank" className="collection_row">
                <div className="collection_col collection_col_left">
                  <span className="collection_text">{__('Terms of Use')}</span>
                </div>
                <span className="collection_col collection_col_arrow">
                  <IconArrowLeft />
                </span>
              </a>
              <a href="/policy" target="_blank" className="collection_row">
                <div className="collection_col collection_col_left">
                  <span className="collection_text">
                    {__('Privacy Policy')}
                  </span>
                </div>
                <span className="collection_col collection_col_arrow">
                  <IconArrowLeft />
                </span>
              </a>
            </div>
          )}
        </div>
        <div className="popup_footer my-profile-settings__popup_footer">
          {this.state.typeSettings === '' && (
            <div className="controls_actions">
              <div
                onClick={() => {
                  this.setState({showConfirmLogout: true});
                }}
                className="btn_control btn_logout">
                {__('Log out')}
              </div>
              <div
                onClick={() => {
                  this.setState({showConfirm: true});
                }}
                className="btn_control btn_delete-account">
                {' '}
                {__('Delete profile')}
              </div>
            </div>
          )}
          {this.state.typeSettings === 'Your subscription' && (
            <div className="subscriptions_actions">
              <Button
                class="btn_primary subscriptions_actions__btn"
                onClick={this.goProfileSettings}>
                {this.props.myProfile.is_unsubscribed
                  ? __('Continue')
                  : __('Keep my Premium Benefits')}
              </Button>
              {this.props.myProfile.errorCancelSubscription.length ? (
                <div className="btn_link subscriptions_actions__btn">
                  {__('Go to')}&nbsp;<a href="https://stripe.com">stripe.com</a>
                  &nbsp;{__('by the link to unsubscribe')}
                </div>
              ) : this.props.myProfile.is_unsubscribed ? (
                <div className="btn_link subscriptions_actions__btn">
                  {__('Premium till')}{' '}
                  {dateFilter(+this.props.myProfile.vip_end * 1000, 'dateFull')}{' '}
                  {dateFilter(+this.props.myProfile.vip_end * 1000, 'time')}
                </div>
              ) : (
                <div
                  className="btn_link subscriptions_actions__btn"
                  onClick={this.handleCancelSubscription}>
                  {this.props.myProfile.cancelSubscriptionLoading
                    ? __('Loading...')
                    : __('Cancel subscription and loose all benefits')}
                </div>
              )}
            </div>
          )}
        </div>
        {this.state.showConfirm ? (
          <ConfirmDelete
            textLine2={__('This can’t be undone.')}
            textLine1={__('You will lose all your contacts.')}
            textButton={__('Delete my profile')}
            title={__('Are you sure?')}
            confirm={(value) => this.confirmDeleteProfile(value)}
          />
        ) : null}
        {this.state.showConfirmLogout ? (
          <ConfirmDelete
            textLine1={__(
              'You will not recieve notifications about new messages.',
            )}
            textLine2=""
            textButton={__('Logout')}
            title={__('Are you sure you want to log out?')}
            confirm={(value) => this.confirmLogout(value)}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      myProfile: state.myProfile,
      upload: state.upload,
      screen: state.screen,
      peopleSearch: state.peopleSearch,
    }),
    (dispatch) => ({
      myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
      uploadActions: bindActionCreators(bindUploadActions, dispatch),
      peopleSearchActions: bindActionCreators(
        bindPeopleSearchActions,
        dispatch,
      ),
      peopleActions: bindActionCreators(bindPeopleActions, dispatch),
      logoutActions: bindActionCreators(bindLogoutActions, dispatch),
    }),
  )(MyProfileSettings),
);
