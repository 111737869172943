import axios from 'axios';
import {
  FAVORITES_ADD_SUCCESS,
  LIVE_CHAT_FAVORITE_LIST_FAIL,
  LIVE_CHAT_FAVORITE_LIST_REQUEST,
  LIVE_CHAT_FAVORITE_LIST_SUCCESS,
  LIVE_CHAT_LIST_FAIL,
  LIVE_CHAT_LIST_REQUEST,
  LIVE_CHAT_LIST_SUCCESS,
} from '../constants';

export function listFavoritesStream() {
  return dispatch => {
    dispatch({
      type: LIVE_CHAT_FAVORITE_LIST_REQUEST,
    });

    return axios
      .post('/stream/active-favorite-list', {
        limit: 100,
        offset: 0,
      })
      .then(result => {
        console.log('stream/active-favorite-list', result);
        dispatch({
          type: LIVE_CHAT_FAVORITE_LIST_SUCCESS,
          payload: result.data.result,
        });
        return result.data.result;
      })
      .catch(err => {
        console.log('stream/active-favorite-list err', err.response);
        dispatch({
          type: LIVE_CHAT_FAVORITE_LIST_FAIL,
        });
      });
  };
}

export function listStream() {
  return dispatch => {
    dispatch({
      type: LIVE_CHAT_LIST_REQUEST,
    });

    return axios
      .post('/stream/active-list', {
        limit: 100,
        offset: 0,
      })
      .then(result => {
        console.log('stream/active-list', result);
        dispatch({
          type: LIVE_CHAT_LIST_SUCCESS,
          payload: result.data.result,
        });
      })
      .catch(err => {
        console.log('err', err);
        dispatch({
          type: LIVE_CHAT_LIST_FAIL,
        });
      });
  };
}

export function addToFavorites(item) {
  return dispatch => {
    let moveTo = 'Favorite';
    let oldFolderType = 'Income';

    const body = {
      user_id: item.user.user_id,
      folder_type: moveTo,
      old_folder_type: oldFolderType,
    };

    dispatch({
      type: FAVORITES_ADD_SUCCESS,
      payload: {user: item.user, stream: item, isActionFromStream: true},
    });

    return axios
      .post('/contacts/move', body)
      .then(response => {
        console.log('response /contacts/move', response);
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function removeFromFavorites(item) {
  return dispatch => {
    let moveTo = 'Income';
    let oldFolderType = 'Favorite';

    const body = {
      user_id: item.user.user_id,
      folder_type: moveTo,
      old_folder_type: oldFolderType,
    };

    dispatch({
      type: FAVORITES_ADD_SUCCESS,
      payload: {user: item.user, stream: item, isActionFromStream: true},
    });

    return axios
      .post('/contacts/move', body)
      .then(response => {
        console.log('response /contacts/move', response);
      })
      .catch(err => {
        console.log(err);
      });
  };
}
