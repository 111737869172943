export const getFormatCurrentDateTime = () => {
  const now = new Date();
  const leadZero = (int) => {
    return int > 9 ? int : `0${int}`;
  };
  return `${now.getFullYear()}-${leadZero(now.getMonth())}-${leadZero(
    now.getDate(),
  )}T${leadZero(now.getHours())}:${leadZero(now.getMinutes())}:${leadZero(
    now.getSeconds(),
  )}`;
};
