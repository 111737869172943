import React from 'react';
import './style.scss';
import __ from '../../../utils/translate'
import {ReactComponent as IconPhoto} from '../../Icons/Photo.svg';

const LoadPhotoBtn = (props) => {
  return (
    <div className="load-photo__btn" onClick={props.onClick}>
      <IconPhoto />
      <span className="load-photo__text">{__('Add photo')}</span>
    </div>
  );
};

export {LoadPhotoBtn};
