import React from 'react';
import ReactDOM from 'react-dom';
import './landing.css';
import './index.css';
import './ts-index.css';
import './responsive.css';
import './_slick.css';
import './_slick_theme.css';
import * as serviceWorker from './serviceWorker';
import './config/axiosConfig';
import store from './store';
import Root from './Components/Root';
import './i18n';
import { isTheyStream } from './apps-config';

const app = <Root store={store} />;

if (isTheyStream()) {
    document.documentElement.style.cssText = `
        --main-bg-color: #000000;
        --primary-color: #a09881;
        --primary-color-hover: #a09881;
        --primary-color-active: #a09881;
        --primary-border-color: #a09881;
        --main-color-text: #ffffff;
        --light-color-text: #8e9295;
    `;
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = 'ts-favicon.png';
    document.title = 'Match meet'
}

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();