export class ModalRouteService {
    modalStack = [];

    currentPath() {
        if (this.modalStack.length) {
            return this.modalStack[this.modalStack.length - 1];
        } else {
            return null;
        }
    }

    addPath(path) {
        const _currentPath = this.currentPath();
        if (_currentPath !== path) {
            this.modalStack.push(path);
        }
    }

    removePath() {
        this.modalStack.pop();
    }

    removeAllStack() {
        this.modalStack = [];
    }
}

const modalRouteService = new ModalRouteService();
export default modalRouteService;