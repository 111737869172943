import React, {Component} from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {ReactComponent as Logo} from '../../Icons/Logo.svg';
import {ReactComponent as TsLogo} from '../../Icons/theystream/Logo.svg';
import {ReactComponent as IconPeople} from '../../Icons/People.svg';
import {ReactComponent as TsIconPeople} from '../../Icons/theystream/People.svg';
import {ReactComponent as TsIconPeopleActive} from '../../Icons/theystream/ActivePeople.svg';
import {ReactComponent as IconFavorites} from '../../Icons/Favorites.svg';
import {ReactComponent as IconNotification} from '../../Icons/Notification.svg';
import {ReactComponent as IconMessages} from '../../Icons/Messages.svg';
import {ReactComponent as IconProfile} from '../../Icons/Profile.svg';
import {ReactComponent as IconPeopleActive} from '../../Icons/PeopleActive.svg';
import {ReactComponent as IconFavoritesActive} from '../../Icons/FavoritesActive.svg';
import {ReactComponent as IconProfileActive} from '../../Icons/ProfileActive.svg';
import {ReactComponent as IconMessagesActive} from '../../Icons/MessagesActive.svg';
import {ReactComponent as IconNotificationActive} from '../../Icons/NotificationActive.svg';
import {Backdrop} from '../../UI';
import {connect} from 'react-redux';
import Notifications from '../../../Containers/Notifications';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../../store/actions/eventsActions';
import {isLoka, isTheyStream} from '../../../apps-config';
import __ from '../../../utils/translate';

class MainMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: false,
    };
  }

  componentDidMount() {
    if (this.props.registration.inProgress === true) {
      this.props.registration.inProgress = false;
    }
  }

  openNotification = () => {
    this.setState({
      showNotification: true,
    });
    this.props.eventsActions.openModal(this.state.showNotification);
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
    this.props.eventsActions.closeModal(this.state.showNotification);
  };

  render() {
    const clsMenuItem = isTheyStream() ? 'ts-nav-menu__item' : 'nav-menu__item';
    const clsNotification = isTheyStream()
      ? ['ts-nav-menu__item']
      : ['nav-menu__item'];

    const clsHeader = ['header', 'header__main'];

    if (this.state.showNotification) {
      clsNotification.push('active');
    }
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsHeader.push('blur');
    }

    if (
      this.props.events.results.filter((it) => it.is_read === false).length > 0
    ) {
      clsNotification.push('new-notifications');
    }
    return (
      <>
        <header
          className={clsHeader.join(' ')}
          style={{
            ...(isTheyStream()
              ? {backgroundColor: '#322F28', borderColor: '#322F28'}
              : {}),
          }}>
          <div className="container">
            <nav>
              <Link className="header__logo" to="/">
                {isTheyStream() ? (
                  <TsLogo style={{height: 40, width: 88}} />
                ) : (
                  <Logo />
                )}
              </Link>
              <ul className="nav-menu">
                {/*{isTheyStream() ? (*/}
                {/*  <li>*/}
                {/*    <NavLink*/}
                {/*      to="/broadcast"*/}
                {/*      exact={true}*/}
                {/*      activeClassName="active"*/}
                {/*      className={clsMenuItem}>*/}
                {/*      <span className="no-active">*/}
                {/*        <TsIconBroadcast />*/}
                {/*      </span>*/}
                {/*      <span className="active">*/}
                {/*        <TsIconBroadcastActive />*/}
                {/*      </span>*/}
                {/*      <span className="nav-menu__text">{__('Broadcasts')}</span>*/}
                {/*    </NavLink>*/}
                {/*  </li>*/}
                {/*) : null}*/}
                <li>
                  <NavLink
                    to="/"
                    exact={true}
                    activeClassName="active"
                    className={clsMenuItem}>
                    <span className="no-active">
                      {isTheyStream() ? <TsIconPeople /> : <IconPeople />}
                    </span>
                    <span className="active">
                      {isTheyStream() ? (
                        <TsIconPeopleActive />
                      ) : (
                        <IconPeopleActive />
                      )}
                    </span>
                    <span className="nav-menu__text">{__('People')}</span>
                  </NavLink>
                </li>
                {isLoka() ? (
                  <li>
                    <NavLink
                      to="/favorites"
                      activeClassName="active"
                      className={clsMenuItem}>
                      <span className="no-active">
                        <IconFavorites />
                      </span>
                      <span className="active">
                        <IconFavoritesActive />
                      </span>
                      <span className="nav-menu__text">{__('Favorites')}</span>
                    </NavLink>
                  </li>
                ) : null}
                <li onClick={this.openNotification}>
                  <div className={clsNotification.join(' ')}>
                    <span className="no-active">
                      <IconNotification />
                    </span>
                    <span className="active">
                      <IconNotificationActive />
                    </span>
                    <span className="nav-menu__text">
                      {__('Notifications')}
                    </span>
                  </div>
                </li>
                <li>
                  <NavLink
                    to="/messages"
                    activeClassName="active"
                    className={clsMenuItem}>
                    {this.props.session.unreadMessageCount ? (
                      <span className="count-new-messages">
                        {this.props.session.unreadMessageCount}
                      </span>
                    ) : null}
                    <span className="no-active">
                      <IconMessages />
                    </span>
                    <span className="active">
                      <IconMessagesActive />
                    </span>
                    <span className="nav-menu__text">{__('Messages')}</span>
                  </NavLink>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: '/my-profile',
                      state: {modal: true},
                    }}
                    className={clsMenuItem}>
                    <span className="no-active">
                      <IconProfile />
                    </span>
                    <span className="active">
                      <IconProfileActive />
                    </span>
                    <span className="nav-menu__text">{__('Profile')}</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        {this.state.showNotification ? (
          <>
            <Notifications close={this.closeNotification} />
            <Backdrop onClick={this.closeNotification} />
          </>
        ) : null}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      events: state.events,
      registration: state.registration,
      session: state.session,
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
    }),
  )(MainMenu),
);
