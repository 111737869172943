import {
  UPLOAD_PHOTO_REQUEST,
  UPLOAD_PHOTO_PROGRESS,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAIL,
} from '../constants';

const initialState = {
  isFetching: false,
  isDocument: false,
  albumType: '',
  isUploadComplete: false,
  uploadedItems: 0,
  totalItems: 0,
  error: ''
};

export default function upload(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_PHOTO_REQUEST:
      return {
        ...state,
        isFetching: true,
        isUploadComplete: false,
      };

    case UPLOAD_PHOTO_PROGRESS:
      return {
        ...state,
        uploadedItems: action.payload.uploadedItems,
        totalItems: action.payload.totalItems
      };

    case UPLOAD_PHOTO_SUCCESS:
      return {
        isFetching: false,
        isUploadComplete: true,
        uploadedItems: 0,
        totalItems: 0,
        isDocument: action.payload.isDocument,
        albumType: action.payload.albumType,
        error: ''
      };

    case UPLOAD_PHOTO_FAIL:
      return {
        isFetching: false,
        isUploadComplete: true,
        uploadedItems: 0,
        totalItems: 0,
        error: action.payload
      };

    default:
      return state;
  }
}
