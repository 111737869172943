import {PEOPLE_SEARCH_SET_PARAMS, PEOPLE_SEARCH_RESET} from '../constants';

const initialState = {
  country_id: '',
  // region_id: '',
  // city_id: '',
  formatted_address: '',
  isFetching: false,
  languages: [],
  gender: null,
  age: [18, 99],
};

const peopleSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case PEOPLE_SEARCH_SET_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    case PEOPLE_SEARCH_RESET:
      return {
        country_id: null,
        // region_id: null,
        // city_id: null,
        formatted_address: '',
        isFetching: false,
        languages: [],
        gender: null,
        age: [18, 99],
      };
    default:
      return state;
  }
};

export default peopleSearchReducer;
