import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {bindActionCreators} from 'redux';
import * as bindPurchaseActions from '../../store/actions/purchaseActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import __ from '../../utils/translate';
import './styles.scss';
import {isTheyStream} from '../../apps-config';
import {declOfNum} from '../../helpers';
import {PAYMENT_TYPE} from '../../store/constants';
import modalRouteService from '../../services/ModalRouteService';

class GetPremium extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.state = {};
    }

    handleOnClick = () => {
        this.props.eventsActions.closeModal(false);
    };

    componentDidMount() {
        modalRouteService.addPath(this.props.match.url);
    }

    goBack() {
        document.removeEventListener('keydown', this.escFunction, false);
        this.props.eventsActions.closeModal(false);
        modalRouteService.removePath();
        this.props.history.push({pathname: '/add-coins', state: {modal: true, go: true}});
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.goBack();
        }
    }

    choosePayment(plan_id, productId) {
        if (this.props.credentials && this.props.credentials.user_id === 136810) {
            this.props.purchaseActions.getPaymentUrl(productId);
            this.props.history.push({
                pathname: '/get-payment',
                state: {modal: true, planId: plan_id, productId}
            });

            return;
        }

        switch (PAYMENT_TYPE) {
            case 'pw': {
                this.props.purchaseActions.getPaymentUrl(productId);
                this.props.history.push({
                    pathname: '/get-payment',
                    state: {modal: true, planId: plan_id, productId},
                });
                break;
            }
            case 'paypal': {
                this.props.history.push({
                    pathname: '/get-payment',
                    state: {modal: true, planId: plan_id, productId},
                });
                break;
            }
            case 'stripe': {
                this.props.purchaseActions.stripeGetSession(productId);
                break;
            }
            default: {
                this.props.purchaseActions.getPaymentUrl(productId);
                this.props.history.push({
                    pathname: '/get-payment',
                    state: {modal: true, planId: plan_id, productId},
                })
            }
        }
    }

    countSave(product, tariffs) {
        let save;
        const priceByFirstMoth = tariffs.subscriptions.sort(function (a, b) {
            return a.value.month - b.value.month;
        })[0].amount_further;
        const priceByMonth = (+(product.amount / +product.value.month)).toFixed(2);
        save = (+(100 - (priceByMonth * 100) / priceByFirstMoth)).toFixed(0);
        return save;
    }

    render() {
        const {isFetching, tariffs} = this.props.purchase;
        const clsBody = isTheyStream()
            ? 'popup_body popup-purchase ts-popup-purchase'
            : 'popup_body popup-purchase';
        return (
            <div className={clsBody}>
                <div className="popup-purchase__head">
                    <div className="btn_close" onClick={this.goBack}>
                        <IconClose/>
                    </div>
                    <h1 className="popup-purchase__title">{__('Get Premium')}</h1>
                    <p className="popup-purchase__description">
                        {__('Choose your plan and save.')}
                        <br/>
                        {__('No commitments, cancel anytime.')}
                    </p>
                </div>
                <div className="popup-purchase__content-sub popup_content scroll">
                    {isFetching
                        ? __('Loading...')
                        : tariffs.subscriptions && tariffs.subscriptions.length
                            ? tariffs.subscriptions
                                .sort(function (a, b) {
                                    return a.value.month - b.value.month;
                                })
                                .map((product, i) => (
                                    <>
                                        {i === 0 && product.type === 'promo' ? (
                                            <div key={i} className="purchase-sub purchase-sub_trial">
                                                <div
                                                    className="purchase-sub__container purchase-sub__container_trial"
                                                    onClick={() =>
                                                        this.choosePayment(product.plan_id, product.id)
                                                    }>
                                                    <div className="purchase-sub__month">
                                                        {product.value.month} {__('Month')}
                                                    </div>
                                                    <div className="purchase-sub__cost">
                                                        {product.currency === 'usd' ? '$' : null}
                                                        {product.amount}
                                                        {product.currency === 'eur' ? '€' : null} /mo
                                                    </div>

                                                    <div className="purchase-sub__benefit purchase-sub__benefit_trial">
                                                        <div className="purchase-sub__benefit-cost">
                                                            {__('Introductory price')} -
                                                        </div>
                                                        <div className="purchase-sub__benefit-dicount">
                                                            60% off
                                                        </div>
                                                    </div>
                                                </div>

                                                <p className="discount-text">
                                                    *{' '}
                                                    {__(
                                                        'first month discount. Automatically renews for 14.99€/mo starting from the 2nd month.',
                                                    )}
                                                </p>
                                                {/*todo: сделать переводы с учетом динамического значения 14,99*/}
                                            </div>
                                        ) : null}
                                        {i === 0 && product.type !== 'promo' ? (
                                            <div
                                                key={i}
                                                className="purchase-sub"
                                                onClick={() =>
                                                    this.choosePayment(product.plan_id, product.id)
                                                }>
                                                <div className="purchase-sub__container">
                                                    <div className="purchase-sub__month">
                                                        {product.value.month} {__('Month')}
                                                    </div>
                                                    <div className="purchase-sub__cost">
                                                        {product.currency === 'usd' ? '$' : null}
                                                        {(+(product.amount / +product.value.month)).toFixed(
                                                            2,
                                                        )}
                                                        {product.currency === 'eur' ? '€' : null} /mo
                                                    </div>

                                                    <div className="purchase-sub__benefit">
                                                        <div className="purchase-sub__benefit-cost">
                                                            {product.currency === 'usd' ? '$' : null}
                                                            {product.amount_further}
                                                            {product.currency === 'eur' ? '€' : null} total -
                                                        </div>
                                                        <div className="purchase-sub__benefit-dicount">
                                                            Save {this.countSave(product, tariffs)}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        {i !== 0 ? (
                                            <div
                                                key={i}
                                                className="purchase-sub"
                                                onClick={() =>
                                                    this.choosePayment(product.plan_id, product.id)
                                                }>
                                                <div className="purchase-sub__container">
                                                    <div className="purchase-sub__month">
                                                        {product.value.month}{' '}
                                                        {declOfNum(product.value.month, [
                                                            __('Month'),
                                                            __('Months'),
                                                            __('Months'),
                                                        ])}
                                                    </div>
                                                    <div className="purchase-sub__cost">
                                                        {product.currency === 'usd' ? '$' : null}
                                                        {(+(product.amount / +product.value.month)).toFixed(
                                                            2,
                                                        )}
                                                        {product.currency === 'eur' ? '€' : null} /mo
                                                    </div>

                                                    <div className="purchase-sub__benefit">
                                                        <div className="purchase-sub__benefit-cost">
                                                            {product.currency === 'usd' ? '$' : null}
                                                            {product.amount_further}
                                                            {product.currency === 'eur' ? '€' : null} total -
                                                        </div>
                                                        <div className="purchase-sub__benefit-dicount">
                                                            Save {this.countSave(product, tariffs)}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </>
                                ))
                            : 'error'}
                </div>
                <div className="popup-purchase__agree">
                    <Link to="/terms" onClick={() => this.handleOnClick()}>
                        {__('Terms of Service {{and}} Privacy Policy', {
                            and: <span>{__('and')}</span>,
                        })}
                    </Link>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => ({
            purchase: state.purchase,
            credentials: state.credentials,
        }),
        (dispatch) => ({
            purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
            myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
            eventsActions: bindActionCreators(bindEventsActions, dispatch),
        }),
    )(GetPremium),
);
