// import appsConfiguration from './apps.configuration';

export const LOKA = 'loka';
export const THEY_STREAM = 'theystream';

export const CURRENT_APP = THEY_STREAM;
// export const CURRENT_APP = LOKA;

console.log('CURRENT_APP', CURRENT_APP);

window.CURRENT_APP = CURRENT_APP;

export function isLoka() {
  return CURRENT_APP === LOKA;
}

export function isTheyStream() {
  return CURRENT_APP === THEY_STREAM;
}

export function getCurrentApp() {
  return CURRENT_APP;
}
