import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showServerError } from '../../store/actions/eventsActions';
import { ReactComponent as IconClose } from '../Icons/Close.svg';
import { ReactComponent as IconError } from '../Icons/Error.svg';
import { Button } from '../UI';
import './styles.scss';
import __ from '../../utils/translate';

const ServerError = () => {
  const { serverError } = useSelector(state => state.events);
  const dispatch = useDispatch();
  const hideServerError = () => {
    dispatch(showServerError(false));
  };

  return (
    serverError
      ? <div className="server-error">
        <div className="server-error__container">
          <div
            className="server-error__cancel"
            onClick={hideServerError}
          ><IconClose/></div>
          <IconError/>
          <h4 className="server-error__title">
            {__('Oops')}…
          </h4>
          <p className="server-error__text">
            {__('Server returned no responce. Check your network and try again!')}
          </p>
          <Button
            class="primary"
            onClick={hideServerError}>
            {__('Retry')}</Button>
        </div>
      </div>
      : null
  );
};

export default ServerError;