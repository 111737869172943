import React, {Component} from 'react';
import Footer from '../../Components/Footer';
import {connect} from 'react-redux';
import {isTheyStream} from '../../apps-config';

class Policy extends Component {
  render() {
    const clsMain = [];
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsMain.push('blur');
    }
    if (isTheyStream()) {
      clsMain.push('ts-main');
    }
    return (
      <>
        <main id="main" className={clsMain.join(' ')}>
          <div className="container">
            <h1 className="main__title">
              This Privacy Policy was last updated on June 01, 2020.
            </h1>
            <div>
              Galocor OÜ (“we” and “us” in any declension) respects the privacy
              of its users and has developed this Privacy Policy to demonstrate
              its commitment to protecting your privacy. This Privacy Policy
              governs your use of Match & Meet (“Service”) and describes the
              data we collect, how it is stored, how that data may be used, with
              whom it may be shared, and your choices about such uses and
              disclosures. Please be sure to carefully read the entirety of this
              Privacy Policy when using our Service.
              <br />
              <br />
              <br />
              Check the Match & Meet’s Terms of Use (“Terms”) for the meaning of
              defined words (those with capital letters) not explicitly defined
              in this Privacy Policy.
              <br />
              <br />
              <br />
              2. Who we are.
              <br />
              <br />
              <br />
              Galocor OÜ, Harju maakond, Tallinn, Mustamäe linnaosa, Ehitajate
              tee 110, 13517, Estonia, will be the controller of your personal
              data provided to, or collected by or for, or processed in
              connection with our Service.
              <br />
              <br />
              <br />
              If you have any questions about how we protect or use your data,
              please email us at{' '}
              <a href="mailto:privacy@corp.loka.world">
                privacy@corp.loka.world
              </a>
              .
              <br />
              <br />
              <br />
              3. What kinds of data do we collect about you?
              <br />
              <br />
              <br />
              We collect data you make available to us voluntarily on the Match
              & Meet in various situations (e.g., when you send us an email). We
              also collect data automatically Match & Meet (e.g. your IP
              address).
              <br />
              <br />
              <br />
              3.1. Data you give us
              <br />
              <br />
              <br />
              You may provide us data in different ways. For example, we collect
              and store data that you give us when you register user profile
              (“Profile”) or respond to Match & Meet's emails or report a
              problem with the Service. The data that you give us includes:
              <br />
              <br />
              <br />
              Profile data. This includes your name, date of birth and email
              address. You may optionally provide additional data on your
              Profile (complete Profile helps you get the most from our
              Service). This includes your dating preferences, interests and
              activities, your gender and age, religion, address, photographs,
              other personal characteristics and demographic data such as your
              height, weight, body type, marital status, habits, educational
              background, and hometown.
              <br />
              <br />
              <br />
              Because you control your Profile, these details are available to
              you at any time by accessing your “Profile” page, which gives you
              the chance to correct or update (other than name, date of birth
              and email address) your data at any time by logging in to Match &
              Meet. Please do not post or add personal data to your Profile that
              you would not want to be publicly available. We also do not
              recommend that you put email addresses, instant messaging details,
              phone numbers, credit card details, national identity numbers,
              drivers’ license details in your Profile which may make them open
              to abuse and misuse. Please be careful about posting sensitive
              details about yourself on your Profile such as religion, health
              details, sexual preferences or ethnic background. Where you choose
              provide us sensitive data about yourself, you are manifestly
              making this public to other users.
              <br />
              <br />
              <br />
              Identity document data (“ID”). You may voluntarily provide us with
              your ID for Profile verification or we may ask you to provide your
              ID for security reasons or when we are required to do so by law.
              <br />
              <br />
              <br />
              3.2. Data we collect about you
              <br />
              <br />
              <br />
              We collect data through a variety of sources. One of our sources
              is cookies a that record data about the use of our website. With
              regard to your use of our Services we may automatically collect
              the following data:
              <br />
              <br />
              <br />
              Navigation data. We collect data about how you navigate and use
              our Services, such as the types of content you view or engage with
              or the frequency and duration of your activities.
              <br />
              <br />
              <br />
              Cookies and other similar technologies. As further described in
              our Cookie Policy, our Service use cookies to distinguish you from
              other users. You can control cookies through your browser
              settings.
              <br />
              <br />
              <br />
              Browser and Device data. We collect data from or about the
              computers, phones, or other devices where you install or access
              our Service, depending on the permissions you’ve granted. In
              particular, we ask for access to your photos and camera of your
              device when you want to upload or make any photos for your
              profile. We may associate the data we collect from your different
              devices, which helps us provide consistent Services across your
              devices. Browser and Device data may include data about your
              Internet browser type, operating system, hardware version, type
              and model, geolocation, IP address, connection data such as ISP,
              language and time zone. We also receive the URL of both the site
              you came from and the one you go to next.
              <br />
              <br />
              <br />
              Data about payments. We collect data about your credit purchase
              amount, date of credit purchase and payment method.
              <br />
              <br />
              <br />
              Transaction data. When you make payments through the Service, you
              need to provide financial account data, such as your credit card
              number, to our third-party service providers. We do not collect or
              store full credit card number data, though we may receive credit
              card-related data, data about the transaction, including: date,
              time and amount of the transaction, the type of payment method
              used.
              <br />
              <br />
              <br />
              Advertising. We partner with a third party ad network to manage
              our advertising on other sites. Our ad network partner uses
              cookies to collect non-personal data about your activities on this
              and other web sites to provide you targeted advertising based upon
              your interests.
              <br />
              <br />
              For more information about advertising and tracking online, visit
              the Network Advertising Initiative or Digital Advertising Alliance
              . This website allows consumers to “opt out” of the behavioral
              advertising delivered by member companies. To learn more about the
              cookies that may be served through our Service, and how you can
              control our use of cookies, please see our Cookies Policy.
              <br />
              <br />
              <br />
              3.3. Data about Children
              <br />
              <br />
              <br />
              We do not knowingly collect any data about children, minors or
              anyone under the age of 18. Nor do we knowingly market to
              children, minors or anyone under the age of 18. If you are less
              than 18 years old, we request that you do not submit data to us.
              If we become aware that a child, minor or anyone under the age of
              18 has registered with us and provided us with personal data, we
              will take steps to terminate that Profile and delete their
              personal data.
              <br />
              <br />
              <br />
              4. How we use the data we collect?
              <br />
              <br />
              <br />
              We and our service providers use personal data to: (i) provide the
              Service; (ii) detect and prevent fraud; (iii) promote, analyze and
              improve our Service.
              <br />
              <br />
              <br />
              In particular, we use your personal data in order:
              <br />
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  to provide you with the Services you use or request, for
                  example, we use your data to authenticate you and authorize
                  access to our Services, to suggest your Profile to the users
                  who conducts extensive search by comparing that search
                  parameters to data provided in your Profile.
                </li>
                <li>
                  to improve our Service by using data about your usage and
                  interaction with our Service. We conduct surveys and research,
                  test features in development. We analyze the data we have to
                  evaluate our Service, and conduct audits and troubleshooting
                  activities to improve our Service content and layouts.
                </li>
                <li>
                  to personalize the Service for you, such as to select the
                  payment processor available to you, we also may use your data
                  to determine your eligibility for promotions, sweepstakes and
                  contests and show you personalized advertisements, if you
                  provided consent on it. In particular, we use identifiers such
                  as GAID (Google Advertise ID) for Android and IDFA (Identifier
                  for Advertisers) for iOS for making personalized
                  advertisements for you.
                </li>
                <li>
                  to process your payments and send notices about your
                  transactions.
                </li>
                <li>
                  to verify your identity, including during account
                  verification, background checks on users.
                </li>
                <li>
                  to prevent, detect, investigate, resolve disputes and/or
                  remediate malicious activities, fraud, other cybercrimes, take
                  legally-required actions or make sure you are a real person
                  and want to avoid fake accounts being created.
                </li>
                <li>
                  to contact you about our Service matters by email and other
                  ways through our Services, including push notifications. We
                  will send you messages about the availability of our Service,
                  security, payment transactions, status of your orders, legal
                  notices or other service-related information (Service
                  messages). We also send messages about your statistics, such
                  as who visit your Profile, add you to favorites list, like you
                  or send you a message (Statistic messages), you can
                  unsubscribe from Statistic messages at any time. Please be
                  aware that you cannot opt out of receiving Service messages
                  from us.
                </li>
                <li>
                  to show you advertisements on our Service and/or send you
                  commercial email about our Service, features, offers,
                  promotions, contests, and events or provide other news or
                  information about third party services that may be of interest
                  to you. We offer you choice to opt out advertisement and/or
                  commercial emails.
                </li>
                <li>to provide you customer service and support.</li>
              </ul>
              <br />
              <br />
              We will also notify you if we want to use your personal data for a
              new or different purpose. We will use your personal data for such
              other purposes only if and to the extent necessary or permitted by
              applicable law or with your consent.
              <br />
              <br />
              <br />
              5. Legal basis for processing (EEA only).
              <br />
              <br />
              <br />
              If you are an individual from the European Economic Area (EEA),
              our legal basis for collecting and using the personal data will
              depend on the personal data concerned and the specific context in
              which we collect it. However, we will normally collect personal
              data from you only where: (a) we have your consent to do so, (b)
              where we need the personal data to perform a contract with you
              (e.g. to deliver the Match & Meet Service you have requested), or
              (c) where the processing is in our or a third party’s legitimate
              interests (and not overridden by your data protection interests or
              fundamental rights and freedoms).
              <br />
              <br />
              <br />
              6. Advertising.
              <br />
              <br />
              <br />
              6.1. Advertising of other services on Match & Meet. We display
              advertising of third party services using data you make available
              to us when you interact with our Service, such as credit
              purchasing on Match & Meet or duration of the Service usage. Our
              custom system enables us to learn about when and what ads you see,
              what ads you click. This allows us to provide you with more useful
              and relevant ads. For example, if we know what ads you are shown
              we can be careful not to show you the same ones repeatedly.
              <br />
              <br />
              6.2. Advertising of our Service on other websites. We serve you
              tailored advertising outside of our Service based on data relating
              to your access to and use of our Service, as well as data received
              from third parties. In order to understand and improve the
              effectiveness of our advertising, we may use cookies and other
              technologies to identify the fact that you have visited Match &
              Meet or seen one of our advertisements on other website, and we
              may provide that data to one or more third party advertising
              networks. We may choose to work with Google AdWords, DoubleClick,
              Google Analytics or other advertising networks. Each of these
              companies has its own privacy policy. The data we provide may
              include the time and date of your visit to our website, pages
              viewed, links clicked and other data that does not disclose your
              identity. We may target advertising to you through a variety of ad
              networks and exchanges, using data from advertising technologies
              on and off of our Services like, unique cookie, or similar
              tracking technology, pixel, device identifiers, geolocation,
              operation system information, email. Advertising you receive on
              other websites is customized based on predictions about your
              interests generated from your use of our Service, visits to
              different websites. This type of advertising customization is
              called "online behavioral" or "interest-based" advertising. If you
              would like more information about this practice, and to learn how
              to opt out of online behavioral advertising by companies
              participating in industry self-regulation in desktop and mobile
              browsers on the particular device on which you are accessing this
              Privacy Policy, please visit the Digital Advertising Alliance or
              Network Advertising Initiative .
              <br />
              <br />
              At present, there is no industry standard for recognizing Do Not
              Track browser signals, so we do not respond to them.
              <br />
              <br />
              <br />
              7. Advertising Choices.
              <br />
              <br />
              <br />
              You have choices regarding our use and disclosure of your personal
              data. We offer you choices about receiving advertising.
              <br />
              <br />
              <br />
              Advertising from third parties on Match & Meet is tailored to you
              based on your previous use of Service. You can choose not to
              receive advertising from third parties by contacting our Customer
              Service. If you no longer want to receive marketing-related emails
              (i) from us about our Service or (ii) from third parties about
              similar services, you can opt out of receiving such electronic
              communications. Please note that if you opt-out of receiving
              marketing-related emails, we may still send you important
              administrative messages that are required to provide you with our
              Service. You can also generally opt-out of receiving personalized
              ads from third party advertisers and ad networks who are members
              of the Network Advertising Initiative (NAI) or who follow the
              Digital Advertising Alliance's Self-Regulatory Principles for
              online behavioral advertising by visiting the opt-out pages on the
              NAI website and DAA website.
              <br />
              <br />
              <br />
              8. Retention Period.
              <br />
              <br />
              <br />
              We will retain personal data for the period necessary to fulfill
              the purposes outlined in this Privacy Policy unless a longer
              retention period is required or permitted by law.
              <br />
              <br />
              <br />
              Please note that we have a variety of obligations to retain the
              data that you provide to us, including to ensure that transactions
              can be appropriately processed, settled, refunded or charged-back,
              to help identify fraud and to comply with anti-money laundering
              and other laws and rules that apply to us and to our financial
              service providers. Accordingly, even if you close Profile, we will
              retain certain Data to meet our obligations.
              <br />
              <br />
              <br />
              9. With whom we share your data?
              <br />
              <br />
              <br />
              Protection of your personal data is important for us. Your
              personal data will be shared with third parties only (i) if we
              have a legal obligation to do so (ii) if the data transfer is
              necessary for performance of the contract with you, (iii) if you
              have consented to the transfer of your data.
              <br />
              <br />
              <br />
              Third-party service providers and partner companies will receive
              your data only if and to the extent necessary for performance of
              our contract with you or with your consent. In such cases, the
              extent to which data is shared will however be kept to the
              absolute minimum. To the extent that our service providers come
              into contact with your personal data, we will make sure that they
              too will comply with all applicable data protection laws.
              <br />
              <br />
              <br />
              We do not share personal data with other companies, organizations
              and individuals unless one of the following circumstances applies:
              <br />
              <br />
              <br />
              With your consent. We will share personal data with other
              companies, organizations or individuals when we have your consent
              to do so.
              <br />
              <br />
              <br />
              Other users. Any data you include on your Profile and any action
              you take on our Service may be seen by other users.
              <br />
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  When you update or add photo (which you can change) is to
                  share it publicly.
                </li>
                <li>
                  When you add to favorite or visit other user Profile or like
                  other user’s photo, that person will see it.
                </li>
                <li>
                  We let senders know when you act on their chat messages.
                </li>
              </ul>
              <br />
              <br />
              <br />
              Service providers. We may share your personal data with third
              parties that perform certain services on our behalf. We use this
              services to provide hosting for and maintenance of our website,
              applications development, backup, storage, marketing assistance,
              performing business and sales analysis, supporting our Service
              functionality, and supporting contests, sweepstakes, surveys and
              other features offered through our Service. These Services process
              your personal data based on our instructions and in compliance
              with our Privacy Policy and any other appropriate confidentiality
              and security measures.
              <br />
              <br />
              <br />
              Other Situations. We also may disclose your data, including
              personal data:
              <br />
              <br />
              <br />
              In response to a subpoena or similar investigative demand, a court
              order, or a request for cooperation from law enforcement or other
              government agency; to establish or exercise our legal rights; to
              defend against legal claims; or as otherwise required by law. In
              such cases, we may raise or waive any legal objection or right
              available to us in our sole and exclusive discretion. When we
              believe disclosure is appropriate in connection with efforts to
              investigate, prevent, or take other action regarding illegal
              activity, suspected fraud or other wrongdoing; to protect and
              defend the rights, property or safety of our company, our users,
              our employees, or others; to comply with applicable law or
              cooperate with law enforcement; or to enforce our Terms of Service
              or other agreements or policies. In connection with a substantial
              corporate transaction, such as the sale of our business, a
              divestiture, merger, consolidation, or asset sale, or in the
              unlikely event of bankruptcy.
              <br />
              <br />
              <br />
              10. International data transfers.
              <br />
              <br />
              <br />
              Match & Meet is a global business. We may transfer personal data
              to countries other than the one in which you live. This country
              may not have the same data protection law as the country in which
              you initially provided the data. We deploy safeguards if we
              transfer Personal Data originating from the European Union (EU)
              and European Economic Area (EEA) to other countries not deemed
              adequate under applicable data protection law.
              <br />
              <br />
              <br />
              We process data outside of the EU and EEA.
              <br />
              <br />
              <br />
              In particular, to offer our Service, we need to transfer your
              personal data to the United States, Ukraine. The United States and
              Ukraine are not considered to provide an adequate level of
              protection under EU data protection law. It means that these
              countries do not have the same data protection laws as the country
              in which you initially provided the data.
              <br />
              <br />
              <br />
              In all cases, we use European Commission-approved Standard
              Contractual Clauses as a legal mechanism for data transfers from
              the EU. Learn more about the Standard Contractual Clauses adopted
              by the EU Commission. These clauses are contractual commitments
              between companies transferring personal data, binding them to
              protect the privacy and security of the data. Match & Meet
              accordingly adopted Standard Contractual Clauses so that the data
              flows necessary to provide, maintain, and develop our Service take
              place legally.
              <br />
              <br />
              <br />
              11. Third-party websites.
              <br />
              <br />
              <br />
              There are a number of places on our Service where you may click on
              a link to access other websites that do not operate under this
              Privacy Policy. For example, if you click on an advertisement on
              our Service, you may be taken to a website that we do not control.
              These third-party websites may independently solicit and collect
              data, including personal data, from you and, in some instances,
              provide us with data about your activities on those websites. We
              recommend that you consult the privacy statements of all
              third-party websites you visit by clicking on the "privacy" link
              typically located at the bottom of the webpage you are visiting.
              <br />
              <br />
              <br />
              12. How we protect your personal data?
              <br />
              <br />
              <br />
              We use a variety of security technologies and procedures to help
              protect your personal data from unauthorized access, use or
              disclosure. When personal data is collected on Match & Meet and/or
              transmitted to other websites, it is protected through the use of
              encryption, such as the Secure Socket Layer (SSL) protocol.
              However, data transfers are generally subject to security gaps,
              please make sure not to provide your login data to any third
              parties.
              <br />
              <br />
              <br />
              13. Changes to this Privacy Policy.
              <br />
              <br />
              <br />
              We may modify this Privacy Policy at any time. If we decide to
              make material changes to this Privacy Policy, we will provide
              notice through our Service, or by other means, to provide you an
              opportunity to revise changes before they become effective. We
              would also make visible the last date of updates introduced to
              this Privacy Policy for your convenience. We recommend that you
              add Match & Meet to your approved sender list, within your email
              reader.
              <br />
              <br />
              <br />
              If you disagree with the changes to this Privacy Policy, you
              should close your Profile. Your continued use of our Service after
              we send a notice about our changes to this Privacy Policy means
              that you are consenting to the updated Privacy Policy.
              <br />
              <br />
              <br />
              14. Privacy Rights.
              <br />
              <br />
              <br />
              You may at any time and at no cost demand information about your
              personal data that are processed by us, correction of any errors
              in your personal data, termination of processing of your personal
              data, erasure of your personal data or portability of your
              personal data. To exercise these rights, simply contact us at any
              time by email at: privacy@corp.loka.world with “Privacy Rights” on
              the subject line and in the body of your message.
              <br />
              <br />
              <br />
              If emailing us your request, please make clear in the email what
              personal data you would like to have changed. For your protection,
              we may only implement requests with respect to the personal data
              associated with the particular email address that you use to send
              us your request, and we may need to verify your identity before
              implementing your request. We will try to comply with your request
              as soon as reasonably practicable.
              <br />
              <br />
              <br />
              15. Contact Us.
              <br />
              <br />
              <br />
              If you have any questions or concerns about this Privacy Policy or
              our collection, use, or storage or your data, please do not
              hesitate to contact us by mail, email, or phone at:
              <br />
              <br />
              <br />
              Mailing address: Harju maakond, Tallinn, Mustamäe linnaosa,
              Ehitajate tee 110, 13517, Estonia
              <br />
              Attention of: Customer Support, Galocor OÜ
              <br />
              <br />
              <br />
              E-mail:{' '}
              <a href="mailto:support@corp.loka.world">
                support@corp.loka.world
              </a>
            </div>
          </div>
        </main>
        {(!this.props.session.isLogged &&
          !this.props.credentials.user_id &&
          !this.props.registration.inProgress) ||
        (!this.props.session.isLogged &&
          !this.props.credentials.user_id &&
          this.props.registration.inProgress) ? (
          <Footer />
        ) : null}
      </>
    );
  }
}

export default connect((state) => ({
  people: state.people,
  peopleSearch: state.peopleSearch,
  events: state.events,
  session: state.session,
  credentials: state.credentials,
  registration: state.registration,
}))(Policy);
