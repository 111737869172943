import React, {Fragment} from 'react';
import i18n, {json} from '../i18n';

function __(template, params = []) {
  let string = i18n.gettext(template);
  if (params) {
    string = replacePlaceholders(string, params);
  }
  return string;
}

const replacePlaceholders = (template, replacements) => {
  const splitRegex = new RegExp(/\{\{(\w*)\}\}/g);
  const parts = template.split(splitRegex);
  return parts.map((part, index) => {
    if (replacements.hasOwnProperty(part)) {
      return <Fragment key={`${index}`}>{replacements[part]}</Fragment>;
    }
    return part;
  });
};

export default __;
