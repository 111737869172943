import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import 'react-input-range/lib/css/index.css';
import {ReactComponent as IconClose} from '../../../Components/Icons/Close.svg';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../../store/actions/eventsActions';
import './style.scss';
import {Button} from '../../../Components/UI/Button';
import * as bindPeopleSearchActions from '../../../store/actions/peopleSearchActions';
import * as bindPeopleActions from '../../../store/actions/peopleActions';
import * as bindMyProfileActions from '../../../store/actions/myProfileActions';
import {genders, languages} from '../../../config/searchConfig';
import LikeTextFieldButton from '../../../Components/common/LikeTextFieldButton';
import __ from '../../../utils/translate';
import SearchSettingsLocation from '../../../Components/SearchSettings/SearchSettingsLocation';
import SearchSettingsLanguages from '../../../Components/SearchSettings/SearchSettingsLanguages';
import SearchSettingsGender from '../../../Components/SearchSettings/SearchSettingsGender';
import SearchSettingsAge from '../../../Components/SearchSettings/SearchSettingsAge';
import modalRouteService from '../../../services/ModalRouteService';
import {isTheyStream} from '../../../apps-config';
import {ReactComponent as IconWhiteClose} from '../../../Components/Icons/WhiteClose.svg';

class Search extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      typeFilter: '',
    };
  }

  componentDidMount() {
    modalRouteService.addPath(this.props.match.url);
    this.props.myProfileActions.loadMyProfile();
  }

  goBack() {
    this.props.eventsActions.closeModal(false);
    modalRouteService.removePath();
    if (modalRouteService.currentPath()) {
      this.props.history.replace({pathname: modalRouteService.currentPath()});
    } else {
      if (this.props.location.state && this.props.location.state.initialRoute) {
        this.props.history.replace({
          pathname: this.props.location.state.initialRoute,
        });
      } else {
        this.props.history.replace({pathname: '/'});
      }
      modalRouteService.removeAllStack();
    }
  }

  resetFilters = () => {
    this.props.peopleSearchActions.reset();
    this.setState({
      typeFilter: '',
    });
  };

  getPremium = () => {
    this.props.history.push({
      pathname: '/get-premium',
      state: {modal: true},
    });
  };

  resetTypeFilter = () => this.setState({typeFilter: ''});

  handleSearch() {
    this.props.peopleActions.searchPeopleByParams(this.props.peopleSearch);
    this.props.peopleActions.load();
    this.goBack();
  }

  render() {
    const clsBody = isTheyStream()
      ? 'popup_body search-settings ts-search-settings'
      : 'popup_body search-settings';

    const {is_vip} = this.props.myProfile;
    return (
      <div className={clsBody}>
        <div className="popup_head">
          <div className="profile_info">
            <div className="profile_title">{__('Search settings')}</div>
            {!this.state.typeFilter.length && (
              <button
                className="btn_link btn_filter"
                onClick={this.resetFilters}>
                {__('Reset filters')}
              </button>
            )}
          </div>
          <div
            className="btn_close mobile-mt-n3"
            onClick={
              this.state.typeFilter === '' ? this.goBack : this.resetTypeFilter
            }>
            {isTheyStream() ? <IconWhiteClose /> : <IconClose />}
          </div>
        </div>
        <div className="popup_content mobile-scroll scroll">
          <div className="edit-container">
            {this.state.typeFilter === 'Languages' ? (
              <SearchSettingsLanguages
                showAllSettings={this.resetTypeFilter}
                languages={languages}
              />
            ) : (
              this.state.typeFilter === '' && (
                <LikeTextFieldButton
                  label={__('Languages')}
                  placeholder={__('No preference')}
                  value={
                    is_vip
                      ? this.props.peopleSearch.languages
                          // .map(lang => languages.find(it => it.id === lang))
                          .map((item) => item.name)
                          .join(', ')
                      : __('Upgrade now')
                  }
                  onClick={() =>
                    is_vip
                      ? this.setState({typeFilter: 'Languages'})
                      : this.getPremium()
                  }
                />
              )
            )}
            {this.state.typeFilter === 'Gender' ? (
              <SearchSettingsGender showAllSettings={this.resetTypeFilter} />
            ) : (
              this.state.typeFilter === '' && (
                <LikeTextFieldButton
                  label={__('Gender')}
                  placeholder={__('No preference')}
                  value={
                    is_vip
                      ? this.props.peopleSearch.gender
                        ? __(
                            `${
                              genders.filter(
                                (item) =>
                                  this.props.peopleSearch.gender === item.id,
                              )[0].name
                            }`,
                          )
                        : null
                      : __('Upgrade now')
                  }
                  onClick={() =>
                    is_vip
                      ? this.setState({typeFilter: 'Gender'})
                      : this.getPremium()
                  }
                />
              )
            )}
            {this.state.typeFilter === 'Age' ? (
              <SearchSettingsAge showAllSettings={this.resetTypeFilter} />
            ) : (
              this.state.typeFilter === '' && (
                <LikeTextFieldButton
                  label={__('Age')}
                  placeholder={__('No preference')}
                  value={`From ${this.props.peopleSearch.age[0]} to ${this.props.peopleSearch.age[1]}`}
                  onClick={() => this.setState({typeFilter: 'Age'})}
                />
              )
            )}
          </div>
        </div>
        {!this.state.typeFilter.length && (
          <div className="popup_footer edit-profile-footer ">
            <Button
              onClick={() => this.handleSearch()}
              disabled={this.props.myProfile.isFetching}>
              {__('Search')}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      myProfile: state.myProfile,
      peopleSearch: state.peopleSearch,
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
      peopleSearchActions: bindActionCreators(
        bindPeopleSearchActions,
        dispatch,
      ),
      peopleActions: bindActionCreators(bindPeopleActions, dispatch),
      myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
    }),
  )(Search),
);
