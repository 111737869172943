import React, {Component} from 'react';

class ModalWithBackdrop extends Component {
  render() {
    return (
      <div className="popup_wrap">
        <div className="popup">{this.props.children}</div>
      </div>
    );
  }
}

export {ModalWithBackdrop};
