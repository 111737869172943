import React, {Component} from 'react';
import {Button, Input} from '../../Components/UI';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {YearPicker, MonthPicker, DayPicker} from '../../Components/BirthDate';
import * as bindRegistrationActions from '../../store/actions/auth/registrationActions';
import {numberByMonth} from '../../Components/BirthDate/helper';
import __ from '../../utils/translate';
import __reverse from '../../utils/reverse';
import {isLoka} from '../../apps-config';
import axios from 'axios';

const NAME_STEP = 'NAME_STEP';
const BIRTHDAY_STEP = 'BIRTHDAY_STEP';
const GENDER_STEP = 'GENDER_STEP';

class SetPersonalInfo extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      year: '',
      month: '',
      day: '',
      gender: 'F',
      mobileStep: NAME_STEP,
    };
  }

  componentWillUnmount() {
    this.props.registrationActions.clearErrors();
  }

  componentDidMount() {
    if (localStorage.getItem('lk-name')) {
      this.setState({
        name: localStorage.getItem('lk-name'),
      });
    }
    if (localStorage.getItem('lk-gender')) {
      this.setState({
        gender: localStorage.getItem('lk-gender'),
      });
    }
    if (localStorage.getItem('lk-day')) {
      this.setState({
        day: localStorage.getItem('lk-day'),
      });
    }
    if (localStorage.getItem('lk-month')) {
      this.setState({
        month: localStorage.getItem('lk-month'),
      });
    } else {
      localStorage.setItem('lk-month', __('Month'));
      this.setState({
        month: __('Month'),
      });
    }
    if (localStorage.getItem('lk-year')) {
      this.setState({
        year: localStorage.getItem('lk-year'),
      });
    }
  }

  hasErrors() {
    return (
      'name' in this.props.registration.errors ||
      'gender' in this.props.registration.errors
    );
  }

  setToken() {
    const token = localStorage.getItem('tokenWeb');
    console.warn('token: ', token);
    axios.interceptors.request.use(
      async (config) => {
        console.warn('Request', config.headers);
        const newConfig = {
          ...config,
          headers: {
            ...config.headers,
            turnstile: token,
          },
        };
        return newConfig;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  submitHandler = (event) => {
    event.preventDefault();
  };

  handleOnChangeName = (event) => {
    this.setState({name: event.target.value});
    localStorage.setItem('lk-name', event.target.value);
    this.props.registrationActions.clearErrors();
  };

  handlePressContinue = () => {
    window.myFun();

    setTimeout(() => {
      this.setToken();
      const email = localStorage.getItem('lk-email');
      const {name, day, month, year, gender} = this.state;
      this.props.registrationActions
        .registration({
          email,
          name,
          day,
          month: numberByMonth[month] + 1,
          year,
          gender,
        })
        .then(() => {
          this.props.history.replace('/photo', '/personal-info');
          this.props.registrationActions.setCurrentStep('3');
        })
        .catch((error) => {
          if (error.error && error.error === 'name_incorrect') {
            this.setState({mobileStep: NAME_STEP});
          }
        });
    }, 1000);
  };

  handlePressMobileContinue = () => {
    if (this.state.mobileStep === NAME_STEP) {
      this.setState({mobileStep: GENDER_STEP});
    }
    if (this.state.mobileStep === GENDER_STEP) {
      this.setState({mobileStep: BIRTHDAY_STEP});
    }
    if (this.state.mobileStep === BIRTHDAY_STEP) {
      this.handlePressContinue();
    }
  };

  handleChange = (e) => {
    const {value} = e.target;
    this.setState({
      gender: value,
    });
    localStorage.setItem('lk-gender', e.target.value);
    this.props.registrationActions.clearErrors();
  };

  render() {
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    const isMobileMode = this.props.screen.isMobileMode;
    let title = __('Answer a few questions');
    let subTitle = __('Other people want to know who they are chatting with');
    if (isMobileMode) {
      switch (this.state.mobileStep) {
        case NAME_STEP:
          title = __('Your first name');
          subTitle = __('Yes, that should be your real name');
          break;
        case GENDER_STEP:
          title = __('Your gender');
          subTitle = __('Other people want to know who they are chatting with');
          break;
        case BIRTHDAY_STEP:
          title = __('Your date of birth');
          subTitle = __(
            'Only your age will be visible to other users. The exact date of birth won’t be displayed',
          );
          break;
        default:
      }
    }
    const clsCard = isLoka()
      ? 'card_form step_form'
      : 'card_form step_form ts-step_form';
    return (
      <main
        className={showBlur ? 'blur' : null}
        style={
          this.props.screen.isMobileMode
            ? {
                paddingTop: 0,
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }
            : {}
        }>
        <div className="container">
          <div className="wrapper">
            <div
              className={clsCard}
              style={
                this.props.screen.isMobileMode
                  ? {boxShadow: 'none', padding: 0}
                  : {}
              }>
              <div className="left_wrap">
                <div className="top_wrap">
                  <h3 className="step_title mobile_step_title">{title}</h3>
                  <p className="step_text mobile_step_text">{subTitle}</p>
                </div>
              </div>
              <div className="right_wrap">
                <form onSubmit={this.submitHandler}>
                  <div
                    style={
                      !isMobileMode
                        ? {}
                        : this.state.mobileStep === NAME_STEP
                        ? {}
                        : {display: 'none'}
                    }>
                    <Input
                      invalidBottom={true}
                      invalid={
                        (this.hasErrors() && this.state.name.length < 2) ||
                        this.props.registration.errors.name
                      }
                      error={
                        !this.hasErrors()
                          ? null
                          : this.props.registration.errors.name
                      }
                      value={this.state.name}
                      placeholder={__('Enter your real name')}
                      type="text"
                      label={isMobileMode ? '' : __('Your first name')}
                      onChange={(event) => this.handleOnChangeName(event)}
                    />
                  </div>
                  <div
                    className="form_control"
                    style={
                      !isMobileMode
                        ? {}
                        : this.state.mobileStep === GENDER_STEP
                        ? {}
                        : {display: 'none'}
                    }>
                    {!isMobileMode ? (
                      <label className="form_label">{__('Your gender')}</label>
                    ) : null}
                    <div className="wrap_controls">
                      <p className="input_radio">
                        <label>
                          <input
                            className="with-gap"
                            name="gender"
                            type="radio"
                            value="M"
                            checked={this.state.gender === 'M'}
                            onChange={this.handleChange}
                          />
                          <span>{__('Male')}</span>
                        </label>
                      </p>
                      <p className="input_radio">
                        <label>
                          <input
                            className="with-gap"
                            name="gender"
                            type="radio"
                            value="F"
                            checked={this.state.gender === 'F'}
                            onChange={this.handleChange}
                          />
                          <span>{__('Female')}</span>
                        </label>
                      </p>
                    </div>
                    {!this.hasErrors() ? null : (
                      <div className="validation_label validation_label_gender">
                        {this.props.registration.errors.gender}
                      </div>
                    )}
                  </div>
                  <div
                    className="form_control"
                    style={
                      !isMobileMode
                        ? {}
                        : this.state.mobileStep === BIRTHDAY_STEP
                        ? {}
                        : {display: 'none'}
                    }>
                    {!isMobileMode ? (
                      <label className="form_label">
                        {__('Your date of birth')}
                      </label>
                    ) : null}

                    <div className="wrap_controls">
                      <DayPicker
                        defaultValue={__('Day')}
                        year={this.state.year}
                        month={numberByMonth[this.state.month]}
                        endYearGiven
                        required={true}
                        value={this.state.day}
                        onClick={(day) => {
                          this.setState({day});
                          localStorage.setItem('lk-day', day);
                          this.props.registrationActions.clearErrors();
                        }}
                        id={'day'}
                        name={'day'}
                        classes={'form_input dropdown_value'}
                        optionClasses={'option dropdown_list_item'}
                      />
                      <MonthPicker
                        defaultValue={__('Month')}
                        endYearGiven
                        year={this.state.year}
                        required={true}
                        value={__(`${this.state.month}`)}
                        onClick={(month) => {
                          let mm = __reverse(month);
                          this.setState({month: mm});
                          localStorage.setItem('lk-month', mm);
                          this.props.registrationActions.clearErrors();
                        }}
                        id={'month'}
                        name={'month'}
                        classes={'form_input dropdown_value'}
                        optionClasses={'option dropdown_list_item'}
                      />
                      <YearPicker
                        defaultValue={__('Year')}
                        start={new Date(new Date().getFullYear()) - 99}
                        end={new Date(new Date().getFullYear()) - 18}
                        reverse
                        required={true}
                        value={this.state.year}
                        onClick={(year) => {
                          this.setState({year});
                          localStorage.setItem('lk-year', year);
                          this.props.registrationActions.clearErrors();
                        }}
                        id={'year'}
                        name={'year'}
                        classes={'form_input dropdown_value'}
                        optionClasses={'option dropdown_list_item'}
                      />
                    </div>
                    {/*<p className="form_hint">You must be at least 18 years old to join our*/}
                    {/*    community</p>*/}
                  </div>
                  {!isMobileMode ? (
                    <Button
                      onClick={this.handlePressContinue}
                      disabled={
                        (!(this.hasErrors() && this.state.name.length < 2) &&
                          !(this.state.gender.length > 0 && this.hasErrors()) &&
                          !(
                            this.state.day > 0 &&
                            this.state.month !== 'Month' &&
                            this.state.year > 0
                          ) &&
                          !this.hasErrors()) ||
                        this.props.registration.isFetching
                      }>
                      {this.props.registration.isFetching
                        ? __('Loading...')
                        : __('Continue')}
                    </Button>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
        {isMobileMode ? (
          <div style={{paddingLeft: 15, paddingRight: 15}}>
            <Button
              onClick={this.handlePressMobileContinue}
              disabled={
                (this.state.mobileStep === NAME_STEP &&
                  !(this.state.name.length > 1)) ||
                (this.state.mobileStep === GENDER_STEP &&
                  !(this.state.gender.length > 0)) ||
                (this.state.mobileStep === BIRTHDAY_STEP &&
                  !(
                    this.state.day > 0 &&
                    this.state.month !== 'Month' &&
                    this.state.year > 0
                  )) ||
                this.props.registration.isFetching
              }>
              {this.props.registration.isFetching
                ? __('Loading...')
                : __('Continue')}
            </Button>
          </div>
        ) : null}
      </main>
    );
  }
}

export default connect(
  (state) => ({
    registration: state.registration,
    events: state.events,
    screen: state.screen,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
  }),
)(SetPersonalInfo);
