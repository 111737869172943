import axios from 'axios';
import {
  EVENTS_REQUEST,
  EVENTS_SUCCESS,
  EVENTS_FAIL,
  EVENTS_REQUEST_ACCEPT_SUCCESS,
  EVENTS_OPEN_MODAL,
  EVENTS_CLOSE_MODAL,
  EVENTS_SET_READ,
  EVENTS_READ_ALL,
  NOTIFICATION_SHOW,
  SERVER_ERROR,
  DAILY_COINS,
} from '../constants';

export function setRead(eventId) {
  return (dispatch) => {
    dispatch({
      type: EVENTS_SET_READ,
      payload: eventId,
    });
  };
}

export function read() {
  return (dispatch) => {
    dispatch({
      type: EVENTS_READ_ALL,
    });
    axios
      .post('/events/read')
      .then((result) => {
      })
      .catch(() => {});
  };
}

export function openModal() {
  return (dispatch) => {
    dispatch({
      type: EVENTS_OPEN_MODAL,
    });
  };
}

export function closeModal() {
  return (dispatch) => {
    dispatch({
      type: EVENTS_CLOSE_MODAL,
    });
  };
}

export function load(offset = null) {
  return (dispatch) => {
    dispatch({
      type: EVENTS_REQUEST,
    });

    axios
      .get(`/events?offset=${offset}`)
      .then((result) => {
        dispatch({
          type: EVENTS_SUCCESS,
          payload: result.data,
          offset,
        });
      })
      .catch(() => {
        dispatch({
          type: EVENTS_FAIL,
        });
      });
  };
}

export function acceptRequest(eventId, userId) {
  return (dispatch) => {
    axios
      .post('/events/accept-request', {user_id: userId})
      .then(() => {
        dispatch({
          type: EVENTS_REQUEST_ACCEPT_SUCCESS,
          payload: eventId,
        });
      })
      .catch((err) => {
        const error = err.response.data;
        dispatch({
          type: NOTIFICATION_SHOW,
          payload: {
            type: 'error',
            text: error.error_text,
          },
        });
      });
  };
}

export const showServerError = (isError) => {
  return (dispatch) => {
    dispatch({
      type: SERVER_ERROR,
      payload: isError,
    });
  };
}

export const showDailyCoins = (show) => {
  return (dispatch) => {
    dispatch({
      type: DAILY_COINS,
      payload: show,
    });
  };
}

