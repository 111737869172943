import axios from 'axios';
// import isEmpty from 'lodash/isEmpty';

import {
  MY_PROFILE_REQUEST,
  MY_PROFILE_SUCCESS,
  MY_PROFILE_FAIL,
  PHOTO_MAKE_MAIN_REQUEST,
  PHOTO_MAKE_MAIN_SUCCESS,
  PHOTO_MAKE_MAIN_FAIL,
  PHOTO_DELETE_REQUEST,
  PHOTO_DELETE_SUCCESS,
  PHOTO_DELETE_FAIL,
  MY_PROFILE_SAVE_REQUEST,
  MY_PROFILE_SAVE_SUCCESS,
  MY_PROFILE_SAVE_FAIL,
  MY_PROFILE_SAVE_ERRORS_CLEAR,
  // TRIP_SAVE_REQUEST,
  // TRIP_SAVE_SUCCESS,
  // TRIP_SAVE_FAIL,
  // TRIP_DELETE_REQUEST,
  // TRIP_DELETE_SUCCESS,
  // TRIP_DELETE_FAIL,
  MY_PROFILE_RESET_UPLOADE_FLAG,
  PHOTO_MAKE_PRIVATE_REQUEST,
  PHOTO_MAKE_PRIVATE_SUCCESS,
  PHOTO_MAKE_PRIVATE_FAIL,
  PHOTO_MAKE_PUBLIC_REQUEST,
  PHOTO_MAKE_PUBLIC_SUCCESS,
  PHOTO_MAKE_PUBLIC_FAIL,
  NOTIFICATION_SHOW,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL,
} from '../constants';
import {
  ACCOUNT_DELETE_FAIL,
  ACCOUNT_DELETE_REQUEST,
  ACCOUNT_DELETE_SUCCESS
} from '../../store/constants';
import { logout } from '../../store/actions/auth/logoutActions';

// import i18n from '../utils/i18n';

export function loadMyProfile() {
  return (dispatch) => {
    dispatch({
      type: MY_PROFILE_REQUEST,
    });

    return axios
      .get('/user-info')
      .then((result) => {
        dispatch({
          type: MY_PROFILE_SUCCESS,
          payload: result.data.user_info,
        });
      })
      .catch(() => {
        dispatch({
          type: MY_PROFILE_FAIL,
        });
      });
  };
}

export function save(data) {
  return (dispatch) => {
    dispatch({
      type: MY_PROFILE_SAVE_REQUEST,
    });

    const errors = {};

    return axios
      .post('/set-user-data', data)
      .then((response) => {
        dispatch({
          type: MY_PROFILE_SAVE_SUCCESS,
          payload: response.data.result.user_info.user_info,
        });
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;

          switch (error.error) {
            case 'incorrect_date':
              errors.birthdate = 'Invalid date';
              break;
            case 'incorrect_name':
              errors.name = 'Invalid';
              break;
            default:
          }

          dispatch({
            type: MY_PROFILE_SAVE_FAIL,
            payload: errors,
          });
        }
      });
  };
}


export function makePrivatePhoto(photo_id) {
  return (dispatch) => {
    dispatch({
      type: PHOTO_MAKE_PRIVATE_REQUEST,
    });

    return axios
      .post('/photo/make-private', {photo_id})
      .then((result) => {
        dispatch({
          type: PHOTO_MAKE_PRIVATE_SUCCESS,
          payload: photo_id
        });
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          console.error(error)
          dispatch({
            type: PHOTO_MAKE_PRIVATE_FAIL,
          });
        }
      });
  };
}

export function makePublicPhoto(photo_id) {
  return (dispatch) => {
    dispatch({
      type: PHOTO_MAKE_PUBLIC_REQUEST,
    });

    return axios
      .post('/photo/make-public', {photo_id})
      .then((result) => {
        dispatch({
          type: PHOTO_MAKE_PUBLIC_SUCCESS,
          payload: photo_id
        });
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          console.error(error)
          dispatch({
            type: PHOTO_MAKE_PUBLIC_FAIL,
          });
        }
      });
  };
}

export function makeMain(id) {
  return (dispatch) => {
    dispatch({
      type: PHOTO_MAKE_MAIN_REQUEST,
    });

    return axios
      .post('/photo/make-main', {id})
      .then((result) => {
        dispatch({
          type: PHOTO_MAKE_MAIN_SUCCESS,
          payload: result.data.user_info,
        });
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          dispatch({
            type: PHOTO_MAKE_MAIN_FAIL,
          });
          dispatch({
            type: NOTIFICATION_SHOW,
            payload: {
              type: 'error',
              text: error.error_text,
            },
          });
        }
      });
  };
}

export function deletePhoto(id) {
  return (dispatch) => {
    dispatch({
      type: PHOTO_DELETE_REQUEST,
    });

    return axios
      .post('/photo/delete', {id})
      .then((result) => {
        dispatch({
          type: PHOTO_DELETE_SUCCESS,
          payload: result.data.user_info,
        });
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          dispatch({
            type: PHOTO_DELETE_FAIL,
          });
          dispatch({
            type: NOTIFICATION_SHOW,
            payload: {
              type: 'error',
              text: error.error_text,
            },
          });
        }
      });
  };
}

export function deleteAccount() {
  return (dispatch) => {
    dispatch({
      type: ACCOUNT_DELETE_REQUEST,
    });

    return axios
      .get('/users/delete')
      .then((result) => {
        dispatch({
          type: ACCOUNT_DELETE_SUCCESS,
        });
        if (window.FB) { window.FB.logout(); }
        dispatch(logout());
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          dispatch({
            type: ACCOUNT_DELETE_FAIL,
          });
          dispatch({
            type: NOTIFICATION_SHOW,
            payload: {
              type: 'error',
              text: error.error_text,
            },
          });
        }
      });
  };
}

export function cancelSubscription() {
  return (dispatch) => {
    dispatch({
      type: CANCEL_SUBSCRIPTION_REQUEST,
    });

    return axios
      .post('/billing/stripe-cancel-subscription')
      .then((result) => {
        dispatch({
          type: CANCEL_SUBSCRIPTION_SUCCESS,
        });
        dispatch(loadMyProfile())
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: CANCEL_SUBSCRIPTION_FAIL,
            payload: err.response.data.error
          });
        }
      });
  };
}

export function resetUploadedFlag() {
  return (dispatch) => {
    dispatch({
      type: MY_PROFILE_RESET_UPLOADE_FLAG,
    });
  };
}

export function clearErrors() {
  return (dispatch) => {
    dispatch({
      type: MY_PROFILE_SAVE_ERRORS_CLEAR,
    });
  };
}
