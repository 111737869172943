import axios from 'axios';
import {
  REGISTRATION_ERRORS_CLEAR,
  REGISTRATION_SET_EMAIL_SUCCESS,
  REGISTRATION_REQUEST_CHECK_EMAIL,
  REGISTRATION_SET_EMAIL_FAIL,
  REGISTRATION_REQUEST,
  REGISTRATION_FAIL,
  REGISTRATION_SUCCESS,
  LOGIN_SUCCESS,
  REGISTRATION_STARTS,
  REGISTRATION_DONE,
  REGISTRATION_SET_STEP,
} from '../../constants';
import isEmpty from 'lodash/isEmpty';
import isEmail from 'is-email';
import { config, setShowInstallApp } from '../../actions/sessionActions';
import __ from '../../../utils/translate'
import { deviceLocale } from '../../../i18n';

export const start = () => {
  return (dispatch) => {
    dispatch({
      type: REGISTRATION_STARTS,
    });
  };
};

export const done = () => {
  return (dispatch) => {
    dispatch({
      type: REGISTRATION_DONE,
    });
    dispatch(setShowInstallApp(true))
  };
};

export const setCurrentStep = (step) => {
  return (dispatch) => {
    dispatch({
      type: REGISTRATION_SET_STEP,
      payload: step,
    });
  };
};

export const setEmail = (email) => {
  const errors = {};
  email = email.trim();

  if (email === '') {
    errors.email = __('Please fill the email field');
  } else if (!isEmail(email)) {
    errors.email = __('Please fill the email field');
  }

  return (dispatch) => {
    if (!isEmpty(errors)) {
      dispatch({
        type: REGISTRATION_SET_EMAIL_FAIL,
        payload: errors,
      });
      return Promise.reject(new Error('fail'));
    }

    dispatch({
      type: REGISTRATION_REQUEST_CHECK_EMAIL,
    });
    return axios
      .post('/users/validate-email', {email: email})
      .then((response) => {
        const {result} = response.data;
        dispatch({
          type: REGISTRATION_SET_EMAIL_SUCCESS,
          payload: email,
        });
        localStorage.setItem('lk-email', email);
        return Promise.resolve();
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;

          errors.email = __('Internal error. Please try later.');

          if (error.error === 'email_incorrect') {
            errors.email = __('Incorrect email');
          } else if (error.error === 'email_already_registered') {
            errors.email = __('Email already registered');
          }
        }
        dispatch({
          type: REGISTRATION_SET_EMAIL_FAIL,
          payload: errors,
        });

        return Promise.reject(new Error('fail'));
      });
  };
};

export const setEmailSocial = (email) => {
  return (dispatch) => { 
    return axios
      .post('/registration/finish-registration-without-email', {email: email})
      .then((response) => {
      });
  };
};

export const registration = (data) => {
  const errors = {};
  
  const name = data.name.trim();
  const gender = data.gender;
  const day = data.day;
  const month = +data.month;
  const year = data.year;

  if (name === '') {
    errors.name = __('Please fill the email field');
  }
  if (name.length < 2) {
    errors.name = __('Name is too short');
  }

  if (gender === '') {
    errors.gender = __('Choose your gender');
  }

  data.name = name;
  data.day = day;
  data.month = month;
  data.year = year;
  data.gender = gender;

  return (dispatch) => {
    if (!isEmpty(errors)) {
      dispatch({
        type: REGISTRATION_FAIL,
        payload: errors,
      });

      return Promise.reject(new Error('fail'));
    }

    dispatch({
      type: REGISTRATION_REQUEST,
    });

    data.lang = deviceLocale;

    return axios
      .post('/registration/new', data)
      .then((response) => {
        const {result} = response.data;
        dispatch({
          type: REGISTRATION_SUCCESS,
          payload: result,
        });
        dispatch({
          type: LOGIN_SUCCESS,
          payload: result,
        });
        if (!localStorage.getItem('lk-token')) {
          localStorage.setItem('lk-token', result.token);
        }
        if (!localStorage.getItem('lk-secret')) {
          localStorage.setItem('lk-secret', result.secret);
        }
        localStorage.setItem('lk-uid', result.user_data.user_id);
        localStorage.removeItem('lk-email');
        localStorage.removeItem('lk-name');
        localStorage.removeItem('lk-gender');
        localStorage.removeItem('lk-day');
        localStorage.removeItem('lk-month');
        localStorage.removeItem('lk-year');

        config(dispatch);

        return Promise.resolve();
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;

          errors.email = __('Internal error. Please try later.');

          if (error.error === 'email_incorrect') {
            errors.email = __('Incorrect email');
          } else if (error.error === 'email_already_registered') {
            errors.email = __('Email already registered');
          } else if (error.error === 'name_incorrect') {
            errors.name = __('Incorrect name');
          }

          dispatch({
            type: REGISTRATION_FAIL,
            payload: errors,
          });

          return Promise.reject(error);
        }
      });
  };
};

export const clearErrors = () => {
  return (dispatch) => {
    dispatch({
      type: REGISTRATION_ERRORS_CLEAR,
    });
  };
};
