import {LOAD} from 'redux-storage';
import {
  LIVE_CHAT_NEW_MESSAGE_RECEIVED,
  LIVE_CHAT_FLUSH,
  LIVE_CHAT_NEW_USER_JOINED,
  LIVE_CHAT_HEART_RECEIVED,
  LIVE_CHAT_SEND_MESSAGE_SUCCESS,
  LIVE_CHAT_SEND_HEART_SUCCESS,
  LIVE_CHAT_JOIN_USER_REQUEST,
  LIVE_CHAT_JOIN_USER_SUCCESS,
  LIVE_CHAT_JOIN_USER_FAIL,
  LIVE_CHAT_START_REQUEST,
  LIVE_CHAT_START_SUCCESS,
  LIVE_CHAT_START_FAIL,
  LIVE_CHAT_STOP_REQUEST,
  LIVE_CHAT_STOP_SUCCESS,
  LIVE_CHAT_STOP_FAIL,
  LIVE_CHAT_SET_CHAT_DATA,
  LIVE_CHAT_CLEAR_CHAT,
  LIVE_CHAT_TRANSLATE_MESSAGE_REQUEST,
  LIVE_CHAT_TRANSLATE_MESSAGE_SUCCESS,
  LIVE_CHAT_TRANSLATE_MESSAGE_FAIL,
  LIVE_CHAT_SEND_MESSAGE_FAIL,
} from '../constants';

const initialState = {
  messages: [],
  streamId: null,
  totalUsersCount: 0,
  hasError: false,
  errorName: null,
  isFetching: false,
};

export default function liveChatReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
    case LIVE_CHAT_FLUSH:
      return initialState;

    case LIVE_CHAT_CLEAR_CHAT:
      return {
        ...initialState,
      };

    case LIVE_CHAT_SET_CHAT_DATA:
      return {
        messages: action.payload.messages,
        streamId: action.payload.streamId,
        totalUsersCount: action.payload.totalUsersCount,
      };

    case LIVE_CHAT_NEW_MESSAGE_RECEIVED:
      return {
        ...state,
        messages: [{type: 'message', ...action.payload}, ...state.messages],
      };
    case LIVE_CHAT_NEW_USER_JOINED:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case LIVE_CHAT_HEART_RECEIVED:
      return {
        ...state,
        messages: [{type: 'heart', ...action.payload}, ...state.messages],
      };

    case LIVE_CHAT_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            type: 'message',
            stream_id: action.payload.streamId,
            message: action.payload.message,
            user: action.payload.user,
            created_at: action.payload.createdAt,
          },
        ],
      };
    case LIVE_CHAT_SEND_MESSAGE_FAIL:
      const messages = state.messages.filter(
        (item) => item.created_at !== action.payload.createdAt,
      );
      return {
        ...state,
        messages: messages,
      };
    case LIVE_CHAT_SEND_HEART_SUCCESS:
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            type: 'heart',
            stream_id: action.payload.streamId,
            count: action.payload.count,
            user: action.payload.user,
            created_at: action.payload.createdAt,
          },
        ],
      };

    case LIVE_CHAT_JOIN_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LIVE_CHAT_JOIN_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        messages: action.payload.messages,
        streamId: action.payload.streamId,
        totalUsersCount: action.payload.totalUsersCount,
      };

    case LIVE_CHAT_JOIN_USER_FAIL:
      return {
        isFetching: false,
      };

    case LIVE_CHAT_START_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LIVE_CHAT_START_SUCCESS:
      return {
        ...state,
        isFetching: false,
        messages: [],
        streamId: action.payload,
        totalUsersCount: 0,
      };

    case LIVE_CHAT_START_FAIL:
      return {
        isFetching: false,
      };

    case LIVE_CHAT_STOP_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LIVE_CHAT_STOP_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case LIVE_CHAT_STOP_FAIL:
      return {
        ...state,
        isFetching: false,
      };
    case LIVE_CHAT_TRANSLATE_MESSAGE_REQUEST:
      return {
        ...state,
        messages: state.messages.map((item) => {
          if (item.id === action.payload.id) {
            item.translate_load = true;
          }
          return item;
        }),
      };
    case LIVE_CHAT_TRANSLATE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.map((item) => {
          if (item.id === action.payload.id) {
            item.translate_load = false;
            item.translate = action.payload.data.translate;
          }
          return item;
        }),
      };
    case LIVE_CHAT_TRANSLATE_MESSAGE_FAIL:
      return {
        ...state,
        messages: state.messages.map((item) => {
          if (item.id === action.payload.id) {
            item.translate_load = false;
          }
          return item;
        }),
      };

    default:
      return state;
  }
}
