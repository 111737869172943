import React, {Component} from 'react';
import {connect} from 'react-redux';
import Footer from '../../Components/Footer';
import {isTheyStream} from '../../apps-config';

class Terms extends Component {
  render() {
    const clsMain = [];
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsMain.push('blur');
    }
    if (isTheyStream()) {
      clsMain.push('ts-main');
    }
    return (
      <>
        <main id="main main-info" className={clsMain.join(' ')}>
          <div className="container">
            <h1 className="main__title">Effective as from 01 June 2020</h1>
            <div>
              These Terms of Use & Service (the “Terms” or the “Agreement”) are
              an agreement between You as a user of the website
              https://match-meet.com/ (“Website”) or mobile application Match &
              Meet (“Application”) and Galocor OÜ ("Match & Meet", "Company", or
              "us").
              <br />
              <br />
              <br />
              These Terms govern your access to and use of the Website or
              Application and the associated services offered through this
              Website or Application, as applicable (together with the Website
              and Application, the “Service” or the “Services”).
              <br />
              <br />
              <br />
              Our Privacy Policy, Cookie Policy, Payment Security and Refund
              Policy and Disclosures & Disclaimers are incorporated into this
              Agreement by reference, as well as any other policies, which may
              be adopted or introduced by us from time to time.
              <br />
              <br />
              <br />
              PLEASE NOTE: THIS AGREEMENT CONTAINS A BINDING ARBITRATION
              PROVISION IN SECTION 15 THAT AFFECTS YOUR RIGHTS UNDER THIS
              AGREEMENT WITH RESPECT TO ALL SERVICE(S). THE ARBITRATION
              PROVISION REQUIRES THAT DISPUTES BE RESOLVED IN ARBITRATION ON AN
              INDIVIDUAL BASIS. IN ARBITRATION, THERE IS NO JUDGE OR JURY AND
              THERE IS LESS DISCOVERY AND APPELLATE REVIEW THAN IN COURT.
              <br />
              <br />
              FURTHERMORE, THIS AGREEMENT CONTAINS DISCLAIMERS OF WARRANTIES,
              LIMITATIONS OF LIABILITY, AND A CLASS ACTION WAIVER.
              <br />
              <br />
              We may change these Terms on this page of the Website. We may
              notify you specifically about some critical changes, but not
              obliged to do so in every case. Use of the Service after any
              changes are made means that you accept such changes.
            </div>
            <br />
            <br />
            <br />
            <br />
            <div>1. MEMBERSHIP AT THE WEBSITE OR APPLICATION</div>
            <br />
            <br />
            <br />
            <div>
              a. Eligibility
              <br />
              <br />
              Before you create an account on Match & Meet, make sure you are
              eligible to use the Services.
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  You must be at least 18 years old to use the Website or to
                  register for the Services (or the reciprocal age of majority
                  in your state or jurisdiction);
                </li>
                <li>
                  You must not be restricted by law from registration/use of
                  similar services and/or products.
                </li>
              </ul>
              <br />
              <br />
              b. Registration
              <br />
              <br />
              You may register the account on the Website and become a Member of
              the Service at no cost.
              <br />
              <br />
              To become a Member, you need to create an account by providing a
              valid email address, name, password and/or other information as
              prompted by the registration form or as required by applicable
              law.
              <br />
              <br />
              We may also allow you to register by using your social network
              credentials.
              <br />
              <br />
              You hereby expressly consent to our use of your profile on the
              Website to populate registration and profile information on the
              other websites owned by the Company or its affiliates (“Affiliate
              Websites”).
              <br />
              <br />
              c. Requirements to Profiles
              <br />
              <br />
              When registering, you are prohibited from selecting or using as
              your name:
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  containing the name of another person with the intent to
                  impersonate that person;
                </li>
                <li>
                  a name that is subject to any rights of a person other than
                  you without appropriate authorization;
                </li>
                <li>
                  or a name that is otherwise offensive, vulgar, or obscene.
                </li>
              </ul>
              <br />
              <br />
              Please note that we prohibit including in any Section of your
              Member profile any telephone numbers, street addresses, email
              addresses, links to pages, profiles and other content from social
              networks, other personal contact information, your last name or
              any URLs.
              <br />
              <br />
              d. Non-Commercial Use of Service
              <br />
              <br />
              Services are for personal use only and may not be used in
              connection with any commercial endeavors. Organizations,
              companies, and/or businesses may not use the Service for any
              purpose.
              <br />
              <br />
              f. No Third-Party Beneficiaries
              <br />
              <br />
              You agree that, except as otherwise provided in these Terms, there
              shall be no third party beneficiaries to this Agreement.
            </div>
            <br />
            <br />
            <br />
            <div>2. Match & Meet PAID SERVICE TERMS AND CONDITIONS</div>
            <br />
            <br />
            <br />
            These Match & Meet Paid Service Terms and Conditions constitute an
            integral part of the Match & Meet Terms of Use and supplement them
            as provided below.
            <br />
            <br />
            Match & Meet app is free to download. Users can enjoy the app using
            basic features free of charge. We also offer an optional
            subscription package (Match & Meet Premium) and consumable premium
            features (such as coins).
            <br />
            <br />
            <div>
              a. Match & Meet Premium
              <br />
              <br />
              Match & Meet Premium includes a range of premium features (daily
              coins pack, weekly coins pack, unlimited number of new contacts)
              and helps the user to get the most out of the app.
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  The cost of the optional subscription for Match & Meet Premium
                  can be found in your iTunes or Google Play market. Payment
                  will be charged to your iTunes or Google Play account at
                  confirmation of purchase.
                </li>
                <li>
                  All prices are subject to change without notice at Match &
                  Meet's sole discretion.
                </li>
                <li>
                  If you pay for a subscription by credit or debit card (or
                  other payment method identified as involving an automatically
                  renewing subscription) and you do not cancel your subscription
                  prior to the end of the subscription term, your subscription
                  will be automatically extended at the end of each term for
                  successive renewal periods of the same duration as the
                  subscription term originally selected (for example, unless you
                  cancel, a one-month subscription will automatically renew on a
                  monthly basis and a six-month subscription will automatically
                  renew on a six-month basis).
                </li>
                <li>
                  Your account will be charged for renewal within 24 hours prior
                  to the end of the current subscription period.
                </li>
                <li>
                  You can manage your subscription and switch off auto-renewal
                  by accessing your Account Settings after purchase in
                  Third-Party Account (such as your Apple iTunes account or your
                  Google Play account).
                </li>
                <li>
                  Any unused portion of a free trial period, if offered, will be
                  forfeited if you purchase a Match & Meet subscription or
                  cancel the trial.
                </li>
                <li>
                  You can change or cancel your subscription at any time. If you
                  purchased a subscription through a third-party account (iTunes
                  or Google Play), you will need to change or cancel your
                  subscription through that third party and in accordance with
                  that third party's terms and conditions.
                </li>
              </ul>
              <br />
              <br />
              b. Free Trial
              <br />
              <br />
              Before making a purchase decision, the user may also try out the
              premium features by activating free trial subscription. Free trial
              subscribers receive same benefits as Match & Meet Premium
              subscribers, including all consumable premium features.
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  By subscribing to free Match & Meet trial, a user
                  automatically agrees to subscribe for Match & Meet
                  subscription at the end of the trial period, if they do not
                  cancel the subscription at least 24 hours prior to the end of
                  the Match & Meet trial.
                </li>
                <li>
                  If you subscribe to free trial and do not cancel your
                  subscription at least 24 hours prior to the end of the free
                  trial period, you will be automatically charged for 1 month of
                  Match & Meet subscription (type of package is indicated on the
                  screen offering a trial). Your account will be charged for
                  renewal within the 24 hours prior to the end of the free trial
                  period.
                </li>
                <li>
                  You can cancel free trial at any time in your iTunes or Google
                  Play account and in accordance with iTunes or Google Play
                  terms and conditions.
                </li>
                <li>
                  Any unused portion of consumable features will not be
                  forfeited at the end of the free trial period.
                </li>
                <li>
                  Any unused portion of a free trial period, if offered, will be
                  forfeited if you purchase Match & Meet subscription or cancel
                  Match & Meet trial.
                </li>
                <li>
                  You may participate in the free trial only once. Any future
                  subscription for a trial will result in charge for
                  subscription right after its confirmation.
                </li>
              </ul>
              <br />
              <br />
              c. Premium Features
              <br />
              <br />
              Following the purchase of a subscription or activating the free
              trial subscription, Match & Meet Premium users receive a set of
              consumable premium features. If users consume a given amount of
              features before the end of the subscription period, they may
              purchase more consumable premium features.
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>Consumable premium features are Coins.</li>
                <li>
                  Consumable premium features can be purchased outside of Match
                  & Meet subscription or free trial subscription
                </li>
                <li>
                  Costs and types of consumable premium features are subject to
                  change without notice and shall be payable at actual rates
                  which you will get to know when checking out
                </li>
                <li>
                  Consumable premium features are added to the consumable
                  features included in the Match & Meet subscription or free
                  trial subscription.
                </li>
                <li>
                  Match & Meet Premium subscription has a fixed number of
                  premium features included. The number of consumable premium
                  features renews automatically when the subscription is
                  extended without additional payment.
                </li>
                <li>
                  Any unused portion of consumable premium features will not be
                  forfeited at the end of the subscription period or free trial
                  subscription.
                </li>
                <li>
                  All fees and charges are nonrefundable and there are no
                  refunds or coins for any partially used Paid Service
                  (including partially used subscription periods) except (i) as
                  expressly set forth in Terms of Use, (ii) as otherwise
                  required by applicable law and (iii) at Match & Meet's sole
                  and absolute discretion
                </li>
              </ul>
              <br />
              <br />
              By subscribing to Match & Meet free trial or subscription, the
              user automatically agrees to Match & Meet Subscription Terms and
              Terms of Use.
              <br />
              <br />
              <br />
              d. Coins
              <br />
              <br />
              The Service which Match & Meet will offer for purchase may also
              comprise coins.
              <br />
              <br />
              Being a piece of code, coins are intellectual property of Match &
              Meet and are distributed by us under license in order to improve
              your user experience (the “Licensed Content”). Match & Meet is a
              sole proprietor of such intellectual property and intellectual
              property rights to such intellectual property. By purchasing
              coins, the Member acquires a limited, revocable, non-transferable
              and non-sublicensable worldwide license to access and use the
              coins as a part of the Service in order to communicate with other
              Members in a more effective way. Any other use of the Licensed
              Content is strictly prohibited.
              <br />
              <br />
              The Licensed Content has no cash equivalent, monetary or other
              value, it does not constitute currency or property of any type.
              The Licensed Content cannot and must not be sold or otherwise
              transferred to third parties, including other users, including, to
              the Members, cannot be used outside the Match & Meet Service, and
              cannot be exchanged for cash, any goods or services or any other
              thing which has any value for any person. Neither the Members nor
              other users have proprietary, ownership, or monetary interest to
              the Licensed Content, which remains Match & Meet intellectual
              property. Users may not obtain a refund for the Licensed Content
              due to its online digital nature. The Licensed Content is fully
              consumed by you right after you buy access to it and it is shown
              to you through the Service. You must not purchase, sell, barter,
              or trade any the Licensed Content, or offer to purchase, sell, or
              trade any Licensed Content (including for money or any other
              consideration or items of value whether inside or outside of the
              Match & Meet Service). Match & Meet and users recognize that any
              such attempted transaction will be null and void.
              <br />
              <br />
              As to avoid any doubts, the Licensed Content does not have an
              equivalent value in real currency and do not act as a substitute
              for real currency. Neither Match & Meet nor any other person or
              entity has any obligation to exchange the Licensed Content for
              anything of value, including, but not limited to, real currency.
              <br />
              <br />
              Match & Meet reserves the right to modify the Licensed Content at
              its sole discretion. Such amendments may impact on the Licensed
              Content making it more or less common, desirable, effective, or
              functional. Match & Meet is entitled to terminate or strike off a
              license and immediately restrict the Licensed Content to be used
              for any or no reason, in its sole discretion, and without advance
              notice or liability.
              <br />
              <br />
              You are prohibited to execute of or solicit in: (a) exchange the
              Licensed Content for money, donation or any other value; (b)
              request money, donation or any other value for the Licensed
              Content; (c) providing of or request any items, including without
              limitation, merchandise, services, including without limitation,
              commercial dating, favors, private conversation, etc. that any
              person can associate with any monetary value in exchange of the
              Licensed Content.
              <br />
              <br />
              When your Match & Meet profile is deleted (whether by you or by
              Match & Meet), blocked or you do not use the Service for more than
              6 months, you will be deprived of the license. You have no right
              to request any reimbursement for the period when you do not use
              the Licensed Content.
              <br />
              <br />
              All personal data is handled under the terms and conditions of
              Match & Meet’s privacy policy. More details can be found here:
              <a href="https://match-meet.com/policy">
                &nbsp; https://match-meet.com/policy
              </a>
              <br />
              <br />
              Additional information about terms of use of the application,
              including detailed terms of subscription, can be found here:
              <a href="https://match-meet.com/terms">
                &nbsp; https://match-meet.com/terms
              </a>
              <br />
              <br />
              If you have any questions regarding subscription, please, contact
              our customer support team at{' '}
              <a href="https://match-meet.com/policy">
                https://match-meet.com/policy
              </a>
              <br />
              <br />
            </div>
            <br />
            <br />
            <br />
            <div>
              3. REPRESENTATIONS AND WARRANTIES FROM AND REQUIREMENTS TO MEMBERS
            </div>
            <br />
            <br />
            <br />
            <div>
              a. Representations and Warranties from Members
              <br />
              <br />
              By using the Service, you represent and warrant that:
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>you have never been convicted of a felony;</li>
                <li>
                  you are not required to register as a sex offender with any
                  government entity;
                </li>
                <li>
                  all information you include in your Member profile is
                  accurate, current, and complete;
                </li>
                <li>
                  you will ensure that this information is kept accurate and
                  up-to-date at all times;
                </li>
                <li>
                  you will notify us immediately of any change in your contact
                  details, including your email address;
                </li>
                <li>
                  your use of the Service is in accordance with any and all
                  applicable laws and regulations;
                </li>
                <li>
                  you have the right, authority, and capacity to enter into this
                  Agreement and abide by all of the terms and conditions of this
                  Agreement;
                </li>
                <li>
                  you will not be engaged into any kind of activities that are
                  prohibited.
                </li>
              </ul>
              <br />
              <br />
              b. Prohibited Activities of Members
              <br />
              <br />
              This is a list of activities that we prohibit on the Website and
              the Application:
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>You shall not create duplicate profiles;</li>
                <li>
                  You shall not transmit any chain letters or junk email to
                  other Members;
                </li>
                <li>
                  You shall not "stalk" or otherwise harass any person on the
                  Service;
                </li>
                <li>
                  You shall not use any robot, spider, site search/retrieval
                  application, or other manual or automatic device or process to
                  retrieve, index, "data mine", or in any way reproduce or
                  circumvent the navigational structure or presentation of the
                  Service or its contents;
                </li>
                <li>
                  You shall not post, distribute, or reproduce in any way any
                  copyrighted material, trademarks, or other proprietary
                  information without obtaining the prior consent of the owner
                  of such proprietary rights;
                </li>
                <li>
                  You shall not remove any copyright, trademark, or other
                  proprietary rights notices contained on the Service;
                </li>
                <li>
                  You shall not interfere with or disrupt the Services or the
                  Website or the servers or networks connected to the Services
                  or the Website;
                </li>
                <li>
                  You shall not post, email, or otherwise transmit any material
                  that contains software viruses or any other computer code,
                  files, or programs designed to interrupt, destroy, or limit
                  the functionality of any computer software or hardware or
                  telecommunications equipment;
                </li>
                <li>
                  You shall not forge headers or otherwise manipulate
                  identifiers in order to disguise the origin of any information
                  transmitted through the Service;
                </li>
                <li>
                  You shall not make illegal and/or unauthorized uses of the
                  Services, including, but not limited to, collecting email
                  addresses of other Members posted on the Service by any means
                  for the purpose of sending unsolicited emails and/or
                  unauthorized framing of, or linking to, the Service;
                </li>
                <li>
                  You shall not "frame" or "mirror" any part of the Service or
                  the Website, without Match & Meet prior written authorization.
                  You also shall not use metatags or code or other devices
                  containing any reference to Match & Meet or the Service or the
                  Website in order to direct any person to any other website for
                  any purpose;
                </li>
                <li>
                  You shall not modify, adapt, sublicense, translate, sell,
                  reverse engineer, decipher, decompile, or otherwise
                  disassemble any portion of the Service or any software used on
                  or for the Service or cause others to do so;
                </li>
                <li>You shall not post any pornographic materials;</li>
                <li>
                  You shall not attempt to interfere with, harm, steal from, or
                  gain unauthorized access to the Service, Member accounts, or
                  the technology and equipment supporting the Service;
                </li>
                <li>
                  You shall not post incomplete, false, or misleading
                  information, impersonate another person, or misrepresent your
                  affiliation with a person or entity;
                </li>
                <li>
                  You shall not disclose personal information about another
                  person or harass, abuse, or post objectionable material;
                </li>
                <li>
                  You shall not post advertising or marketing links or content,
                  except as specifically allowed by this Agreement;
                </li>
                <li>
                  You shall not conduct any kind of advertising to, or
                  solicitation of, from one Members to other Members on the
                  Service or using information obtained via the Service, unless
                  you have not obtained specific consent to it from us;
                </li>
                <li>
                  You shall not use the Service in an unlawful or illegal way or
                  commit an illegal act in relation to the Service including,
                  without limitation, all laws associated with international
                  money transfers;
                </li>
                <li>
                  You shall not access the Service from a jurisdiction where it
                  is illegal or unauthorized;
                </li>
                <li>
                  You shall not collect email addresses of Members or Users by
                  electronic or other means for the purpose of sending
                  unsolicited email and unauthorized framing of or linking to
                  the Service;
                </li>
                <li>
                  You shall not create any databases, websites, software, legal
                  entities, and/or services that compete with the Service;
                </li>
                <li>
                  You shall not access or use (or attempt to access or use) the
                  Service in any way that violates this Agreement.
                </li>
              </ul>
              <br />
              <br />
              Although Match & Meet cannot monitor the conduct of its Members
              while not on the Service, it is also a violation of these Terms to
              use any information obtained from the Service in order to harass,
              abuse, or harm another person, or in order to contact, advertise
              to, solicit, or sell to any Member without their prior explicit
              consent.
              <br />
              <br />
              c. Our Rights Regarding Violations of Warranties and Prohibitions
              by You
              <br />
              <br />
              In order to protect our Members from prohibited advertising or
              solicitation, we reserve the right to restrict the number of
              messages that a Member may send to other Members in any 24-hour
              period to a number that we deem appropriate in our sole
              discretion.
              <br />
              <br />
              We may conduct investigation regarding possible breaches of the
              mentioned requirements from you. We reserve the right to:
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  ask for any additional documents and information necessary for
                  investigation in accordance with our Privacy Policy; and
                </li>
                <li>
                  conduct checks or other screenings of your background
                  (including criminal background in sex offender register
                  searches), at any time and using available public records,
                  social networks.
                </li>
              </ul>
              <br />
              <br />
              If such investigation shows that there is a violation by you, we
              deactivate your profile on the Service. In addition, we may take
              appropriate legal action against you because of such violation,
              without limitation, seeking civil, criminal, and injunctive
              redress.
              <br />
              <br />
              d. Requirements to Content Posted by You on the Website and/or
              Application
              <br />
              <br />
              You are solely responsible for the information, communications and
              content that you publish or display ("post") on the Service or
              transmit to other Members ("User Content").
              <br />
              <br />
              We prohibit providing, posting and transmitting to other Members
              any kind of User Content that:
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  is patently abusive and offensive to the online community,
                  such as User Content that promotes racism, bigotry, hatred, or
                  physical harm of any kind against any group or individual,
                  harasses or advocates harassment of another person;
                </li>
                <li>
                  promotes information that is inaccurate, false, misleading, or
                  promotes illegal activities or conduct that is abusive,
                  threatening, obscene, defamatory or libelous;
                </li>
                <li>
                  promotes an illegal or unauthorized copy of another person's
                  copyrighted work or protected by other intellectual property
                  rights, such as providing pirated computer programs or links
                  to them, providing information to circumvent
                  manufacture-installed copy-protect devices, or providing
                  pirated images, audio or video, or links to pirated images,
                  audio or video files;
                </li>
                <li>
                  contains restricted or password only access pages, or hidden
                  pages or images (those not linked to or from another
                  accessible page);
                </li>
                <li>
                  provides material that exploits people under the age of 18 in
                  a sexual or violent manner, or solicits personal information
                  from anyone under the age of 18;
                </li>
                <li>
                  provides instructional information about illegal activities
                  such as making or buying illegal weapons, violating someone's
                  privacy, or providing or creating computer viruses;
                </li>
                <li>
                  engages in commercial activities and/or sales without our
                  prior written consent such as contests, sweepstakes, barter,
                  advertising, and pyramid schemes;
                </li>
                <li>
                  has features of pornographic or adult content, including, but
                  not limited, content that represents a sexual act, naked
                  genitals or other graphic material; and/or
                </li>
                <li>
                  infringes or violates another party's rights and/or applicable
                  law in any other way (including, but not limited to, rights of
                  privacy and publicity).
                </li>
              </ul>
              We may investigate and take appropriate legal action in our sole
              discretion against anyone who violates this provision, including,
              without limitation, removing the offending communication from the
              Service and terminating the access and membership of such
              violators.
              <br />
              <br />
              If information provided to Match & Meet, or another Member, only
              subsequently becomes inaccurate, misleading, or false, you will
              promptly notify Match & Meet of such change and you will take all
              reasonably necessary measures to correct the information.
              <br />
              <br />
              e. Our Rights to and Regarding Content Posted by You on the
              Website and/or Application
              <br />
              <br />
              We may, but are not obliged to, monitor and remove any User
              Content for any reason. In particular, we may remove User Content
              that does not comply with the requirements of these Terms and our
              Policies.
              <br />
              <br />
              We may use any of your User Content (including your photographs)
              and your endorsements, testimonials, or other comments in respect
              of your use of the Service (whether provided via the Service,
              other methods of communication, or otherwise), for displaying on
              the public pages of the Service, advertising or publicity.
              <br />
              <br />
              We may use such Content in full or in part, and with such
              corrections of any grammatical, stylistic, or other similar
              shortcomings as we deem appropriate.
              <br />
              <br />
              By posting User Content to Match & Meet, you automatically grant,
              and you represent and warrant that you have the right to grant, to
              Match & Meet, its affiliates, licensees, and successors, an
              irrevocable, non-exclusive, fully paid, worldwide license to use,
              copy, perform, display, reproduce, adapt, modify and distribute
              such information and content and to prepare derivative works of,
              or incorporate into other works, such information and content, and
              to grant and authorize sublicenses of the foregoing for the
              purpose of providing the Service to you.
              <br />
              <br />
              You hereby release, discharge and agree to hold Match & Meet
              harmless from any causes of action in law or equity you may have
              or may hereafter acquire against Match & Meet or any of its
              affiliates for libel, slander, invasion of privacy, copyright or
              trademark violation, right of publicity, or false light arising
              out of or in connection with the utilization by Match & Meet or
              its affiliates of your User Content.
              <br />
              <br />
              If you do not wish us to do this, please contact us. More details
              on how we use your information are contained in our Privacy
              Policy.
            </div>
            <br />
            <br />
            <br />
            <div>4. ACCOUNT SECURITY</div>
            <br />
            <br />
            <br />
            <div>
              a. Security Measures
              <br />
              <br />
              In order to retain security of your account you hereby agree to
              undertake the following:
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  to keep the confidentiality of the email and password that you
                  designate during the registration process;
                </li>
                <li>
                  to be fully responsible for all activities that occur under
                  your credentials;
                </li>
                <li>
                  in case of any unauthorized use of your email or password or
                  any other breach of security immediately notify us, and ensure
                  that you exit from your account at the end of each session;
                </li>
                <li>
                  to do all possible actions to disable others to view or record
                  your password or other personal information when you are
                  accessing your account from a public or shared computer;
                </li>
                <li>
                  not to authorize others to use your membership and not assign
                  or otherwise transfer your account to any other person or
                  entity.
                </li>
              </ul>
              <br />
              <br />
              Please note that we shall not be liable for any loss or damage
              arising from your failure to comply with this provision.
              <br />
              <br />
              b. Unauthorized Interception of Data
              <br />
              <br />
              We put efforts to make your use of the Service safe and secure
              taking necessary protective and security measures. Nevertheless,
              any message or information you send through the Service may be
              possibly read or intercepted by others, even if there is a special
              notice that such message or information is encrypted or otherwise
              protected or secured.
              <br />
              <br />
              Please note that we shall not be liable for any loss or damage
              arising from unauthorized use of your credentials by third
              parties.
              <br />
              <br />
              c. Notification about Service Security Breach
              <br />
              <br />
              If we learn of a Service security breach, then we may attempt to
              notify you electronically by posting a notice on the Service or
              sending an email to you. To receive free written notice of a
              security breach (or to withdraw your consent from receiving
              electronic notice), please write to us at{' '}
              <a href="mailto:support@corp.loka.world">
                support@corp.loka.world
              </a>
              .
            </div>
            <br />
            <br />
            <br />
            <div>5. INTERACTION BETWEEN MEMBERS</div>
            <br />
            <br />
            <br />
            <div>
              a. General Rules
              <br />
              <br />
              You are solely responsible for your interactions with other
              Members.
              <br />
              <br />
              Use caution and common sense when using the Service, especially
              when providing any information to other Members, including
              personal and financial one.
              <br />
              <br />
              You acknowledge and agree:
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  to assume all risks when interacting with any other Member
                  while using the Service, including, but not
                </li>
                <li>
                  limited to, sharing confidential information, sending money;
                </li>
                <li>
                  not to provide your financial information to other Members;
                </li>
                <li>
                  to take reasonable precautions in all interactions with other
                  Members during both online and offline interaction.
                </li>
                <li>
                  to review Match & Meet's Community Guidelines (if any) prior
                  to using the Service.
                </li>
              </ul>
              <br />
              <br />
              Please note that we disclaim all liability associated with the
              mentioned risks.
              <br />
              <br />
              b. Reliance on Content, Advice, and Other Statements
              <br />
              <br />
              We do not guarantee that any opinions, advice, statements, offers,
              or other information or content from any Member or third party on
              the Service are accurate, complete, or reliable.
              <br />
              <br />
              Such Members or third parties are solely responsible for such
              content. Match & Meet does not hold any responsibility in this
              regard.
              <br />
              <br />
              Please note that we shall not be liable for any loss or damage
              arising from your reliance on information or other content posted
              on the Service or transmitted to or by any Member or third party.
              <br />
              <br />
              c. Our Disclaimers on Interaction between Members
              <br />
              <br />
              We make no representations or warranties that:
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  you will experience specific results from the use of the
                  Service;
                </li>
                <li>
                  the conduct of Members and/or their compatibility with any
                  current or future Members shall fit any of your expectations;
                </li>
                <li>
                  we are obliged to and currently do conduct criminal background
                  checks or screenings on all Members.
                </li>
              </ul>
              <br />
              <br />
              You assume all risk associated with interacting with any and all
              Members or other third parties, including without limitation the
              risk that information about a certain Member posted on the Service
              or information provided by a Member in the course of interactions
              with other Members via the Service may be false or misleading.
              <br />
              <br />
              Please note that we shall not be liable for any disputes or
              disagreements between you and any other Member or third party you
              interact with using the Service.
              <br />
              <br />
              You release Match & Meet of all claims, demands, and damages in
              disputes among Members of the Service or other third parties. You
              also agree not to involve us in such disputes. You agree to
              resolve disputes directly with the other party.
              <br />
              <br />
              To the fullest extent permitted by law, in no event shall Match &
              Meet or its affiliates, officers, employees, agents, partners, and
              licensors (collectively, the "released parties") be liable for any
              damages whatsoever, whether direct, indirect, general, special,
              compensatory, consequential, and/or incidental, arising out of, or
              relating to, the conduct of you or anyone else in connection with
              the use of the Service, including, without limitation, bodily
              injury, emotional distress, loss of business, lost profits, trade
              secret misappropriation, intellectual property infringement,
              and/or any other damages resulting from communications or meetings
              with other Members of this Service or persons you meet through the
              Service.
              <br />
              <br />
              All Members of the Service hereby expressly agree not to sue or
              hold liable the released parties for any instruction, advice or
              services that originated through the Service, and the released
              parties expressly disclaim any liability whatsoever, to the
              fullest extent permitted by law, for any damage, suits, claims,
              and/or controversies that arise or are related in any way to the
              service, the information provided through the Service and/or the
              interaction(s) between any Members of the Service, whether arising
              under contract, tort or otherwise.
            </div>
            <br />
            <br />
            <br />
            <div>6. USE OF CONTACTS AND INTERACTION WITH CUSTOMER SERVICE</div>
            <br />
            <br />
            <br />
            <div>
              a. Electronic Communication
              <br />
              <br />
              Without limiting other provisions of this Agreement (including the
              Privacy Policy), by using the Service, you agree to accept and
              consent to receiving electronic communications initiated from us
              regarding any issue arisen from this Agreement and Service.
              <br />
              <br />
              All information communicated on the Service is considered
              electronic communication. When you communicate with us through or
              on the Service or via other forms of electronic media, such as
              e-mail, you are communicating with Match & Meet electronically.
              <br />
              <br />
              You agree that we may communicate electronically with you and that
              such communications, as well as notices, disclosures, agreements,
              and other communications that we provide to you electronically,
              are equivalent to communications in writing and shall have the
              same force and effect as if they were in writing and signed by the
              party sending the communication.
              <br />
              <br />
              b. Text Messages
              <br />
              <br />
              By providing your mobile telephone number, you consent to receive
              text messages at that number as requested for account
              verification, message notifications, and other purposes related to
              the Service.
              <br />
              <br />
              While we do not charge a fee for text messages, your carrier may
              charge standard messaging, data, and other fees. You are
              responsible for those charges.
              <br />
              <br />
              We may send and receive text messages through cellular telephone
              operators or other networks, and the level of reliability may
              vary. We are not responsible for the timeliness or final delivery
              of the message, as we cannot control work of the cellular
              telephone operator or other networks.
              <br />
              <br />
              We will use your mobile number in accordance with our Privacy
              Policy.
              <br />
              <br />
              c. Prohibited Conduct towards Customer Service
              <br />
              <br />
              When communicating with our customer service representatives, you
              agree not to be abusive, obscene, profane, offensive, sexist,
              threatening, harassing, racially offensive, and behaving
              inappropriately anyhow.
              <br />
              <br />
              If your behavior towards any of our representatives is such as
              described above, we may terminate your membership.
            </div>
            <br />
            <br />
            <br />
            <div>7. PRIVACY</div>
            <br />
            <br />
            <br />
            <div>
              We respect your privacy and the use and protection of your
              personal information. Your submission of personal information
              through the Service is governed by our Privacy Policy. It contains
              important information and disclosures relating to the collection
              and use of your personal information by us.
            </div>
            <br />
            <br />
            <br />
            <div>8. TERMINATION OF ACCOUNT AND BLOCKING</div>
            <br />
            <br />
            <br />
            <div>
              a. General Grounds for Termination of Account
              <br />
              <br />
              We may suspend or terminate your use of the Services and/or
              membership at any time, at our sole discretion without cause and
              without notice. Payment Security and Refund Policy defines
              specific grounds for blocking of accounts due to payments security
              reasons.
              <br />
              <br />
              For the purposes of these Terms:
              <br />
              <br />
              “Termination” means deletion of the account from the Match &
              Meet’s servers and complete erasure of all data related to a
              Member Profile. At Member’s request, we may retrieve all personal
              data and provide in a comprehensive and readable form.
              <br />
              <br />
              “Blocking” means temporary or permanent restriction of access to a
              Member Profile for violation of these Terms.
              <br />
              <br />
              The decision regarding termination or blocking of the account is
              made each time by the Match & Meet’s Customer Service at its sole
              discretion.
              <br />
              <br />
              In particular, termination of account is possible as a result of
              its inactivity for three months, violation of these Terms,
              security reasons, etc.
              <br />
              <br />
              You may terminate your membership at any time, for any reason, by
              contacting our Customer Service online at
              <a href="mailto:support@corp.loka.world">
                &nbsp; support@corp.loka.world
              </a>
              .
              <br />
              <br />
              b. Termination as a Result of Death or Disability
              <br />
              <br />
              If a person that was the Member of the Website is no longer able
              to receive the Service by reason of death or disability, such
              person or his/her legal representative or legal successor may
              contact us regarding termination of the account.
              <br />
              <br />
              "Disability" means a condition which precludes you from physically
              using the Service during the term of disability and the condition
              is verified in writing by a physician designated and remunerated
              by you. Written verification from the physician must be submitted
              to Match & Meet.
              <br />
              <br />
              If the physician determines that the duration of the disability
              will be less than six (6) months, we may extend the term of the
              Service contract for a period of six (6) months at no additional
              charge instead of terminating the account.
              <br />
              <br />
              c. Blocking of IP addresses
              <br />
              <br />
              In order to protect the integrity of the Service, Match & Meet
              reserves the right, at any time in its sole discretion, to block
              Members with certain IP addresses from accessing the Website.
            </div>
            <br />
            <br />
            <br />
            <div>9. THIRD-PARTY LINKS AND WEBSITES</div>
            <br />
            <br />
            <br />
            <div>
              Match & Meet may provide you with access to third-party links and
              websites over which we neither monitor nor have any control nor
              input.
              <br />
              <br />
              You acknowledge and agree that Match & Meet provides access to
              such third-party links and access to third-party websites "as is"
              and "as available" without any warranties, representations or
              conditions of any kind and without any endorsement. We shall have
              no liability arising from or relating to your use of third-party
              links or websites.
              <br />
              <br />
              Any use by you of third-party links or websites offered through
              the Service is entirely at your own risk and discretion and you
              should ensure that you are familiar with and approve of the terms
              on which the third-party links and websites are provided by the
              relevant third-party provider(s).
            </div>
            <br />
            <br />
            <br />
            <div>10. CONTENT ON THE WEBSITE. COPYRIGHT POLICY</div>
            <br />
            <br />
            <br />
            <div>
              a. Proprietary Rights
              <br />
              <br />
              Match & Meet provides to you a revocable, limited, non-exclusive,
              royalty-free, non-sublicenseable, non-transferrable license to use
              the Service. You may not post, distribute, or reproduce in any way
              any copyrighted material, trademarks, or other proprietary
              information without obtaining the prior written consent of the
              owner of such proprietary rights.
              <br />
              <br />
              You acknowledge and agree that Match & Meet owns, licenses, or
              otherwise retains all proprietary rights in the Service and all
              intellectual property thereon and therein.
              <br />
              <br />
              Those rights include, but are not limited to, database rights,
              copyright, design rights (whether registered or unregistered),
              patents, trademarks (whether registered or unregistered) and other
              similar rights, wherever existing in the world, together with the
              sole and exclusive right to apply for the protection of the same
              on behalf of Match & Meet and its licensors.
              <br />
              <br />
              The Service contains the copyrighted material, trademarks, and
              other proprietary information of Match & Meet and its licensors.
              Except for that information which is in the public domain or for
              which you have been given written permission, you may not copy,
              modify, publish, transmit, distribute, perform, display, or sell
              any such proprietary information.
              <br />
              <br />
              b. Our Actions in Cases of Intellectual Property Rights
              Infringement
              <br />
              <br />
              We do not tolerate infringing activity on or through the Service
              anyhow.
              <br />
              <br />
              If we have any reasons to believe in good faith that User Content
              and/or any kind of information violates intellectual property
              rights of a third party by being made available on or through the
              Service, upon notice from an intellectual property owner or his or
              her agent, we:
              <br />
              <br />
              (i) remove or disable access to material; and
              <br />
              <br />
              (ii) remove any User Content uploaded to the Service by "repeat
              infringers".
              <br />
              <br />
              "Repeat infringer" is a Member that has uploaded User Content to
              or through the Service and about whom Match & Meet has received
              more than two takedown notices compliant under applicable law with
              respect to such User Content.
              <br />
              <br />
              However, we may terminate the account of Member after receipt of a
              single notification of claimed infringement.
              <br />
              <br />
              c. Procedure for Reporting Claimed Infringement
              <br />
              <br />
              If you believe that any content made available on or through the
              Service infringes your intellectual property right, please
              promptly send a written "Notification of Claimed Infringement"
              containing the following information to the designated agent
              identified below. Match & Meet may share your Notification of
              Claimed Infringement with the Member that is possible infringer,
              and you consent to making such disclosure by Match & Meet. Your
              communication must include substantially the following:
              <br />
              <br />
              1. A physical or electronic signature of a person authorized to
              act on behalf of the owner of the material(s) that has/have been
              allegedly infringed;
              <br />
              <br />
              2. Identification of the material allegedly being infringed, or,
              if multiple materials are covered by a single notification, then a
              representative list of such works;
              <br />
              <br />
              3. Identification of the specific material that is claimed to be
              infringing or to be the subject of infringing activity and that is
              to be removed or access to which is to be disabled, and
              information reasonably sufficient to permit Match & Meet to locate
              the material on the Service;
              <br />
              <br />
              4. Information reasonably sufficient to permit Match & Meet to
              contact you, such as your name, address, telephone number, and
              email address;
              <br />
              <br />
              5. A statement that you have a good faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright or other intellectual property owner, its agent, or the
              law; and
              <br />
              <br />
              6. Under penalty of perjury, a statement that the information in
              the Notification of Claimed Infringement is accurate and truthful,
              and that you are authorized to act on behalf of the owner of an
              exclusive right that is allegedly infringed.
              <br />
              <br />
              You should consult with your own lawyer and/or review applicable
              law regarding copyright or other intellectual property
              infringement to confirm your obligations to provide a valid notice
              of claimed infringement.
              <br />
              <br />
              d. Designated Agent Contact Information
              <br />
              <br />
              Designated agent of Match & Meet for receipt of Notifications of
              Claimed Infringement can be contacted at e-mail:
              <a href="mailto:legal@corp.loka.world">legal@corp.loka.world</a>
              <br />
              <br />
              e. False Notifications of Claimed Infringement or Counter
              Notifications
              <br />
              <br />
              Match & Meet reserves the right to seek damages from any party
              that submits a false notification in violation of the law, as
              provided for by applicable law.
            </div>
            <br />
            <br />
            <br />
            <div>11. ADDITIONAL DISCLAIMERS OF WARRANTIES</div>
            <br />
            <br />
            <br />
            <div>
              a. Basic Disclaimers of Warranties
              <br />
              <br />
              Except where otherwise inapplicable or prohibited by law to the
              fullest extent permitted by law, you expressly understand and
              agree that your use of the Service is at your sole risk, and the
              Service is provided on an "as is" and "as available" basis.
              <br />
              <br />
              The released parties (as defined above in Section 6(e)) expressly
              disclaim all warranties of any kind, whether express or implied,
              including, but not limited to, implied warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement as well as any and all warranties as to products
              or services offered by businesses listed on the service. The
              released parties make no, and expressly disclaim any warranty
              that:
              <br />
              <br />
              (i) the Service will meet your requirements,
              <br />
              <br />
              (ii) the Service will be uninterrupted, timely, secure, or
              error-free,
              <br />
              <br />
              (iii) the results that may be obtained from the use of the
              Service, including data, will be accurate or reliable,
              <br />
              <br />
              (iv) the quality of any data or service available on the Service
              will meet your expectations, and
              <br />
              <br />
              (v) any errors in the service will be corrected.
              <br />
              <br />
              Any material obtained through the use of the Service is accessed
              at your own discretion and risk, and you will be solely
              responsible for any damage to your computer system or mobile
              device or loss of data that results from the use of any such
              material.
              <br />
              <br />
              We cannot guarantee and do no promise any specific results from
              use of the Website and/or the Service. You agree also to take the
              risks of interruption of the Service for any technical reasons.
              <br />
              <br />
              b. Absence of Any Advice on the Service
              <br />
              <br />
              Any statement that may be posted on the Service is for
              informational and entertainment purposes only and is not intended
              to replace or substitute for any professional financial, medical,
              legal, or other advice.
              <br />
              <br />
              Match & Meet makes no representations or warranties and, to the
              fullest extent permitted by law, expressly disclaims any and all
              liability relating to your reliance on the statements or other
              information offered or provided within or through the Service. If
              you have specific concerns or a situation arises in which you
              require professional or medical advice, you should consult with an
              appropriately trained and qualified specialist.
              <br />
              <br />
              c. Change of Website/Application Information and Service
              <br />
              <br />
              We may change all the information provided on the Service at our
              sole discretion without notice. We may at any time modify or
              discontinue, temporarily or permanently, the Service (or any part
              thereof) at our sole discretion with or without notice. You agree
              that we shall not be liable to you or any third party for any
              modification, suspension or discontinuance of the Service.
            </div>
            <br />
            <br />
            <br />
            <div>12. LIMITATION OF LIABILITY</div>
            <br />
            <br />
            <br />
            <div>
              Except where otherwise inapplicable or prohibited by law you
              expressly understand and agree that, to the fullest extent
              permitted by law, the released parties shall not be liable to you
              for any direct, indirect, incidental, special, consequential,
              punitive, or exemplary damages, including, but not limited to,
              damages for loss of profits, goodwill, use, data or other
              intangible losses (even if Match & Meet has been advised of the
              possibility of such damages), resulting from:
              <br />
              <br />
              (a) the use, attempted use, or the inability to use the Service;
              <br />
              <br />
              (b) the cost of procurement of substitute goods and services
              resulting from any data, information, or services obtained or
              messages received or transactions entered into through, from, or
              as a result of the Service;
              <br />
              <br />
              (c) unauthorized access to or alteration of your transmissions or
              data;
              <br />
              <br />
              (d) statements or conduct of any user or third party on the
              Service;
              <br />
              <br />
              (e) your reliance on content or data made available by us; or
              <br />
              <br />
              (f) any other matter relating to the Service.
              <br />
              <br />
              Even if Match & Meet is found liable under any theory, Match &
              Meet’s liability and your exclusive remedy will be limited to the
              greater of the fees you have paid to Match & Meet or $100.
              <br />
              <br />
              This limitation of liability shall apply for all claims,
              regardless of whether Match & Meet was aware of or advised in
              advance of the possibility of damages or such claims. Some
              jurisdictions do not allow the exclusion of certain warranties or
              the limitation or exclusion of liability for incidental or
              consequential damages. Accordingly, some of the above limitations
              in this paragraph may not apply to you.
            </div>
            <br />
            <br />
            <br />
            <div>13. INDEMNITY BY YOU</div>
            <br />
            <br />
            <br />
            <div>
              To the fullest extent permitted by law, you agree to indemnify,
              defend, and hold harmless Match & Meet and all of its
              subsidiaries, affiliates, officers, agents, and other partners and
              employees, from and against any loss, liability, claim, or demand,
              including reasonable attorney's fees and costs, made by any third
              party resulting from or in any way connected with or related to
              your use of the Service in violation of this Agreement and/or your
              breach of this Agreement and/or any of your representations and
              warranties set forth above.
              <br />
              <br />
              Match & Meet reserves the right to assume the exclusive defense
              and control of any matter otherwise subject to indemnification by
              you, in which event you will fully cooperate with, and fully
              indemnify, Match & Meet in connection therewith.
            </div>
            <br />
            <br />
            <br />
            <div>
              14. DISPUTE RESOLUTION BY MANDATORY BINDING ARBITRATION AND CLASS
              ACTION WAIVER
            </div>
            <br />
            <br />
            <br />
            <div>
              Please read this arbitration provision carefully to understand
              your rights:
              <br />
              <br />
              (a) All claims must be resolved through binding arbitration by a
              neutral arbitrator;
              <br />
              <br />
              (b) You are waiving the right to a trial by jury; the rights that
              you would have if you went to court, such as discovery or the
              right to appeal, may be more limited or may not exist.
              <br />
              <br />
              (c) You may only bring a claim in your individual capacity and not
              as a plaintiff (lead or otherwise) or class member in any
              purported class or representative proceeding;
              <br />
              <br />
              (d) The arbitrator may not consolidate proceedings or claims or
              otherwise preside over any form of a representative or class
              proceeding.
              <br />
              <br />
              a. Mandatory Arbitration
              <br />
              <br />
              This Arbitration Agreement provides that all disputes must be
              resolved through BINDING ARBITRATION whenever you or we choose to
              submit or refer a dispute to arbitration. You and Loka, and each
              of our respective agents, corporate parents, subsidiaries,
              affiliates, predecessors in interest, successors, and assigns,
              agree to BINDING ARBITRATION (except for matters that may be taken
              to small claims court), as the exclusive form of dispute
              resolution except as provided for below, for all disputes and
              claims arising out of or relating to this Agreement (including the
              Privacy Policy) or the Service, unless you are located in a
              jurisdiction that prohibits the exclusive use of arbitration for
              dispute resolution. YOU AGREE THAT, BY ENTERING INTO THIS
              AGREEMENT, YOU AND Match & Meet ARE EACH WAIVING THE RIGHT TO A
              TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
              <br />
              <br />
              b. Waiver of Class Action and Collective Relief
              <br />
              <br />
              There shall be no right or authority for any claims to be
              arbitrated or litigated on a class action, joint or consolidated
              basis or on bases involving claims brought in a purported
              representative capacity on behalf of the general public, other
              users of this Website, or any other persons. The arbitrator may
              award relief only in favor of the individual party seeking relief
              and only to the extent necessary to provide relief warranted by
              that individual party’s claim. The arbitrator may not award relief
              for or against anyone who is not a party. The arbitrator may not
              consolidate more than one person’s claims, and may not otherwise
              preside over any form of a representative or class proceeding.
              This waiver of class actions and collective relief is an essential
              part of this arbitration provision and cannot be severed from it.
              <br />
              <br />
              c. Arbitration Procedures
              <br />
              <br />
              Any disputes arising out of or in connection with this Agreement,
              including any questions regarding its existence, validity, or
              termination, shall be referred to and finally resolved by BINDING
              ARBITRATION under the London Court of International Arbitration
              (“LCIA”) Rules in force when the Notice of Arbitration is
              submitted, which Rules are deemed to be incorporated by reference
              into this clause.
              <br />
              <br />
              <ul style={{paddingLeft: '40px'}}>
                <li>
                  Overview. Arbitration is an alternative to litigation where a
                  neutral person (the arbitrator) hears and decides the parties’
                  dispute. Arbitration proceedings are designed to provide
                  parties with a fair hearing in a manner that is faster and
                  less formal than court proceedings. The following procedures
                  (the “Arbitration Procedures”) are applicable to all
                  arbitration proceedings involving you and Match & Meet.
                </li>
                <li>
                  Pre-Arbitration Dispute Resolution. Match & Meet is always
                  interested in resolving disputes amicably and efficiently.
                  Therefore, before you commence an arbitration, we suggest that
                  you contact us to explain your complaint, as we may be able to
                  resolve it without the need for arbitration. You may contact
                  us online at legal@corp.loka.world.
                </li>
                <li>
                  Administrator. The administrator for the arbitration is the
                  LCIA, a non-profit organization that is not affiliated with
                  Match & Meet. The arbitrator who will hear and decide your
                  dispute will be appointed by the LCIA in accordance with its
                  rules. Information about the LCIA’s rules and fees can be
                  found at:
                  https://www.lcia.org//Dispute_Resolution_Services/lcia-arbitration-rules-2014.aspx.
                </li>
                <li>
                  Seat of Arbitration. The seat of the arbitration shall be
                  London, United Kingdom.
                </li>
                <li>
                  Choice of Law. The governing law applicable to the Arbitration
                  Agreement and the arbitration shall be the laws of England and
                  Wales (also known as English Law), without regard to English
                  Law’s conflict of laws rules. This Agreement, its conclusions
                  and interpretations, as well as all claims, including any
                  non-Agreement obligations, arising out of or in connection
                  with this Agreement or its conclusion, shall be subject to the
                  laws of England and Wales.
                </li>
                <li>
                  Language. The language of the arbitration shall be English.
                </li>
                <li>
                  Applicable Rules. The arbitration will be governed by the
                  LCIA’s Arbitration Rules (“LCIA Rules”), as modified by these
                  Arbitration Procedures. If there is any inconsistency between
                  the LCIA’s Rules and these Arbitration Procedures, the
                  Arbitration Procedures will control. However, if the
                  arbitrator determines that strict application of the
                  Arbitration Procedures would not result in a fundamentally
                  fair arbitration, the arbitrator may make any order necessary
                  to provide a fundamentally fair arbitration that is consistent
                  with the LCIA Rules.
                </li>
                <li>
                  Commencing an Arbitration. To commence an arbitration against
                  Match & Meet, you must complete a written request for
                  arbitration, submit it to the LCIA, and send a copy to Match &
                  Meet at Match & Meet Legal, legal@corp.loka.world.
                  Alternatively, the notice may be addressed to (the
                  “Arbitration Notice Address”) To learn more about commencing
                  an arbitration and to obtain a form to institute arbitration,
                  see the LCIA’s online filing page,
                  https://onlinefiling.lcia.org/. You may represent yourself in
                  the arbitration or have a lawyer (or some other
                  representative) act on your behalf. Upon receipt of an
                  arbitration claim, Match & Meet may assert any counterclaims
                  it may have against the complaining party.
                </li>
                <li>
                  Fees. You are responsible for paying your portion of the fees
                  set forth in the LCIA’s Schedule of LCIA Arbitration Costs and
                  as determined by the Arbitral Tribunal under LCIA Rules. Match
                  & Meet will pay all remaining fees. If your claim against
                  Match & Meet is for less than $1,000, we will pay all fees.
                  You may hire an attorney to represent you in arbitration. You
                  are responsible for your attorneys’ fees and additional costs.
                  Notwithstanding anything in this Arbitration Provision to the
                  contrary, we will pay all fees and costs that we are required
                  by law to pay.
                </li>
                <li>
                  Selection of the Arbitrator. The arbitrator who will hear and
                  decide your dispute will be appointed by the LCIA in
                  accordance with its rules.
                </li>
                <li>
                  Discovery. Each party may (a) request relevant, non-privileged
                  documents from the other party; and (b) request that the other
                  party provide the particulars of its claims or defenses. Any
                  such discovery requests must be served on the other party
                  within 10 days after the arbitrator’s appointment. The
                  responding party shall provide the requesting party with all
                  responsive, non-privileged documents, the requested
                  particulars, and/or any objections to the requests within 15
                  days after receipt of the requests. Any disputes about
                  discovery or requests for extensions shall be submitted
                  promptly to the arbitrator for prompt resolution. In ruling on
                  any discovery dispute or extension request, the arbitrator
                  shall take into consideration the nature, amount, and scope of
                  the underlying arbitration claim, the cost and other effort
                  what would be involved in providing the requested discovery,
                  the case schedule, and whether the requested discovery is
                  necessary for the adequate preparation of a claim or defense.
                </li>
                <li>
                  Communications with the Arbitrator. Whenever communicating
                  with the arbitrator, the parties must include each other – for
                  example, by including the other party on a telephone
                  conference call and copying the other party on any written
                  submissions, such as letters or emails. To the extent
                  practicable, conferences with the arbitrator will take place
                  by telephone conference call or email. Ex parte communications
                  are not permitted with any arbitrator.
                </li>
                <li>
                  Confidentiality. Upon either party’s request, the arbitrator
                  will issue an order requiring that confidential information of
                  either party disclosed during the arbitration (whether in
                  documents or orally) may not be used or disclosed except in
                  connection with the arbitration or a proceeding to enforce the
                  arbitration award and that any permitted filing of
                  confidential information must be done under seal.
                </li>
                <li>
                  Arbitration Award. The arbitrator will render a written
                  decision within 14 days after the hearing or, if no hearing
                  was held, within 30 days after any rebuttal or supplemental
                  statements are due. The decision must clearly specify the
                  relief, if any, awarded and contain a brief statement of the
                  reasons for the award. Waiver of Appeal. The parties waive any
                  right to refer any question of law and any right of appeal on
                  the law and/or the merits to any court.
                </li>
                <li>
                  Survivability. This arbitration provision shall survive
                  termination of this Agreement.
                </li>
              </ul>
            </div>
            <br />
            <br />
            <br />
            <div>15. ADDITIONAL PROVISIONS APPLICABLE IN CERTAIN STATES</div>
            <br />
            <br />
            <br />
            <div>
              The following provisions are applicable for Members residing in
              California.
              <br />
              <br />
              You, the buyer, may cancel the terms and conditions of this
              Agreement, without penalty or obligation, at any time prior to
              midnight of the third business day following the original date of
              this Agreement, excluding Sundays and holidays. To cancel this
              Agreement, please mail or deliver a signed and dated notice which
              states that you, the buyer, are canceling this Agreement, or words
              of similar effect.
              <br />
              <br />
              This notice shall be sent to{' '}
              <a href="mailto:support@corp.loka.world">
                support@corp.loka.world
              </a>
              <br />
              <br />
              Please include your username and email address in any
              correspondence. Provided you cancel the Agreement in accordance
              with the abovementioned procedure, we will return, within ten days
              of the date on which you give notice of cancellation, any payments
              you have made.
            </div>
            <br />
            <br />
            <br />
            <div>16. CHANGES TO THE AGREEMENT AND ITS PARTIES. NOTICES</div>
            <br />
            <br />
            <br />
            <div>
              a. Changes to the Agreement
              <br />
              <br />
              We reserve the right, at our sole discretion, to change the
              Agreement from time to time and at any time and without prior
              notice by
              <br />
              <br />
              a) posting the changed Agreement (or parts of it) to the Service;
              or
              <br />
              <br />
              b) otherwise giving you notice of the changes.
              <br />
              <br />
              The changes shall be effective upon such posting by us or upon us
              giving you such notice, whichever is the earlier (unless we
              expressly indicate otherwise).
              <br />
              <br />
              It is your responsibility to check the Service and your email
              account periodically for changes to these Terms and other parts of
              the Agreement.
              <br />
              <br />
              Your continued use of or access to the Service following the
              effective date of any changes to the Agreement constitutes
              acceptance of those changes. This Agreement may not be changed by
              you, unless any changes proposed by you are expressly accepted by
              Match & Meet in writing.
              <br />
              <br />
              Any new features which are added to the Service shall also be
              subject to the Terms.
              <br />
              <br />
              In this clause, the terms "change" and "changed" in relation to
              changes to the Agreement shall be interpreted broadly and shall
              include any and all modifications, amendments, revisions and
              restatements whatsoever, including adding or removing any portions
              of this Agreement.
              <br />
              <br />
              b. Changes to Parties
              <br />
              <br />
              We may assign or transfer all of our rights and obligations
              hereunder to any other person, whether by way of novation or
              otherwise, and you hereby give us consent to any such assignment
              and transfer.
              <br />
              <br />
              You agree that posting on this Website of a version of this
              Agreement indicating another person as a party to this Agreement
              will constitute sufficient notice to you of the transfer of our
              rights and obligations under the Agreement with you to that party
              (unless otherwise is expressly indicated).
              <br />
              <br />
              c. Notices
              <br />
              <br />
              Without prejudice to the provisions of the preceding clause, we
              may choose to notify you of changes to this Agreement by posting a
              notice via the Service, by sending you an email, or otherwise.
              <br />
              <br />
              If we choose to notify you about changes to this Agreement or
              about other matters by email, each such notification shall be
              effective and shall be deemed received by you immediately after
              being sent to the email address you have provided to us, even if:
              <br />
              <br />
              (a) our email notification is filtered as a spam, junk, bulk, or
              other undesirable or low-priority message and is not displayed in
              your email inbox; or
              <br />
              <br />
              (b) you do not actually read it for any other reason.
              <br />
              <br />
              To reduce the chance that it is so filtered, please add
              legal@corp.loka.world to your email contact book and whitelist
              this address as a "safe" or "approved" sender. In addition, you
              may wish to create a custom filter marking emails from this
              address as important emails for your high-priority inbox. Please
              contact your email service provider if you are not sure how to do
              any of that.
            </div>
            <br />
            <br />
            <br />
            <div>17. TERM OF THE AGREEMENT</div>
            <br />
            <br />
            <br />
            <div>
              This Agreement will take full force and effect when you access the
              Service and will remain in effect while you use the Service until
              your account is terminated for whatever reason.
              <br />
              <br />
              After your membership is terminated, all terms that by their
              nature may survive termination of this Agreement shall be deemed
              to survive such termination including, but not limited to,
              Sections 4, 6-21.
              <br />
              <br />
              We reserve the right to take further action for our loss or the
              potential loss of other Members or third parties when necessary
              due to your breach of this Agreement, in our sole discretion.
            </div>
            <br />
            <br />
            <br />
            <div>18. ELECTRONIC SIGNATURE</div>
            <br />
            <br />
            <br />
            <div>
              You further acknowledge and agree that by clicking on a button
              labeled "SUBMIT", "I ACCEPT", "I AGREE" or similar links or
              buttons, you are submitting a legally binding electronic signature
              and are entering into a legally binding contract. You acknowledge
              that your electronic submissions constitute your agreement and
              intent to be bound by this Agreement.
              <br />
              <br />
              Pursuant to any applicable statutes, regulations, rules,
              ordinances or other laws, including without limitation the United
              States Electronic Signatures in Global and National Commerce Act,
              P.L. 106-229 (the "E-Sign Act") or other similar statutes, you
              hereby agree to the use of electronic signatures, contracts,
              orders and other records and to electronic delivery of notices,
              policies and records of transactions initiated or completed
              through the Service.
              <br />
              <br />
              Furthermore, you hereby waive any rights or requirements under any
              statutes, regulations, rules, ordinances or other laws in any
              jurisdiction which require an original signature, delivery or
              retention of non-electronic records, or to payments or the
              granting of Credits by other than electronic means.
            </div>
            <br />
            <br />
            <br />
            <div>19. MISCELLANEOUS</div>
            <br />
            <br />
            <br />
            <div>
              a. Governing Law
              <br />
              <br />
              This Agreement shall be governed by and construed in accordance
              with the law of England and Wales.
              <br />
              <br />
              To the extent that any action relating to any dispute hereunder is
              for whatever reason not submitted to arbitration, each of the
              parties submits to the exclusive jurisdiction to the courts of
              England and Wales to settle any disputes which may arise out of or
              in connection with this Agreement and that accordingly Proceedings
              must be brought in such courts.
              <br />
              <br />
              The parties irrevocably submit to the personal jurisdiction and
              venue of the courts of England and waive any defenses of improper
              venue or forum non conveniens.
              <br />
              <br />
              b. Entire Agreement. Severability
              <br />
              <br />
              This Agreement and all other provisions referenced herein contain
              the entire agreement between you and Match & Meet regarding the
              use of the Service. If any provision of this Agreement is held
              invalid, the remainder of this Agreement shall continue in full
              force and effect.
              <br />
              <br />
              c. No Waiver of Breach or Default
              <br />
              <br />
              The failure to require performance of any provision will not
              affect our right to require performance at any time thereafter,
              nor will a waiver of any breach or default of this Agreement or
              any provision of this Agreement constitute a waiver of any
              subsequent breach or default or a waiver of the provision itself.
              <br />
              <br />
              d. Force Majeure
              <br />
              <br />
              Match & Meet shall not be responsible for any failure to perform
              due to unforeseen circumstances or to causes beyond our reasonable
              control, including but not limited to: acts of God, such as fire,
              flood, earthquakes, hurricanes, tropical storms or other natural
              disasters; war, riot, arson, embargoes, acts of civil or military
              authority, or terrorism; strikes, or shortages in transportation,
              facilities, fuel, energy, labor or materials; failure of the
              telecommunications or information services infrastructure;
              hacking, spam, or any failure of a computer, server or software,
              for so long as such event continues to delay Match & Meet’s
              performance.
              <br />
              <br />
              e. Absence of Certain Kind of Relationship
              <br />
              <br />
              You agree that no joint venture, partnership, employment, or
              agency relationship exists between you and Match & Meet as a
              result of this Agreement or use of the Service.
              <br />
              <br />
              f. Use of Section Headers
              <br />
              <br />
              Use of Section headers in this Agreement is for convenience only
              and will not have any impact on the interpretation of particular
              provisions.
            </div>
            <br />
            <br />
            <br />
            <div>20. CONTACT INFORMATION</div>
            <br />
            <br />
            <br />
            <div>
              Please Contact Us with any questions regarding this Agreement.
              <br />
              <br />
              Mailing address: Harju maakond, Tallinn, Mustamäe linnaosa,
              Ehitajate tee 110, 13517, Estonia
              <br />
              <br />
              Attention of: Customer Support, Galocor OÜ
              <br />
              <br />
              E-mail:{' '}
              <a href="mailto:support@corp.loka.world">
                support@corp.loka.world
              </a>
              <br />
              <br />
              All Rights Reserved.
              <br />
              <br />
              Customer Support:{' '}
              <a href="mailto:support@corp.loka.world">
                support@corp.loka.world
              </a>
            </div>
          </div>
        </main>
        {(!this.props.session.isLogged &&
          !this.props.credentials.user_id &&
          !this.props.registration.inProgress) ||
        (!this.props.session.isLogged &&
          !this.props.credentials.user_id &&
          this.props.registration.inProgress) ? (
          <Footer />
        ) : null}
      </>
    );
  }
}

export default connect((state) => ({
  people: state.people,
  peopleSearch: state.peopleSearch,
  events: state.events,
  session: state.session,
  credentials: state.credentials,
  registration: state.registration,
}))(Terms);
