import React from 'react';
import MessageBubble from '../MessageBubble';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import __ from '../../../../utils/translate';
import {isLoka} from '../../../../apps-config';

const ChatHistory = ({
                         messages,
                         credentials,
                         contactUser,
                         isVip,
                         isBlocked,
                         blockedReason,
                         wasVip,
                         history,
                         userLang,
                         translateMessage,
                     }) => {
    const clsContainer = isLoka()
        ? 'messages-area__container'
        : 'messages-area__container ts-messages-area__container';
    const subscriptionList = () => (
        <div className={clsContainer}>
            <div className="limit">
                <p className="limit__action">
                    {__('Opening a reply to your message costs 7 Coins.')}
                </p>
                <p className="limit__cost">{__('Upgrade to read her message')}</p>
                <Link
                    to={{
                        pathname: '/get-premium',
                        state: {modal: true}
                    }}
                    className="btn btn_upgrade">
                    {__('Upgrade now')}
                </Link>
            </div>
        </div>
    );

    const tryFreeBlock = () => (
        <div className={clsContainer}>
            <div className="limit">
                <p className="limit__action">
                    {__('Opening a reply to your message costs 7 Coins.')}
                </p>
                <p className="limit__cost">{__('Upgrade to read her message')}</p>
                <Link
                    to={{
                        pathname: '/get-premium',
                        state: {
                            modal: true,
                            initialRoute: '/messages',
                        },
                    }}
                    className="btn btn_upgrade">
                    {__('Upgrade now')}
                </Link>
            </div>
        </div>
    );

    const needBuyContacts =
        isBlocked && blockedReason === 'max_chats_per_day_limit';

    const needBuyContactsTrial = (
        <div className={clsContainer}>
            <div className="limit">
                <p className="limit__action">
                    {__('Your limit of new contacts is over for today.')}
                </p>
                <p className="limit__cost">{__('Upgrade to get unlimited contacts')}</p>
                <Link
                    to={{
                        pathname: '/get-premium',
                        state: {modal: true}
                    }}
                    className="btn btn_upgrade">
                    {__('Upgrade now')}
                </Link>
            </div>
        </div>
    );

    const showAddCoins = () => {
        localStorage.setItem('urlAddCoins', JSON.stringify({
            pathname: history.location.pathname,
            state: history.location.state
        }))
        history.push({
            pathname: '/add-coins',
            state: {modal: true},
        });
    };

    const needCoins = (
        <div className="limit">
            <p className="limit__action">
                {contactUser.gender === 'F'
                    ? __('Add Coins to read \n her  message.')
                    : __('Add Coins to read \n his  message.')}
            </p>
            <p className="limit__cost">
                {__('Opening a reply to your message costs 7 Coins.')}
            </p>

            <div onClick={() => showAddCoins()} className="btn btn_favorites">
                {__('Add Coins')}
            </div>
        </div>
    );

    const firstBlurMessageIndex = messages.map((item, index) => !item.is_my && !item.is_free && !item.is_paid ? index : null).find(item => typeof item === 'number');

    const renderMessages = () =>
        messages.map((item, index) => {
            return (
                <React.Fragment key={index}>
                    <MessageBubble
                        key={index}
                        item={item}
                        credentials={credentials}
                        messages={messages}
                        index={index}
                        contactUser={contactUser}
                        userLang={userLang}
                        translateMessage={translateMessage}
                        firstBlurMessageIndex={firstBlurMessageIndex}
                    />
                </React.Fragment>
            );
        });

    if (needBuyContacts) {
        return needBuyContactsTrial;
    }
    return (
        <div className={clsContainer}>
            {messages && messages.length > 0 ? (
                <>
                    {(() => {
                        if (!isBlocked) {
                            return null;
                        }
                        return isVip
                            ? needCoins
                            : wasVip
                                ? subscriptionList()
                                : tryFreeBlock();
                    })()}
                    {renderMessages()}
                </>
            ) : (
                <div className="messages-area__default-content">
                    <div className="chat_ask">
            <span>
              {contactUser.gender === 'F'
                  ? __('Ask her about')
                  : __('Ask his about')}
                :
            </span>
                        <ul>
                            <li>{__('Local food')}</li>
                            <li>{__('Local music')}</li>
                            <li>{__('Local history')}</li>
                            <li>{__('Local politics')}</li>
                        </ul>
                        <span>{__('or just say Hi')}</span>
                        <div className="chat_ask_text">
                            {__('You can write in English.')} {contactUser.name}{' '}
                            {__('will see your messages translated to Greek.')}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withRouter(ChatHistory);
