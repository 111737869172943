import React, {Component} from 'react';
import {ReactComponent as BtnAppStore} from '../../Components/Icons/BtnAppStore.svg';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import __ from '../../utils/translate';
import {isTheyStream} from '../../apps-config';

class Footer extends Component {
  render() {
    const clsFooter = isTheyStream() ? ['ts-footer'] : ['footer'];
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    if (showBlur) {
      clsFooter.push('blur');
    }
    return (
      <div className={clsFooter.join(' ')}>
        <div className="container">
          <div className="download">
            <p className="download__text text--light">
              {__('Download our apps')}
            </p>
            <div className="download__group">
              <a
                href={isTheyStream() ? 'https://play.google.com/store/apps/details?id=com.theystream' : 'https://play.google.com/store/apps/details?id=com.loka'}
                className="download__btn download__btn_gp">
                <img src="/img/google-play@2x.png" alt="" />
              </a>
              <a
                href={isTheyStream() ? 'https://apps.apple.com/us/app/match-meet-dating-app/id1535615149' : 'https://apps.apple.com/us/app/id1523632672'}
                className="download__btn download__btn_as">
                <BtnAppStore />
              </a>
            </div>
          </div>
          <div className="footer__links">
            <Link className="footer__link" to="/terms">
              {__('Terms & Conditions')}
            </Link>
            <Link to="/policy" className="footer__link">
              {__('Privacy Policy')}
            </Link>
            <Link to="/contacts" className="footer__link">
              {__('Contact Us')}
            </Link>
          </div>
          {this.props.screen.isMobileMode && <div style={{height: 110}}></div>}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  events: state.events,
  screen: state.screen,
}))(Footer);
