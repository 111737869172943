import React from 'react';
import './styles.scss'
import { ReactComponent as IconArrowLeft } from '../../Icons/ArrowLeft.svg';

const LikeTextFieldButton = ({
  label,
  value,
  placeholder,
  onClick
}) => {

  return (
    <div className="form_control mb20 ts-form_control">
      {label ? <div className="form_label">{label}</div> : null}
      <div onClick={() => onClick()} className="form_input">
        <div className="button_container">
          <div className="button_text_container">
            {value ? (
              <p className="search-settings-value">
                {value}
              </p>
            ) : (
              <p className="button_placeholder">{placeholder}</p>
            )}
          </div>
          <span className="collection_col_arrow">
           <IconArrowLeft />
          </span>
        </div>
      </div>
    </div>
  );
};

export default LikeTextFieldButton;
