import {
  PROFILE_RESET,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  PHOTO_INFO_SUCCESS,
  PROFILE_NEXT_REQUEST,
  PROFILE_NEXT_SUCCESS,
  PROFILE_PREVIOUS_SUCCESS,
  PHOTO_LIKE_SUCCESS,
  PROFILE_MEET_SUCCESS,
  CHAT_REQUEST,
  GIFT_ASSIGN_SUCCESS,
  FAVORITES_ADD_SUCCESS,
} from '../constants';

const initialState = {
  name: '',
  albums: [],
  allow_photo_comments: 1,
  about: '',
  gifts: {items: []},
  trips: [],
  visited_countries: [],
  guide_services: [],
  main_photo: {},
  nextProfileOffset: 1,
  is_verified: false,
  meet: {
    guide: null,
    profile: null,
    trip: null,
  },

  hasError: false,
  errorName: null,

  isFetching: true,
  isAssignedGift: false,
  chat_with_me: false,
};

function sortByKey(array, key) {
  return array.sort((a, b) => {
    const x = a[key];
    const y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export default function profile(state = initialState, action) {
  let newState;
  switch (action.type) {
    case PROFILE_RESET:
      return initialState;
    case PROFILE_NEXT_REQUEST:
      return {
        ...state,
        isFetching: true,
        nextProfileOffset: action.payload, // 2
      };

    case PROFILE_NEXT_SUCCESS:
      return {
        ...state,
        nextProfileOffset: action.payload.next_offset,
      };

    case PROFILE_PREVIOUS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case FAVORITES_ADD_SUCCESS:
      return {
        ...state,
        is_favorite: !{...state}.is_favorite,
      };

    case PROFILE_REQUEST:
      return {
        ...state,
        hasError: false,
        isFetching: true,
        isAssignedGift: false,
      };

    case PROFILE_SUCCESS:
      newState = {...action.payload.user_info};
      newState.albums = sortByKey(newState.albums, 'album_type');
      newState.isFetching = false;
      return {
        ...state,
        ...newState,
        chat_with_me: action.payload.chat_with_me
      };

    case PROFILE_FAIL:
      return {
        ...initialState,
        hasError: true,
        errorName: action.payload.error,
        errorData: action.payload.data,
      };

    case PHOTO_INFO_SUCCESS:
      newState = {...state};
      // Update info in photos by albums.
      newState.albums.map((album) => {
        if (album.photos_count) {
          album.photos.map((photo) => {
            let newPhoto = {...photo};
            if (newPhoto.photo_id === action.payload.photo_info.photo_id) {
              newPhoto = Object.assign(photo, action.payload.photo_info);
            }
            return newPhoto;
          });
        }
        return album;
      });
      return {
        ...state,
        albums: newState.albums,
      };

    case PHOTO_LIKE_SUCCESS:
      newState = {...state};
      // Update info in photos by albums.
      newState.albums.map((album) => {
        if (album.photos_count) {
          album.photos.map((photo) => {
            // It uses object reference to state updates
            const newPhoto = photo;
            if (newPhoto.photo_id === action.payload.id) {
              newPhoto.is_liked = true;
              newPhoto.vote_count += 1;
            }
            return newPhoto;
          });
        }
        return album;
      });
      return {
        ...state,
        albums: newState.albums,
      };

    case PROFILE_MEET_SUCCESS:
      return {
        ...state,
        meet: {
          ...state.meet,
          ...action.payload,
        },
      };

    case GIFT_ASSIGN_SUCCESS:
      return {
        ...state,
        isAssignedGift: action.payload === 'profile',
      };

    case CHAT_REQUEST:
      return {
        ...state,
        isAssignedGift: false,
        isFetching: true,
      };

    default:
      return state;
  }
}
